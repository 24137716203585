import "./Main.css";
import BreadCrumb from "../breadcrumbs/Breadcrumbs";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
const Main = () => {
  const currentUser  = useSelector((state) => state?.auth?.user);
  if (!currentUser) {
    /* istanbul ignore next */ 
    return <Navigate to="/login" />;
  }

  return (
    <main>
      <BreadCrumb name="Dashboard"></BreadCrumb>
      <div className="main__container">
        {/* <!-- MAIN TITLE STARTS HERE --> */}
        <div className="main__tile_heading">
            Dashboard
          {/* <img src={hello} alt="hello" />
          <div className="main__greeting">
            {employeeAddress.name && (
              <>
                <h1>Hello {_.startCase(_.toLower(employeeAddress.name))}</h1>
              </>
            )}
          </div> */}
        </div>

        {/* <!-- MAIN TITLE ENDS HERE --> */}

        {/* <!-- MAIN CARDS STARTS HERE --> */}
        {/* <div className="main__cards">
          <div className="card" style={{ backgroundColor: 'lightblue' }}>
            <i
              className="fa fa-user-o fa-2x text-lightblue"
              aria-hidden="true"
            ></i>
            <div className="card_inner">
              <p className="text-primary-p">Number of Users</p>
              <span className="font-bold text-title">578</span>
            </div>
          </div>

          <div className="card" style={{ backgroundColor: 'lightgray' }}>
            <i className="fa fa-calendar fa-2x text-red" aria-hidden="true"></i>
            <div className="card_inner">
              <p className="text-primary-p">Orders FullFilled</p>
              <span className="font-bold text-title">2467</span>
            </div>
          </div>

          <div className="card" style={{ backgroundColor: 'lightpink' }}>
            <i
              className="fa fa-video-camera fa-2x text-yellow"
              aria-hidden="true"
            ></i>
            <div className="card_inner">
              <p className="text-primary-p">Orders Pending</p>
              <span className="font-bold text-title">340</span>
            </div>
          </div>

          <div className="card" style={{ backgroundColor: 'lightyellow' }}>
            <i
              className="fa fa-thumbs-up fa-2x text-green"
              aria-hidden="true"
            ></i>
            <div className="card_inner">
              <p className="text-primary-p">Number of Likes</p>
              <span className="font-bold text-title">645</span>
            </div>
          </div>
        </div>
        <div className="main__cards">
          <div className="card" style={{ backgroundColor: 'aquamarine' }}>
            <i
              className="fa fa-user-o fa-2x text-lightblue"
              aria-hidden="true"
            ></i>
            <div className="card_inner">
              <p className="text-primary-p">Number of Users</p>
              <span className="font-bold text-title">578</span>
            </div>
          </div>

          <div className="card" style={{ backgroundColor: 'lavender' }}>
            <i className="fa fa-calendar fa-2x text-red" aria-hidden="true"></i>
            <div className="card_inner">
              <p className="text-primary-p">Orders FullFilled</p>
              <span className="font-bold text-title">2467</span>
            </div>
          </div>

          <div className="card" style={{ backgroundColor: 'lightsalmon' }}>
            <i
              className="fa fa-video-camera fa-2x text-yellow"
              aria-hidden="true"
            ></i>
            <div className="card_inner">
              <p className="text-primary-p">Orders Pending</p>
              <span className="font-bold text-title">340</span>
            </div>
          </div>

          <div className="card" style={{ backgroundColor: 'lightskyblue' }}>
            <i
              className="fa fa-thumbs-up fa-2x text-green"
              aria-hidden="true"
            ></i>
            <div className="card_inner">
              <p className="text-primary-p">Number of Likes</p>
              <span className="font-bold text-title">645</span>
            </div>
          </div>
        </div> */}
        <div className="main_head">
        Welcome to Warehouse Management System
          {/* <img src={hello} alt="hello" />
          <div className="main__greeting">
            {employeeAddress.name && (
              <>
                <h1>Hello {_.startCase(_.toLower(employeeAddress.name))}</h1>
              </>
            )}
          </div> */}
          {/* <h2>Welcome to Warehouse Management System</h2> */}
        </div>

        {/* <!-- MAIN CARDS ENDS HERE --> */}

        {/* <!-- CHARTS STARTS HERE --> */}
        {/* <!-- CHARTS ENDS HERE --> */}
      </div>
    </main>
  );
};

export default Main;
