import React from 'react'
import { Modal } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid';

export default function CompanyCarrierDialog(props) {
    const{Item, modalShow, setModalShow}=props
  return (
    <Modal
    size="md"
    show={modalShow}
    onHide={/* istanbul ignore next */ () => {
        setModalShow(false);
      }}
      centered>
      <Modal.Header >
        <div className="modal-main-div">
          <h6 >Company Carrier</h6>
          <div >{Item.carrierCompanyName}</div>
        </div>
        <div className="modalDeleteWrapper">
          <button type="button" className="btn-close" aria-label="Close" onClick={() => setModalShow(false)}></button>
        </div>
      </Modal.Header>
      <Modal.Body>
          {Item.sellerNames.map(
            (sellerItem, indexSeller) => (
              <>
                <li
                    key={uuidv4()}
                    className="alignLeft"
                  >
                    {sellerItem}
                  </li>
              </>
            )
          )}
      </Modal.Body>
    </Modal>
  )
}
