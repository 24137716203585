import _ from 'lodash';
import React from 'react';

const ItemsOutbound = (props) => {
    const {
        setShowProductListingBox,
        loader,
        formData,
        isEditable,
        handleBlurInput,
        weightUnitListing,
        dimensionUnitListing,
        setFormData,
    } = props;

    const handleRemoveItem = (e, index) => {
        let formdataObj = { ...formData };
        if (formdataObj.items[index].id !== 0) {
            formdataObj.items[index].isDeleted = true;
        } else {
            formdataObj.items.splice(index, 1);
        }
        setFormData(formdataObj);
    }

    const handleItemChange = (e) => {
        const { name, value } = e.target;
        let dataObj = _.cloneDeep(formData);
        dataObj.items[e.target.getAttribute('data-index')][name] = value;
        dataObj.items[e.target.getAttribute('data-index')].error = '';
        setFormData(dataObj);
    }

    return (
        <>
            <div className="row mb-3 dimesntionsBlock">
                <div className="col-12 p-0 mb-4"><b>Items/Products</b>
                    <button
                        className="ml-3 btn cus-primary-transparent-btn slcttxt"
                        onClick={() => {
                            setShowProductListingBox(true);
                        }}
                        style={{ paddingLeft: "0", paddingRight: "0" }}
                        type="text"
                        disabled={
                            loader || formData.consignmentId || !isEditable
                        }
                    >
                        Add from existing Product List <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                </div>

                <div className='items-wrapper'>
                    {formData?.items?.map((item, index) => (
                        <div className='col-12 mb-4' key={item.id}>
                            {!item.isDeleted && (
                                <>
                                    <div className="row">
                                        <div className='col-lg-2 col-md-6 col-sm-12'>
                                            <label>Name</label>
                                            <input
                                                className={`form-control numInput`}
                                                type="text"
                                                name="name"
                                                value={item.name}
                                                data-index={index}
                                                onChange={handleItemChange}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className='col-lg-2 col-md-6 col-sm-12'>
                                            <label>SKU</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"text"}
                                                value={item.productSKU}
                                                name="productSKU"
                                                onChange={handleItemChange}
                                                data-index={index}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className='col-lg-2 col-md-6 col-sm-12'>
                                            <label>Available Qty</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"number"}
                                                value={item.availableQuantity}
                                                name="availableQuantity"
                                                min={0}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={handleItemChange}
                                                data-index={index}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className='col-lg-2 col-md-6 col-sm-12'>
                                            <label>Ordered Qty</label>
                                            <input
                                                className={`form-control numInput ${item.error && "errorField"}`}
                                                type={"number"}
                                                name="orderedQuantity"
                                                value={item.orderedQuantity}
                                                min={0}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={handleItemChange}
                                                disabled={!isEditable}
                                                data-index={index}
                                            />
                                            <div className="error">{item.error}</div>
                                        </div>

                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                            <label>Weight</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"number"}
                                                name="weight"
                                                min={0}
                                                value={item.weight}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                data-index={index}
                                                onChange={handleItemChange}
                                                disabled={!isEditable}
                                            />
                                        </div>

                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                            <label>Unit</label>
                                            <select
                                                className={`form-control countrySelect`}
                                                onChange={handleItemChange}
                                                value={item.weightUnitId}
                                                name={"weightUnitId"}
                                                disabled={!isEditable}
                                                onBlur={handleBlurInput}
                                                data-index={index}
                                            >
                                                {weightUnitListing?.length > 0 &&
                                                    weightUnitListing?.map((weight) => (
                                                        <option key={weight.id} value={weight.id}>
                                                            {weight.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                            <label>Length</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"number"}
                                                name="length"
                                                value={item.length}
                                                onChange={handleItemChange}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                disabled={!isEditable}
                                                min={0}
                                                data-index={index}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                            <label>Width</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"number"}
                                                name="width"
                                                value={item.width}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={handleItemChange}
                                                disabled={!isEditable}
                                                min={0}
                                                onBlur={handleBlurInput}
                                                data-index={index}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                            <label>Height</label>
                                            <input
                                                className={`form-control numInput`}
                                                type={"number"}
                                                name="height"
                                                value={item.height}
                                                min={0}
                                                data-index={index}
                                                disabled={!isEditable}
                                                onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                onChange={handleItemChange}
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                            <label>Dimension Unit</label>
                                            <select
                                                className={`form-control countrySelect`}
                                                onChange={handleItemChange}
                                                value={item.dimensionUnitId}
                                                name={"dimensionUnitId"}
                                                disabled={!isEditable}
                                                data-index={index}
                                            >
                                                {dimensionUnitListing?.length > 0 &&
                                                    dimensionUnitListing?.map((dimension) => (
                                                        <option key={dimension.id} value={dimension.id}>
                                                            {dimension.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div className='col-2'>
                                            <button disabled={!isEditable} onClick={(e) => handleRemoveItem(e, index)} className="ml-3 mt-4 btn cus-seconday-bg-btn round red"><i class="fa fa-minus" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default ItemsOutbound;