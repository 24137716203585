import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import {
  getInventoryProductList,
} from "../../store/actions/inventory.action";
import LoaderSpinner from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import { renderTableRow } from "../../config/utils";
import InfiniteScroll from "react-infinite-scroll-component";

const OutboundProductListingDialog = (props) => {
  const {
    showProductListingBox,
    handleCancel,
    setShowProductListingBox,
    formData
  } = props;
  const [loader, setLoader] = useState(false);
  const { inventoryProductList, inventoryProductListStatus } = useSelector(
    (state) => state.InventoryManagement
  );
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);
  const [sortOrder, setSortOrder] = useState("DESCENDING");
  const [sortingColumn, setSortingColumn] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [imported, setImported] = useState([]);
  let dataLoad = {
    sellerIdList: [],
    companyIdList: [],
  };
  const [filterData, setfilterData] = useState(
    JSON.parse(JSON.stringify(dataLoad))
  );
  const [totalPages, setTotalPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    if (formData?.items?.length) {
      setImported(_.map(formData?.items, "productId"));
    }
  }, [formData])

  useEffect(() => {
    fetchProductList();
  }, [pageIndex, sortingColumn, sortOrder]);

  useEffect(() => {
    if (inventoryProductList?.TotalRecords) {
      setTotalPages(Math.ceil(inventoryProductList?.TotalRecords / 50));
    }
  }, [inventoryProductList]);

  useEffect(() => {
    if (inventoryProductListStatus === "pending") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [inventoryProductListStatus]);

  useEffect(() => {
    if (inventoryProductList?.listInventoryEntity && inventoryProductListStatus === 'success') {
      setProductList([...productList, ...(_.cloneDeep(inventoryProductList?.listInventoryEntity))]);
    }
  }, [inventoryProductList?.listInventoryEntity]);

  const fetchProductList = () => {
    let data = {
      pageIndex: pageIndex,
      pageSize: 50,
      sortColumn: sortingColumn,
      sortOrder: sortOrder,
      searchKeyword: "",
      sellerIdList: _.map(filterData.sellerIdList, "id"),
      companyIdList: _.map(filterData.companyIdList, "Id"),
    };
    dispatch(getInventoryProductList(data));
  };

  const handleSortingColumn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setSortingColumn(sortColumn);
    setSortOrder(newSortOrder);
    setProductList([]);
    setfilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return sortingColumn === columnName && sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return sortingColumn === columnName ? "active" : "sortColumn";
  };

  const handleSelect = (e, index) => {
    const { checked } = e.target;
    let listData = [...productList];
    listData[index].isChecked = checked;
    setProductList(listData);
  }

  const loadMoreData = () => {
    if (totalPages !== pageIndex) {
      setPageIndex(pageIndex + 1);
    }
  }

  const addSelectedProducts = () => {
    let listData = [...productList];
    let findChecked = listData.filter(item => item.isChecked);
    props.handleMultiProductSelect(findChecked);
    setShowProductListingBox(false);
  }

  const handleSearchFilter = () => {
    let data = {
      pageIndex: 1,
      pageSize: 50,
      sortColumn: '',
      sortOrder: 'DESCENDING',
      searchKeyword: searchTxt,
      sellerIdList: _.map(filterData.sellerIdList, "id"),
      companyIdList: _.map(filterData.companyIdList, "Id"),
    };
    setProductList([]);
    setPageIndex(1);
    setSortingColumn('');
    setSortOrder('DESCENDING');
    dispatch(getInventoryProductList(data));
  }

  const handleReset = () => {
    let data = {
      pageIndex: 1,
      pageSize: 50,
      sortColumn: '',
      sortOrder: 'DESCENDING',
      searchKeyword: '',
      sellerIdList: _.map(filterData.sellerIdList, "id"),
      companyIdList: _.map(filterData.companyIdList, "Id"),
    };
    setPageIndex(1);
    setSortingColumn('');
    setSortOrder('DESCENDING');
    setSearchTxt('');
    dispatch(getInventoryProductList(data));
  }

  return (
    <Modal
      {...props}
      size="xl"
      className="topUpModal productskuListing"
      centered
      backdrop="static"
      show={showProductListingBox}
      onHide={handleCancel}
    >
      <div className="top_modal_header_container no_mrg">
        <span className="">Select Product SKU</span>
        <div className="modalDeleteWrapper">
          <button
            type="button"
            onClick={() => handleCancel()}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="modal_container_rate_card seller-Modal productList">
        {loader && <LoaderSpinner />}
        <div className="row filterContainer show searchBox mb-4">
          <div className="col-10 searchInputBox">
            <input
              value={searchTxt}
              onChange={(e) => setSearchTxt(e.target.value)}
              className="form-control searchInput"
              placeholder={'Search by SKU, Outbound Id, Name, Consignment Type, Carrier Id, Tracking Number or Outbound Status'}
              onBlur={(e) => setSearchTxt(e.target.value.trim())}
            />
          </div>
          <button disabled={!searchTxt} className="btn cus-primary-transparent-btn clrBtn" onClick={handleReset}>Reset</button>
          <button
            className="btn cus-seconday-bg-btn marginLeft5 searchBtn"
            onClick={handleSearchFilter}
          >
            Search
          </button>
          <div className="col-2 alignRight">
          </div>
        </div>
        {productList.length > 0 && (
          <InfiniteScroll
            next={loadMoreData}
            hasMore={totalPages === pageIndex ? false : true}
            loader={<p>Loading...</p>}
            height={400}
            pullDownToRefreshThreshold={30}
            dataLength={productList.length}

          >
            <div className="incomingTbl">
              <Table
                className={`table table-height tblOutbound horizontalAlignedTbl cus-left-right-col-layout table ${loader ? "disableTble" : ""
                  }`}
              >
                <thead className="tbl-btch">
                  <tr className="table-rw-batch">
                    <th width={"5%"}></th>
                    <th width={"20%"}>
                      <div
                        className={handleSortingClass("productSku")}
                        onClick={(e) => handleSortingColumn(e, "productSku")}
                        id="productSku"
                      >
                        Product SKU
                        <i
                          className={handleSortingClass("productSku", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width={"20%"}>
                      <div
                        className={handleSortingClass("productName")}
                        onClick={(e) => handleSortingColumn(e, "productName")}
                        id="productName"
                      >
                        Product Name
                        <i
                          className={handleSortingClass("productName", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width={"20%"}>Product Description</th>
                    <th width={"10%"}>
                      <div
                        className={handleSortingClass("quantity")}
                        onClick={(e) => handleSortingColumn(e, "quantity")}
                        id="quantity"
                      >
                        Quantity
                        <i
                          className={handleSortingClass("quantity", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="tbl-bt-body">
                  {productList?.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        <input
                          type="checkbox"
                          className="checkProductInput"
                          checked={item.isChecked}
                          disabled={imported.includes(item.id)}
                          onChange={(e) => handleSelect(e, index)}
                        />
                      </td>
                      <td>
                        {item.productSku}
                      </td>
                      <td>{item.productName}</td>
                      <td>{item.productDescription}</td>
                      <td>{item.quantity}</td>
                    </tr>
                  ))}
                  {inventoryProductListStatus === "success" &&
                    productList.length === 0 &&
                    renderTableRow(10, "No Result Found...")}
                </tbody>
              </Table>
            </div>
          </InfiniteScroll>
        )}
        <div className="paletteActions mt-3">
          <button
            className="btn cus-primary-transparent-btn top-btn"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button
            className="btn cus-seconday-bg-btn top-btn"
            onClick={addSelectedProducts}
            disabled={!productList.filter(item => item.isChecked).length}
          >
            Add Products
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default OutboundProductListingDialog;
