import React, { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import { RecentHistoryDialog } from './RecentHistoryDialog';
import { resetRecentHistoryBatch } from "../../store/actions/batch.action";
export const RecentBatchPrint = (props) => {
    const [csvModalShow, setCsvModalShow] = useState(false);

    useEffect(() => {
        if (!csvModalShow) {
            props.dispatch(resetRecentHistoryBatch());
        }
    }, [csvModalShow]);

    return (
        <>
            <Dropdown className='historyPopper batch-select recent-bth-print'>
                <Dropdown.Toggle className='opneDropDownMenu'><i className="fa fa-ellipsis-v" aria-hidden="true"></i></Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item className='printLabelBtn' onClick={() => setCsvModalShow(true)}>Print History</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {csvModalShow &&
                <RecentHistoryDialog
                    {...props}
                    onHide={() => setCsvModalShow(false)}
                    show={csvModalShow}
                    type={"shipment"}
                />
            }
        </>
    );
}