import axios from "axios";
import { apiUrl } from "../constant";

export const getInventoryProductListingService = (data) => {
    return axios.post(`${apiUrl}/api/InventoryManagement/ProductListing`, data);
};

export const getProductByIdService = (id) => {
    return axios.post(`${apiUrl}/api/InventoryManagement/ProductById?Id=${id}`);
};

export const updateInventoryProductService = (data) => {
    return axios.put(`${apiUrl}/api/InventoryManagement/InventoryProduct`, data);
};

export const createInventoryProductService = (data) => {
    return axios.post(`${apiUrl}/api/InventoryManagement/InventoryProduct`, data);
};

export const uploadProductMediaService = (data) => {
    return axios.put(`${apiUrl}/api/InventoryManagement/update-media`, data);
};

export const getConsignmentListingService = (data) => {
    return axios.post(`${apiUrl}/api/Consignment/ConsignmentListing`, data);
};

export const getConsignmentOutboundListingService = (data) => {
    return axios.post(`${apiUrl}/api/Consignment/OutboundListing`, data);
};
export const getActiveIntegrationsService = () => {
    return axios.get(`${apiUrl}/api/active-integrations`);
};

export const getAllConsignmentTypesService = () => {
    return axios.get(`${apiUrl}/api/Consignment/GetAllConsignmentType`);
};

export const getAllConsignmentStatusService = () => {
    return axios.get(`${apiUrl}/api/Consignment/GetAllConsignmentStatus`);
};

export const getAllConsignmentOutboundStatusService = () => {
    return axios.get(`${apiUrl}/api/Consignment/OutboundStatus`);
};

export const getWarehouseListingService = (data) => {
    return axios.post(`${apiUrl}/api/InventoryManagement/Warehouse`, data);
};

export const areaByWarehouseIdService = (data) => {
    return axios.post(
        `${apiUrl}/api/InventoryManagement/AreaByWareHouseId`,
        data
    );
};

export const rowByWarehouseIdService = (data) => {
    return axios.post(
        `${apiUrl}/api/InventoryManagement/RowByWarehouseAreaId`,
        data
    );
};

export const bayByWarehouseIdService = (data) => {
    return axios.post(
        `${apiUrl}/api/InventoryManagement/BayByWarehouseRowId`,
        data
    );
};

export const levelByWarehouseIdService = (data) => {
    return axios.post(
        `${apiUrl}/api/InventoryManagement/LevelByWarehouseBayId`,
        data
    );
};

export const positionByWarehouseIdService = (data) => {
    return axios.post(
        `${apiUrl}/api/InventoryManagement/PositionByWarehouseLevelId`,
        data
    );
};

export const ProductListFileUploadService = (formdata) => {
    return axios.post(`${apiUrl}/api/upload-file`, formdata);
};

export const getInboundByIdService = (id) => {
    return axios.post(`${apiUrl}/api/Consignment/ConsignmentById?Id=${id}`);
};

export const getOutboundByIdService = (id) => {
    return axios.post(`${apiUrl}/api/Consignment/BulkOutboundById?Id=${id}`);
};

export const getProductListTemplateUrlService = (ProductOptions) => {
    return axios.get(`${apiUrl}/api/bulkproduct/template?${ProductOptions}`);
};

export const getProductHisotryService = (product) => {
    return axios.post(`${apiUrl}/api/InventoryManagement/HistoryByProductId`, product);
};

export const getInboundHisotryService = (product) => {
    return axios.post(`${apiUrl}/api/Consignment/HistoryByConsignmentId`, product);
};

export const getProductInventoryService = (product) => {
    return axios.post(`${apiUrl}/api/InventoryManagement/HistoryByInventoryId`, product);
};

export const updateConsoleService = (payload) => {
    return axios.put(`${apiUrl}/api/Consignment/UpdateConsignment`, payload);
};

export const createConsoleService = (payload) => {
    return axios.post(`${apiUrl}/api/Consignment/CreateConsignment`, payload);
};

export const createOutboundService = (payload) => {
    return axios.post(`${apiUrl}/api/Consignment/BulkOutbound`, payload);
};

export const updateOutboundService = (payload) => {
    return axios.put(`${apiUrl}/api/Consignment/UpdateBulkOutbound`, payload);
};

export const updateConsignmentMediaService = (payload) => {
    return axios.put(`${apiUrl}/api/Consignment/update-consignment-media`, payload);
};

export const updateOutboundLabel = (payload) => {
    return axios.put(`${apiUrl}/api/Consignment/update-outbound-label`, payload);
};

export const getTrackingNumberFromPdfService = (payload) => {
    return axios.post(`${apiUrl}/api/LabelUpload/GetTrackingNumber`, payload);
};

export const updateOutboundImageService = (payload) => {
    return axios.put(
        `${apiUrl}/api/Consignment/update-outbound-image`,
        payload
    );
};

export const updateOutboundFilesService = (payload) => {
    return axios.put(`${apiUrl}/api/Consignment/update-outbound-file`, payload);
};

export const getConsignmentElementByIdService = (id) => {
    return axios.post(`${apiUrl}/api/Consignment/ConsignmentElementById?Id=${id}`);
};

export const createConsignmentElementService = (data) => {
    return axios.post(`${apiUrl}/api/Consignment/CreateConsignmentElement`, data);
};

export const updateConsignmentElementService = (data) => {
    return axios.put(`${apiUrl}/api/Consignment/UpdateConsignmentElement`, data);
};

export const deleteConsignmentService = (data) => {
    return axios.post(`${apiUrl}/api/Consignment/DeleteConsignmentElements`, data);
};

export const fetchAllConsignmentElementStatusService = () => {
    return axios.get(`${apiUrl}/api/Consignment/GetAllConsignmentElementStatus`);
};

export const getOutboundHisotryService = (product) => {
    return axios.post(`${apiUrl}/api/Consignment/HistoryByOutboundId`, product);
};