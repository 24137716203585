import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import {
  resetPassword,
} from "../../store/actions/auth";
import "../../components/login/bootstraplogin.css";
import MainLogoV from "../../assets/images/main-logo-vertical.png";
import eye_visible from "../../assets/eye_visible.png";
import eye_cross from "../../assets/eye_cross.png";
import { passwordRegex } from "../../constant";
import MainLogoAi from "../../assets/images/bannerLogin.svg"

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const ResetPassword = () => {
  let tokenValue = window.location.search.split("?token=")[1];
  const [password, setPassword] = useState("");
  const [errors, SetErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setconfirmPassword] = useState("");
  const loading = false;
  let hostName = window.location.hostname;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (!passwordRegex.test(password)) {
      SetErrors({
        newPassword: "Please enter at least 10 digits that contains  one numeric , special , uppercase , lowercase letter",
      });
    } else if (confirmPassword !== password) {
      SetErrors({
        confirmPassword: "Passwords mismatch. Please enter the password again to confirm!",
      });
    } else {
      SetErrors({})
    }
  }, [password, confirmPassword]);

  const onChangePassword = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
  };
  const onConfirmPassword = (e) => {
    const confirmPasswordValue = e.target.value;
    setconfirmPassword(confirmPasswordValue);
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    dispatch(resetPassword(tokenValue, password, navigate));
  };

  return (
    <div className="cus-login-pg login-form-center">
      <div>
        <div className="cus-login-pg-sec">
          <div className="cus-login-pg-left-sec">
            <img alt="" className="cus-main-logo-img" src={hostName === 'portal.shipvisionai.com' ? MainLogoAi : MainLogoV} />
          </div>
          <div className="cus-login-pg-right-sec">
            <div className="cus-welcome-txt">
              Welcome to <span>{hostName === 'portal.shipvisionai.com' ? 'ShipVisionAI' : '3PLVision'}</span>
            </div>
            <div className="cus-login-form">
              <>
                <div className="cus-form-sec-heading">Reset Password</div>
                <div className="cus-login-form-sec">
                  <Form className="loginFormSubmit">
                    <div className="form-group cus-rel">
                      <div className="cus-rel">
                        <Input
                          type={showPassword ? "text" : "password"}
                          className="form-controlzm "
                          name="newPassword"
                          value={password}
                          onChange={onChangePassword}
                          validations={[required]}
                          autocomplete="off"
                          placeholder="New Password"
                        />
                        {showPassword ? (
                          <img
                            onClick={toggleShowPassword}
                            className="cus-sm-icon-cls"
                            src={eye_visible}
                            alt=""
                          />
                        ) : (
                          <img
                            onClick={toggleShowPassword}
                            className="cus-sm-icon-cls"
                            src={eye_cross}
                            alt=""
                          />
                        )}
                        {password != "" && errors && errors?.newPassword && (
                          <div
                            className="alert alert-danger erroralert"
                            role="alert"
                          >
                            {errors?.newPassword}
                          </div>
                        )}
                      </div>

                      <div className="cus-rel">
                        <Input
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-controlzm "
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={onConfirmPassword}
                          validations={[required]}
                          autocomplete="off"
                          placeholder="Confirm Password"
                        />
                        {showConfirmPassword ? (
                          <img
                            onClick={toggleShowConfirmPassword}
                            className="cus-sm-icon-cls"
                            src={eye_visible}
                            alt=""
                          />
                        ) : (
                          <img
                            onClick={toggleShowConfirmPassword}
                            className="cus-sm-icon-cls"
                            src={eye_cross}
                            alt=""
                          />
                        )}
                        {confirmPassword != "" &&
                          errors &&
                          errors?.confirmPassword && (
                            <div
                              className="alert alert-danger erroralert"
                              role="alert"
                            >
                              {errors?.confirmPassword}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-group cus-action-group">
                      <div className="cus-action-btn-sec">
                        <button
                          onClick={handleResetPassword}
                          className="btn cus-seconday-bg-btn"
                          disabled={
                            !password ||
                            loading ||
                            !confirmPassword ||
                            errors?.confirmPassword ||
                            errors?.newPassword
                          }
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          Reset
                        </button>
                      </div>
                      <div className="cus-action-btn-sec">
                        <button
                          className="btn cus-seconday-bg-btn"
                          onClick={() => navigate("/login")}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
