import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./fundManagementhistory.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { formatNumberWithCommas } from "../../config/utils";
import { InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fundTransactionFilter } from "../../config/utils";
import moment from "moment";
import { emptyFundTransactionList, getFundTransactionDetails } from "../../store/actions/fundManagement";
import Button from "bootstrap-4-react/lib/components/Button";
import BatchPagination from "../batch/BatchPagination";
import LoaderSpinner from "../common/Loader";

export function FundmanagementHistory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [filterError, setFilterError] = useState('');

  const numOfPage = [30, 50, 100, 200, 500, 1000, 2000];
  const [currentPage, setCurrentPage] = useState(1);

  const [filterTransactionType, setfilterTransactionType] = useState();
  const [numberOfPage, setNumberOfPage] = useState(30);
  const location = useLocation();
  const data = location?.state?.rowData;
  const userValue = location?.state?.userData;

  const currentDate = new Date();
  const sevenDaysBefore = new Date(
    currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
  );
  // Format the date to a string in a desired format (e.g. ISO string)
  const dateValue = sevenDaysBefore.toISOString();
  const date = new Date(dateValue);
  const options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
    timeZone: "Asia/Kolkata",
  };
  const formattedDate = date.toLocaleString("en-US", options).replace(/,/g, "");
  let currentDateValue = new Date();

  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      availableAmount: data?.availableAmount || userValue?.balanceAmount,
      topupCompanyId: data?.topupCompanyId,
      topupSellerId: data?.topupSellerId,
      companyName: data?.companyName || userValue?.companyName,
      id: 0,
      sellerName: data?.sellerName || userValue?.sellerName,
      threshold: data?.threshold || userValue?.threshold,
      dateFrom: new Date(formattedDate),
      dateTo: currentDateValue,
      creditCompanySellerId:
        data?.creditCompanySellerId || 0,
    });
  }, [location?.state?.dummyState]);

  const fundTransactionDetails = useSelector(
    (state) =>
      state?.FundManagementReducer?.getTransactionDetails?.Entity
        ?.listFundMasterDetailsEntity
  );

  const fundTransactionTotalRecord = useSelector(
    (state) => state?.FundManagementReducer?.getTransactionDetails?.Entity
  );

  const [formatDateValue, setFormatDateValue] = useState();
  const [currentDateVal, setcurrentDateVal] = useState();

  const handleDateChange = (item, name) => {
    /* istanbul ignore next */

    if (name == "dateFrom") {
      /* istanbul ignore next */
      setFormatDateValue(item);
      setFormData({ ...formData, [name]: item });
    } else if (name == "dateTo") {
      /* istanbul ignore next */

      setcurrentDateVal(item);
      setFormData({ ...formData, [name]: item });
    } else {
      /* istanbul ignore next */
      setFormData({ ...formData, [name]: item });
    }
    setFilterError('');
    setTotalPages(1);
  };

  useEffect(() => {
    if (formData?.creditCompanySellerId > -1) {
      dispatch(
        getFundTransactionDetails(
          formData,
          setTotalPages,
          numberOfPage,
          currentPage,
          filterTransactionType?.value,
          setShowLoader
        )
      );
    }
  }, [numberOfPage, currentPage, location?.state?.dummyState, formData?.creditCompanySellerId]);

  useEffect(() => {
    setFormatDateValue(formattedDate);
    setcurrentDateVal(currentDateValue);
  }, []);

  const handleApply = (type) => {
    if (type === "reset") {
      setfilterTransactionType(0);
      setCurrentPage(1);
      dispatch(
        getFundTransactionDetails({
          ...formData,
          dateFrom: new Date(formattedDate),
          dateTo: currentDateValue,
        },
          setTotalPages,
          numberOfPage,
          1,
          0,
          setShowLoader
        )
      );
      setNumberOfPage(30)
      setCurrentPage(1)
      setTotalPages(1)
      setFormData({
        ...formData,
        dateFrom: new Date(formattedDate),
        dateTo: currentDateValue,
      });
      setFormatDateValue(formattedDate);
      setcurrentDateVal(currentDateValue);
      setFilterError('');
      setShowLoader(false);
    } else {
      if (moment(currentDateVal).isBefore(moment(formatDateValue))) {
        setFilterError('Select correct to/from date');
      } else {
        dispatch(
          getFundTransactionDetails(
            formData,
            setTotalPages,
            null,
            null,
            filterTransactionType?.value,
            setShowLoader
          )
        );
      }
    }
  };

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          Fund Management
          <div className="top_right_button_container">
            <Button
              className="cus-seconday-bg-btn top-btn"
              onClick={() => handleApply()}
            >
              Apply
            </Button>
            <Button
              className="cus-seconday-bg-btn top-btn"
              onClick={() => handleApply("reset")}
            >
              Reset <i className="fa fa-refresh" aria-hidden="true"></i>
            </Button>
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/fundManagement");
                dispatch(emptyFundTransactionList());
              }}
            >
              Back
            </Button>
          </div>
        </div>
        {showLoader && <LoaderSpinner />}
        <div className="mb-3">
          <Row>
            <Col xs={3}>
              <Form.Group>
                <div className="input-label-Created-date">
                  Company Name : {formData?.companyName}
                </div>
              </Form.Group>
            </Col>

            <Col xs={3}>
              <Form.Group>
                <div className="input-label-Created-date">
                  Seller Name : {formData?.sellerName}
                </div>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group>
                <div className="input-label-Created-date">
                  Threshold Value : <i className="fa fa-usd" aria-hidden="true"></i>{" "}
                  {formData?.threshold
                    ? formatNumberWithCommas(formData?.threshold)
                    : 0}
                </div>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group>
                <div className="input-label-Created-date">
                  Available Balance:{" "}
                  <i className="fa fa-usd" aria-hidden="true"></i>{" "}
                  {formData?.availableAmount &&
                    formatNumberWithCommas(formData?.availableAmount)}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="mb-3">
          <Row>
            <Col xs={3}>
              <InputGroup className="mb-2">
                <Row>
                  <Col xs={3}>
                    <div className="input-label-Created-date">From:</div>
                  </Col>
                  <Col xs={9}>
                    <DatePicker
                      className="date-picker"
                      showIcon
                      dateFormat="MM-dd-yyyy"
                      selected={formatDateValue && new Date(formatDateValue)}
                      onChange={(selectedDate) =>
                        handleDateChange(selectedDate, "dateFrom")
                      }
                    />
                  </Col>
                  <Col><span className="error">{filterError}</span></Col>
                </Row>
              </InputGroup>
            </Col>
            <Col xs={3}>
              <InputGroup>
                <Row>
                  <Col xs={3}>
                    <div className="input-label-Created-date">To:</div>
                  </Col>
                  <Col xs={9}>
                    <DatePicker
                      className="date-picker"
                      showIcon
                      dateFormat="MM-dd-yyyy"
                      selected={currentDateVal}
                      onChange={(dateTo) => handleDateChange(dateTo, "dateTo")}
                    />
                  </Col>
                </Row>
              </InputGroup>
            </Col>
            <Col xs={3} className="transaction-filter">
              <Select
                onChange={(e) => setfilterTransactionType(e)}
                options={fundTransactionFilter}
                classNamePrefix="list"
                placeholder={"Transaction Type"}
                value={filterTransactionType}
              />
            </Col>
          </Row>
        </div>

        <div className="main_content_table">
          <Table className="table table-height">
            <thead border="Green">
              <tr className="trow">
                <th>#</th>
                <th className="trows-s">Date</th>
                <th>Transaction Type</th>
                <th>Description</th>
                <th>Carrier</th>
                <th className="amount-align">
                  Debit (<i className="fa fa-usd" aria-hidden="true"></i>)
                </th>
                <th className="amount-align">
                  Credit (<i className="fa fa-usd" aria-hidden="true"></i>)
                </th>
                <th className="amount-align">
                  Closing Balance (<i className="fa fa-usd" aria-hidden="true"></i>)
                </th>
              </tr>
            </thead>
            <tbody>
              {fundTransactionDetails &&
                /* istanbul ignore next */
                fundTransactionDetails?.map((d, i) => (
                  <tr key={d?.id}>
                    <td>{i + 1}</td>

                    <td>
                      {moment(d?.createdDate).format(
                        "MM-DD-YYYY | h:mm:ss A"
                      )}
                    </td>
                    <td>{d?.transactionType}</td>
                    <td className="description-cell">{d?.description}</td>
                    <td>{d?.carrierName || '-'}</td>
                    <td className="amount-align">
                      {formatNumberWithCommas(d?.debit)}
                    </td>
                    <td className="amount-align">
                      {formatNumberWithCommas(d?.credit)}
                    </td>
                    <td className="amount-align">
                      {formatNumberWithCommas(d?.closingBalance)}
                    </td>
                  </tr>
                ))}

              {fundTransactionDetails && !fundTransactionDetails.length &&
                <tr>
                  <td colSpan={8} align="center">No Record Found...</td>
                </tr>
              }
            </tbody>
          </Table>
        </div>
        {fundTransactionDetails && (
          /* istanbul ignore next */
          <BatchPagination
            gridData={fundTransactionDetails}
            totalVisualRecords={fundTransactionDetails.length}
            totalRecords={fundTransactionTotalRecord.TotalRecords}
            numberOfPage={numberOfPage}
            numOfPage={numOfPage}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            setNumberOfPage={setNumberOfPage}
          />
        )}
      </div>
    </main>
  );
}
