import React, { useEffect } from 'react';
import pic10 from "../../assets/minus.png";
import pic9 from "../../assets/plus.png";
import { convertIntoKilo, convertToLb, validDecimalNum } from '../../config/utils';

const CommercialInvoice = (props) => {
    const { countries, shipmentObject, setShipmentObject } = props;
    const invoiceType = [{ name: 'Commercial', id: 1 }, { name: 'Proforma', id: 2 }];
    const commodityObj = shipmentObject.commodity;

    const handlePackage = (type, index) => {
        let packageObj = { unitValue: null, quantity: null, weight: null, ounces: null, description: '', hts: '', countryOriginId: 0, countryOrigin: '', }
        let shipObj = { ...shipmentObject };
        if (type === 'add') {
            shipObj.commodity.commodityList.push(packageObj);
        } else {
            shipObj.commodity.commodityList.splice(index, 1);
        }
        setShipmentObject(shipObj);
    }

    useEffect(() => {
        let shipObj = { ...shipmentObject };
        let totalWeight = shipObj.commodity.commodityList.reduce(function (acc, obj) {
            let convertVal = shipmentObject.dimensionUnit === 'INCH' ? convertToLb(obj.ounces) : convertIntoKilo(obj.ounces)
            return acc + (Number(obj.quantity) * Number(obj.weight)) + convertVal;
        }, 0);
        shipObj.commodity.totalWeight = totalWeight;
        setShipmentObject(shipObj);
    }, [shipmentObject?.dimensionUnit]);

    const handleFormUpdate = (event) => {
        const { value, name } = event.target;
        let shipObj = { ...shipmentObject };
        if (name === 'includeCommercial') {
            shipObj.commodity[name] = event.target.checked;
        } else if (name === 'issuerCountryId') {
            let countryIndex = countries.findIndex(
                (item) => item.id.toString() === value
            );
            shipObj.commodity.issuerCountry = countries[countryIndex]?.iSO;
            shipObj.commodity.issuerCountryId = Number(value);
        } else {
            shipObj.commodity[name] = value;
        }
        setShipmentObject(shipObj);
    };

    const handlePackageChange = (event, index, type = '') => {
       
        const { value, name } = event.target;
        let shipObj = { ...shipmentObject };
        if (type === 'num') {
            if (!isNaN(value) && validDecimalNum.test(value)) {
                shipObj.commodity.commodityList[index][name] = Number(value) || null;
            } else {
                return false;
            }
        } else if (name === 'countryOriginId') {
            let countryIndex = countries.findIndex(
                (item) => item.id.toString() === value
            );
            shipObj.commodity.commodityList[index][name] = value;
            shipObj.commodity.commodityList[index].countryOrigin = countries[countryIndex]?.iSO;
        } else if (type === '') {
            shipObj.commodity.commodityList[index][name] = value;
        }
        let totalQuantity = shipObj.commodity.commodityList.reduce(function (acc, obj) {
            return acc + Number(obj.quantity);
        }, 0);
        let totalAmountVal = shipObj.commodity.commodityList.reduce(function (acc, obj) {
            return acc + (Number(obj.quantity) * Number(obj.unitValue));
        }, 0);
        let totalWeight = shipObj.commodity.commodityList.reduce(function (acc, obj) {
            let convertVal = shipmentObject.dimensionUnit === 'INCH' ? convertToLb(obj.ounces) : convertIntoKilo(obj.ounces)
            return acc + (Number(obj.quantity) * (Number(obj.weight) + convertVal));
        }, 0);
        shipObj.commodity.totalQuantity = totalQuantity;
        shipObj.commodity.totalUnitValue = totalAmountVal;
        shipObj.commodity.totalWeight = totalWeight;
        setShipmentObject(shipObj);
    };

    return (
        <div className='commercialInvoice'>
            <div className='head'>
                <input
                    type="checkbox"
                    name="includeCommercial"
                    className="includeCommercial"
                    checked={commodityObj.includeCommercial}
                    onChange={handleFormUpdate}
                    disabled
                /> Include commercial invoice</div>
            {/* {commodityObj.includeCommercial && ( */}
            <div className='commercialInvoiceBody'>
                <div className='row mb-20'>
                    <div className='col-4'>
                        <label>Invoice Type</label>
                        <select
                            className='form-control'
                            value={commodityObj.invoiceType}
                            onChange={handleFormUpdate}
                            name="invoiceType"
                        >
                            {invoiceType.map((item) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='col-4'>
                        <label>Receiver Tax ID/VAT</label>
                        <input maxLength={100} onChange={handleFormUpdate} name="taxIdVat" className='form-control' value={commodityObj.taxIdVat} />
                    </div>
                    <div className='col-4'>
                        <label>Package Marks</label>
                        <input maxLength={100} onChange={handleFormUpdate} name="packageMarks" className='form-control' value={commodityObj.packageMarks} />
                    </div>
                </div>
                <div className='row mb-20'>
                    <div className='col-4'>
                        <label>Sender Tax ID/VAT</label>
                        <input maxLength={100} onChange={handleFormUpdate} name="senderTaxIdVat" className='form-control' value={commodityObj.senderTaxIdVat} />
                    </div>
                    <div className='col-8'>
                        <label>Sender Tax ID/VAT Issuer Country</label>
                        <select
                            className={`form-select1 form-control`}
                            value={commodityObj.issuerCountryId}
                            name="issuerCountryId"
                            onChange={handleFormUpdate}
                        >
                            <option value="">Select Country</option>
                            {countries?.map((country) => (
                                <option
                                    key={country.id}
                                    selected={
                                        country.id ===
                                        shipmentObject.senderCountryID
                                    }
                                    value={country.id}
                                >
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {commodityObj?.commodityList && commodityObj.commodityList.map((packageItem, index) => (
                    <div className='parcelDetails' key={`commodityList-${packageItem.quantity}`}>
                        <div className='row mb-20'>
                            <div className='col-3'>
                                <label>Quantity</label>
                                <input
                                    className={`form-control ${!packageItem.quantity && "errorField"}`}
                                    name='quantity'
                                    type="text"
                                    autoFocus
                                    value={packageItem?.quantity}
                                    onChange={(e) => handlePackageChange(e, index, 'num')}
                                />
                            </div>
                            <div className='col-3'>
                                <label>Unit Value</label>
                                <input
                                    className={`form-control ${!packageItem.unitValue && "errorField"}`}
                                    name='unitValue'
                                    value={packageItem.unitValue}
                                    type="text"
                                    onChange={(e) => handlePackageChange(e, index, 'num')}
                                />
                                <span className="txt-label">$</span>
                            </div>

                            <div className='col-3'>
                                <label>Weight</label>
                                <input
                                    name='weight'
                                    type="text"
                                    value={packageItem.weight}
                                    onChange={(e) => handlePackageChange(e, index, 'num')}
                                    className={`form-control ${packageItem.weight || packageItem.ounces ? "" : "errorField"}`}
                                />
                                <span className="txt-label">
                                    {shipmentObject.dimensionUnit === "INCH"
                                        ? "lb"
                                        : "kg"}</span>
                            </div>
                            <div className='col-3'>
                                <label>Ounces</label>
                                <input type="text" className={`form-control ${packageItem.weight || packageItem.ounces ? '' : "errorField"}`} name='ounces' value={packageItem.ounces} onChange={(e) => handlePackageChange(e, index, 'num')} />
                                <span className="txt-label">
                                    {shipmentObject.dimensionUnit === "INCH" ? "oz" : "g"}
                                </span>
                            </div>
                        </div>
                        <div className='row mb-20'>
                            <div className='col-4'>
                                <label>Description ({packageItem?.description?.length}/250)</label>
                                <input
                                    className={`form-control ${!packageItem.description && "errorField"}`}
                                    name='description' maxLength={250} value={packageItem.description} onChange={(e) => handlePackageChange(e, index)} />
                            </div>
                            <div className='col-3'>
                                <label>HTS#/B#</label>
                                <input maxLength={50} className='form-control' name='hts' value={packageItem.hts} onChange={(e) => handlePackageChange(e, index)} />
                            </div>
                            <div className='col-3'>
                                <label>Country of Origin</label>
                                <select
                                    className={`form-select1 form-control`}
                                    value={packageItem.countryOriginId}
                                    name='countryOriginId'
                                    onChange={(e) => handlePackageChange(e, index)}
                                >
                                    <option value="">Select Country</option>
                                    {countries?.map((country) => {
                                        return (
                                            <option
                                                key={country.id}
                                                selected={
                                                    country.id ===
                                                    shipmentObject.senderCountryID
                                                }
                                                value={country.id}
                                            >
                                                {country.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='col-2'>
                                <div className='actionInvoice'>
                                    {index + 1 === commodityObj.commodityList.length && (
                                        <button
                                            className="addplusicon shipment"
                                            onClick={() => handlePackage('add', index)}
                                        >
                                            <img alt="" src={pic9} />
                                        </button>
                                    )}
                                    {index > 0 && (
                                        <button
                                            className="addplusicon shipment removeParcel"
                                            onClick={() => handlePackage('remove', index)}
                                        >
                                            <img alt="" src={pic10} />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className='detailsBox mt-1'>
                    <div className='row'>
                        <div className='col-4'>
                            <label>Total Quantity</label>
                            <input name="totalQuantity" className='form-control' type={'text'} value={commodityObj?.totalQuantity || 0} disabled />
                        </div>
                        <div className='col-4'>
                            <label>Total Weight</label>
                            <input className='form-control' type={'text'} value={commodityObj?.totalWeight || 0} disabled />
                            <span className="txt-label">
                                {shipmentObject.dimensionUnit === "INCH" ? "lb" : "kg"}</span>
                        </div>
                        <div className='col-4'>
                            <label>Total Value</label>
                            <input className='form-control' type={'text'} value={commodityObj?.totalUnitValue || 0} disabled />
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}

        </div>
    );
}

export default CommercialInvoice;