import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    deleteOrder,
    getAllPackagesByServiceId,
    getAllServices,
    getSignatureOptionList,
    updateBulkOrder,
} from "../../../store/actions";
import pic10 from "../../../assets/minus.png";
import AlertDialogModal from "../../common/AlertDialog";
import { toast } from "react-toastify";
import "./UpdateOrder.css";
import { unitList } from '../../../constant';
import DatePicker from "react-datepicker";
import { convertToCm, convertToInch, validDecimalNum } from "../../../config/utils";

const UpdateOrders = (props) => {
    const { selectedData, setSelectedData, createdBy } = props;
    const services = useSelector((state) => state.shipment.services);
    const signatureList = useSelector((state) => state.shipment.signatureList);
    const packages = useSelector((state) => state.shipment.packages);
    const dispatch = useDispatch();
    const [dateError, setDateError] = useState("");
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [selectedCarrier, setSelectedCarrier] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});

    const batchObjCopy = {
        modifiedBy: createdBy?.Value?.employeeId,
        createdBy: createdBy?.Value?.employeeId,
        sellerId: createdBy?.Value?.sellerId,
        companyId: createdBy?.Value?.companyId,
        parcels: [
            {
                id: 0,
                length: "",
                width: "",
                height: "",
                weight: "",
                ounces: "",
            },
        ],
        packageId: 0,
        serviceId: 0,
        signatureId: 0,
        contentDescription: "",
        shipmentDate: moment(new Date(new Date())).format("YYYY-MM-DD"),
        orderID: [],
        dimensionUnit: "INCH",
    };

    const [batchObj, setBatchObj] = useState({
        modifiedBy: createdBy?.Value?.employeeId,
        signatureId: 0,
        sellerId: createdBy?.Value?.sellerId,
        companyId: createdBy?.Value?.companyId,
        carrierCompanyId: 0,
        createdBy: createdBy?.Value?.employeeId,
        packageId: 0,
        contentDescription: "",
        serviceId: 0,
        orderID: [],
        dimensionUnit: "INCH",
        shipmentDate: moment(new Date(new Date())).format("YYYY-MM-DD"),
        parcels: [
            {
                id: 0,
                length: "",
                width: "",
                height: "",
                weight: "",
                ounces: "",
            },
        ],
    });

    useEffect(() => {
        let findDiff = _.isEqual(batchObjCopy, batchObj);
        setIsFormEdited(findDiff);
        dispatch(getAllServices(false, false));
    }, [batchObj]);

    useEffect(() => {
        if (batchObj.carrierCompanyId) {
            let getCarrier = services.filter(
                (item) => item.carrierCompanyId === Number(batchObj.carrierCompanyId)
            );
            setSelectedCarrier(getCarrier);
        }
    }, [batchObj.carrierCompanyId]);

    const [batchUpdateObj, setBatchUpdateObj] = useState({
        editService: false,
        editDimensions: false,
        editDescription: false,
        editShipDate: false,
    });

    const toggleSection = (e, section) => {
        const { checked } = e.target;
        setBatchUpdateObj({
            ...batchUpdateObj,
            [section]: checked,
        });
    };

    useEffect(() => {
        if (batchObj.serviceId) {
            selectServicePackage(batchObj.serviceId);
            dispatch(getSignatureOptionList(batchObj.serviceId));
        }
    }, [batchObj.serviceId]);

    const selectServicePackage = (value) => {
        dispatch(getAllPackagesByServiceId(value));
    };

    useEffect(() => {
        if (selectedPackage?.shouldAutofill) {
            let copyShipObj = { ...batchObj };
            let updateParcel = copyShipObj.parcels.map((item, index) => {
                return {
                    ...item,
                    length: selectedPackage.length,
                    width: selectedPackage.width,
                    height: selectedPackage.height,
                };
            });
            copyShipObj.parcels = updateParcel;
            setBatchObj(copyShipObj);
        }
    }, [selectedPackage]);

    const handleServiceSelect = (e) => {
        const { value } = e.target;
        let findPackage = packages.filter((item) => item.id === Number(value));
        if (findPackage && findPackage.length) {
            setSelectedPackage(findPackage[0]);
        }
    }

    const handleDateValidation = (value) => {
        let currentDate = new Date();
        let selectedDate = new Date(value);
        selectedDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        if (moment(selectedDate).isBefore(currentDate)) {
            /* istanbul ignore next */
            setDateError("Shipment Date cannot be in the past");
        } else {
            setDateError("");
        }
    }

    const handleParcelValues = (batchObjClone, value) => {
        batchObjClone.weightUnit = value === "CMS" ? "KG" : "LBS";
        if (selectedPackage.shouldAutofill) {
            let updateParcel = batchObjClone.parcels.map((item) => {
                return {
                    ...item,
                    length: value === 'INCH' ? convertToInch(item.length) : convertToCm(item.length),
                    width: value === 'INCH' ? convertToInch(item.width) : convertToCm(item.width),
                    height: value === 'INCH' ? convertToInch(item.height) : convertToCm(item.height),
                }
            });
            batchObjClone.parcels = updateParcel;
        }
        return batchObjClone;
    }

    const handleFieldChange = (e, fieldName) => {
        const { value } = e.target;
        let batchObjClone = { ...batchObj };
        if (fieldName === "shipmentDate") {
            handleDateValidation(value);
        }
        if (fieldName === "dimensionUnit") {
            batchObjClone = handleParcelValues(batchObjClone, value);
        }
        batchObjClone[fieldName] = value
        setBatchObj(batchObjClone);
    };

    const handleSaveBatch = async () => {
        let saveObjData = { ...batchObj, ...batchUpdateObj };
        selectedData.shipmentDate = new Date(selectedData.shipmentDate);
        saveObjData.orderID = _.map(selectedData, "orderId");
        let validParcels = saveObjData.parcels.filter(
            (item) =>
                item.ounces || item.weight || item.length || item.width || item.height
        );
        saveObjData.parcels = validParcels;
        saveObjData.signatureId = parseInt(saveObjData.signatureId);
        saveObjData.carrierCompanyId = parseInt(saveObjData.carrierCompanyId);
        saveObjData.packageId = parseInt(saveObjData.packageId);
        saveObjData.serviceId = parseInt(saveObjData.serviceId);
        saveObjData.weightUnit = batchObj.dimensionUnit === "INCH" ? "LBS" : "KG";
        let res = await updateBulkOrder(saveObjData);
        /* istanbul ignore next */
        if (res.data && res.data.Status === 200) {
            props.getAllRecords(() => {
              toast.success("Orders Updated Successfully");
            });
        }
    };
    const addParcel = () => {
        /* istanbul ignore next */
        let batchClone = { ...batchObj };
        let data = {
            length: selectedPackage?.shouldAutofill ? selectedPackage.length : null,
            height: selectedPackage?.shouldAutofill ? selectedPackage.height : null,
            width: selectedPackage?.shouldAutofill ? selectedPackage.width : null,
            ounces: "",
            weight: "",
        };
        /* istanbul ignore next */
        batchClone.parcels.push(data);
        /* istanbul ignore next */
        setBatchObj(batchClone);
    };
    /* istanbul ignore next */
    const removeParcel = (index) => {
        /* istanbul ignore next */
        let data = { ...batchObj };
        data.parcels.splice(index, 1);
        setBatchObj(data);
    };
    const handleDeleteOrder = () => {
        // deleteOrder
        setModalShow(!modalShow);
    };

    /* istanbul ignore next */
    const handleYesDeleteAlert = async () => {
        /* istanbul ignore next */
        let deleteOrders = _.map(selectedData, "orderId");
        let data = {
            id: deleteOrders,
            createdBy: createdBy?.Value?.employeeId,
            modifiedBy: createdBy?.Value?.employeeId,
            sellerId: createdBy?.Value?.sellerId,
            companyId: createdBy?.Value?.companyId,
        };
        /* istanbul ignore next */
        let deleteRes = await deleteOrder(data);
        if (deleteRes.data.Status === 200) {
            toast.success("Order Deleted Successfully");
            setSelectedData([]);
            setModalShow(!modalShow);
            props.fetchAllBatches();
            props.getAllRecords();
        }
    };

    const handleParcelChange = (e, name, index) => {
        const { value } = e.target;
        let copyOrder = { ...batchObj };
        if (!isNaN(value) && validDecimalNum.test(value)) {
            copyOrder.parcels[index][name] = Number(value);
            setBatchObj(copyOrder);
        }
    };

    return (
        <div className="widgetBox batchActons">
            <h2>Batch Edit {selectedData?.length} Selected Orders</h2>
            <div className="editOptions row">
                <div className="col-12">
                    <div className="editHead">
                        <div>
                            <input
                                id={"editService"}
                                type={"checkbox"}
                                onChange={(e) => toggleSection(e, "editService")}
                                checked={batchUpdateObj.editService}
                            />
                        </div>
                        <label htmlFor="editService">
                            Edit Service Type, Package Type, Signature
                        </label>
                    </div>
                    {batchUpdateObj.editService && (
                        <div className="formDetail">
                            <select
                                value={batchObj.carrierCompanyId}
                                onChange={(e) => handleFieldChange(e, "carrierCompanyId")}
                                className="form-control carrierCompanyId"
                            >
                                <option value={0}>Select Carrier...</option>
                                {services?.map((carrier) => (
                                    <option
                                        key={carrier?.carrierCompanyId}
                                        value={carrier?.carrierCompanyId}
                                    >
                                        {carrier?.carrierCompanyName}
                                    </option>
                                ))}
                            </select>
                            <select
                                value={batchObj.serviceId}
                                onChange={(e) => {
                                    handleFieldChange(e, "serviceId");
                                }}
                                className="form-control serviceIdInput"
                            >
                                <option value={0}>Select Service...</option>
                                {Boolean(batchObj.carrierCompanyId) &&
                                    selectedCarrier.length > 0 &&
                                    selectedCarrier[0]?.services?.map((service) => (
                                        <option key={service?.id} value={service?.id}>
                                            {service?.serviceName}
                                        </option>
                                    ))}
                            </select>
                            <select
                                value={batchObj.packageId}
                                onChange={(e) => {
                                    handleFieldChange(e, "packageId");
                                    handleServiceSelect(e);
                                }}
                                disabled={!batchObj.serviceId}
                                className="form-control packageIdInput"
                            >
                                <option value={0}>Select package Type...</option>
                                {packages &&
                                    packages.length > 0 &&
                                    packages.map((item, index) => (
                                        <option key={item.id} value={item.id}>
                                            {item.packageName}
                                        </option>
                                    ))}
                            </select>
                            <div className="signature-container">
                                Signature
                                <select
                                    className="form-selectship sign-select form-control"
                                    onChange={(e) => handleFieldChange(e, "signatureId")}
                                >
                                    <option value={0}>Select Signature</option>
                                    {signatureList?.length > 0 &&
                                        signatureList.map((item, index) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-12">
                    <div className="editHead">
                        <div>
                            <input
                                id="editDimensions"
                                type={"checkbox"}
                                onChange={(e) => toggleSection(e, "editDimensions")}
                                checked={batchUpdateObj.editDimensions}
                            />
                        </div>
                        <label htmlFor="editDimensions">Edit Dimensions and Weight</label>
                    </div>
                    {batchUpdateObj.editDimensions && (
                        <div className="formDetail">
                            <div className="">Dimensions</div>
                            <div className="row">
                                <div className="col-6">
                                    <select
                                        className="unitDropdown form-control"
                                        onChange={(e) => handleFieldChange(e, "dimensionUnit")}
                                        value={batchObj.dimensionUnit}
                                    >
                                        {unitList.length > 0 &&
                                            unitList.map((item, index) => (
                                                <option key={item.value} value={item.value}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            {batchObj.parcels &&
                                batchObj.parcels.length > 0 &&
                                batchObj.parcels.map((item, index) => (
                                    <div key={item?.id}>
                                        <div className="row">
                                            <div className="col-4">
                                                <input
                                                    disabled={(!batchObj.serviceId && !batchObj.packageId) || selectedPackage?.shouldAutofill}
                                                    id="length"
                                                    onChange={(e) =>
                                                        handleParcelChange(e, "length", index)
                                                    }
                                                    value={item.length}
                                                    className="form-control length"
                                                    maxLength={10}
                                                    type="number"
                                                    placeholder="L"
                                                />
                                                <aside>
                                                    {batchObj.dimensionUnit === "INCH" ? "in" : "cm"}
                                                </aside>
                                            </div>
                                            <div className="col-4">
                                                <input
                                                    disabled={(!batchObj.serviceId && !batchObj.packageId) || selectedPackage?.shouldAutofill}
                                                    id="width"
                                                    onChange={(e) =>
                                                        handleParcelChange(e, "width", index)
                                                    }
                                                    value={item.width}
                                                    className="form-control"
                                                    maxLength={10}
                                                    type="number"
                                                    placeholder="W"
                                                />
                                                <aside>
                                                    {batchObj.dimensionUnit === "INCH" ? "in" : "cm"}
                                                </aside>
                                            </div>
                                            <div className="col-4">
                                                <input
                                                    disabled={(!batchObj.serviceId && !batchObj.packageId) || selectedPackage?.shouldAutofill}
                                                    id="height"
                                                    onChange={(e) =>
                                                        handleParcelChange(e, "height", index)
                                                    }
                                                    value={item.height}
                                                    className="form-control"
                                                    maxLength={10}
                                                    type="number"
                                                    placeholder="H"
                                                />
                                                <aside>
                                                    {batchObj.dimensionUnit === "INCH" ? "in" : "cm"}
                                                </aside>
                                            </div>
                                        </div>
                                        <div className="row custom-wei-onc">
                                            <div className="custom-wei-sub">
                                                <div className="col-5">
                                                    <div>
                                                        Weight (
                                                        <span>
                                                            {batchObj.dimensionUnit === "INCH" ? "lb" : "Kg"}
                                                        </span>
                                                        )
                                                    </div>
                                                    <input
                                                        min={0}
                                                        id="weight"
                                                        onChange={(e) =>
                                                            handleParcelChange(e, "weight", index)
                                                        }
                                                        value={item.weight}
                                                        placeholder=""
                                                        className="form-control weight"
                                                        maxLength={10}
                                                        type="number"
                                                        onKeyDown={(evt) =>
                                                            ["e", "E", "+", "-"].includes(evt.key) &&
                                                            evt.preventDefault()
                                                        }
                                                    />
                                                </div>
                                                <div className="col-5">
                                                    <div>
                                                        {batchObj.dimensionUnit === "INCH"
                                                            ? "Ounces"
                                                            : "Grams"}
                                                    </div>
                                                    <input
                                                        min={0}
                                                        id="ounces"
                                                        onChange={(e) =>
                                                            handleParcelChange(e, "ounces", index)
                                                        }
                                                        value={item.ounces}
                                                        placeholder=""
                                                        className="form-control ounces"
                                                        maxLength={10}
                                                        type="number"
                                                        onKeyDown={(evt) =>
                                                            ["e", "E", "+", "-"].includes(evt.key) &&
                                                            evt.preventDefault()
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 custom-add-btn">
                                                {index + 1 === batchObj.parcels.length &&
                                                    batchObj.parcels.length <= 4 && (
                                                        <button
                                                            className="addplusicon custom-1-btn"
                                                            onClick={addParcel}
                                                        >
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                            Add New
                                                        </button>
                                                    )}

                                                {index > 0 && (
                                                    <button
                                                        className="addplusicon removeParcelRow custom-minus-btn"
                                                        onClick={(e) => removeParcel(index)}
                                                    >
                                                        <img
                                                            alt=""
                                                            src={pic10}
                                                            style={{ height: "17px", width: "17px" }}
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
                <div className="col-12">
                    <div className="editHead">
                        <div>
                            <input
                                id="editDescription"
                                type={"checkbox"}
                                onChange={(e) => toggleSection(e, "editDescription")}
                                checked={batchUpdateObj.editDescription}
                            />
                        </div>
                        <label htmlFor="editDescription">Edit Content Description</label>
                    </div>
                    {batchUpdateObj.editDescription && (
                        <div className="formDetail">
                            <div>Content Description</div>
                            <input
                                className="form-control contentDescriptionInput"
                                type="text"
                                onChange={(e) => handleFieldChange(e, "contentDescription")}
                                value={batchObj.contentDescription}
                            />
                        </div>
                    )}
                </div>
                <div className="col-12">
                    <div className="editHead">
                        <div>
                            <input
                                id="editShipDate"
                                type={"checkbox"}
                                onChange={(e) => toggleSection(e, "editShipDate")}
                                checked={batchUpdateObj.editShipDate}
                            />
                        </div>
                        <label htmlFor="editShipDate">Shipment Date</label>
                    </div>
                    {batchUpdateObj.editShipDate && (
                        <div className="formDetail">
                            <div>Shipment Date</div>
                            <DatePicker
                                className={
                                    dateError
                                        ? "form-control dateField error"
                                        : "form-control dateField"
                                }
                                selected={new Date(batchObj.shipmentDate)}
                                value={batchObj.shipmentDate}
                                onChange={(date) => handleFieldChange({ target: { value: date } }, "shipmentDate")}
                                id="shipmentDateInput"
                            />
                            <p className="dateError">{dateError !== "" && dateError}</p>
                        </div>
                    )}
                </div>
                <div className="col-12 actions">
                    <button
                        className="btn cus-seconday-bg-btn"
                        onClick={handleSaveBatch}
                        disabled={isFormEdited || dateError}
                    >
                        Save Changes
                    </button>
                    <button
                        id="deleteOrderBtn"
                        className="btn cus-seconday-bg-btn"
                        onClick={handleDeleteOrder}
                    >
                        Delete Orders
                    </button>
                </div>
            </div>
            <AlertDialogModal
                modalClass={"modalDeleteWrapper"}
                modalShow={modalShow}
                handleCancelDeleteAlert={handleDeleteOrder}
                handleYesDeleteAlert={handleYesDeleteAlert}
            />
        </div>
    );
};

export default UpdateOrders;
