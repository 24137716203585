import React from "react";
import { useNavigate } from "react-router-dom";
import MarginLogo from "../../assets/icons/margin.svg";
import { barCode, barcodeList, featureSubmodule } from "../../constant";
import { useDispatch } from "react-redux";
import { barCodeMappingBackDetails, barCodeUploadBackDetails } from "../../store/actions/barcode.action";
import { checkPermission } from "../../config/utils";

export function Barcode(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { employeePermissions } = props;
  const cardList = barcodeList;
  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading">Barcode</div>
        <div className="container-fluid">
          <div className="cus-page-content-sec">
            <div className="content-container">
              <div className="cards setup-container">
                {cardList.map((card) => (
                  <>
                    {checkPermission(barCode, card.subModule, '', featureSubmodule, employeePermissions) && (
                      <div
                        className="setup-card"
                        onClick={() => {
                          dispatch(barCodeMappingBackDetails({}));
                          dispatch(barCodeUploadBackDetails({}));
                          navigate(card.path);
                        }}
                      >
                        <div className="icon">
                          {card.path === "/margin-configuration" ? (
                            <img
                              alt=""
                              className="cus-sm-icon-cls"
                              src={MarginLogo}
                              style={{ maxWidth: "30px" }}
                            />
                          ) : (
                            <i className={card.icon} />
                          )}
                        </div>
                        <div className="txt-name">{card.name}</div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
