import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as WmsTooltip } from "react-tooltip";
import { getAreaByWarehouseId, getBayByWarehouseId, getLevelByWarehouseId, getPositionByWarehouseId, getRowByWarehouseId, getWarehouseListing } from '../../../store/actions/inventory.action';
import DatePicker from "react-datepicker";
import { quantityTextRender, renderConsignmentType, renderTooltip } from '../../../config/utils';

const WarehouseDetails = (props) => {
    const {
        handleWarehouse,
        item,
        isEditable,
        handleInputBlur,
        addWarehouse,
        index,
        removeWarehouse,
        updateWareHouseDetails,
        userDetails,
        productForm,
    } = props;
    const dispatch = useDispatch();
    const { warehouseList } = useSelector((state) => state.InventoryManagement);
    const countries = useSelector((state) => state.shipment.countries);

    useEffect(() => {
        fetchWareHouses();
    }, [userDetails]);

    useEffect(() => {
        if (item.warehouseId) {
            fetchAreaDetails();
        }
    }, [item.warehouseId]);

    useEffect(() => {
        if (item.areaId) {
            fetchRowDetails();
        }
    }, [item.areaId]);

    useEffect(() => {
        if (item.rowId) {
            fetchBayDetails();
        }
    }, [item.rowId]);

    useEffect(() => {
        if (item.bayId) {
            fetchLevelsDetails();
        }
    }, [item.bayId]);

    useEffect(() => {
        if (item.levelId) {
            fetchPositionDetails();
        }
    }, [item.levelId]);

    const fetchPositionDetails = async () => {
        let data = {
            levelId: item.levelId
        }
        let getRowDetailsRes = await Promise.resolve(dispatch(getPositionByWarehouseId(data)));
        if (getRowDetailsRes.data && getRowDetailsRes.data.Status === 200) {
            updateWareHouseDetails(index, 'positionList', getRowDetailsRes.data.Entity);
        }
    }

    const fetchLevelsDetails = async () => {
        let data = {
            bayId: item.bayId
        }
        let getRowDetailsRes = await Promise.resolve(dispatch(getLevelByWarehouseId(data)));
        if (getRowDetailsRes.data && getRowDetailsRes.data.Status === 200) {
            updateWareHouseDetails(index, 'levelList', getRowDetailsRes.data.Entity);
        }
    }

    const fetchBayDetails = async () => {
        let data = {
            rowId: item.rowId
        }
        let getRowDetailsRes = await Promise.resolve(dispatch(getBayByWarehouseId(data)));
        if (getRowDetailsRes.data && getRowDetailsRes.data.Status === 200) {
            updateWareHouseDetails(index, 'bayList', getRowDetailsRes.data.Entity);
        }
    }
    const fetchRowDetails = async () => {
        let data = {
            areaId: item.areaId
        }
        let getRowDetailsRes = await Promise.resolve(dispatch(getRowByWarehouseId(data)));
        if (getRowDetailsRes.data && getRowDetailsRes.data.Status === 200) {
            updateWareHouseDetails(index, 'rowList', getRowDetailsRes.data.Entity);
        }
    }

    const fetchAreaDetails = async () => {
        let data = {
            warehouseId: item.warehouseId
        }
        let getAreaDetailsRes = await Promise.resolve(dispatch(getAreaByWarehouseId(data)));
        if (getAreaDetailsRes.data && getAreaDetailsRes.data.Status === 200) {
            updateWareHouseDetails(index, 'areaList', getAreaDetailsRes.data.Entity);
        }
    }

    const fetchWareHouses = () => {
        let data = {
            "pageIndex": 1,
            "pageSize": 100,
            "sortColumn": "",
            "sortOrder": "ASCENDING",
            "searchKeyword": "",
            "companySellerId": 0,
            "userRole": ""
        }
        dispatch(getWarehouseListing(data));
    }

    return (
        <div className='row addressForm mb-3' key={item?.id}>
            <div className='col-12 border-col'>
                <div className='row'>
                    <div className='col-2 col zIndex2'>
                        <label>Warehouse Name</label>
                        <div className='d_flex'>
                            <select
                                value={item.warehouseId}
                                className={`form-control countryOfOrigin mr-3 selectWarehouse`}
                                onChange={(e) => handleWarehouse(e, index)}
                                name='warehouseId'
                                disabled={!isEditable}
                            >
                                <option value={0}>Select Warehouse</option>
                                {warehouseList?.listWarehouse && warehouseList?.listWarehouse.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                            {Number(item.warehouseId) > 0 && (
                                <div className='detailBox '>
                                    <i id={`address-${index}`} className="fa fa-address-card-o" aria-hidden="true"></i>
                                    <WmsTooltip
                                        anchorId={`address-${index}`}
                                        data-tooltip-html={true}
                                        place="right"
                                        className="tooltipWidth"
                                    >
                                        <div className='tooltipAddress'>
                                            <p><b>Address</b></p>
                                            <p>{item?.address?.address1}, {item?.address?.zipcode}</p>
                                            <p>{item?.address?.state}, {item?.address?.country}</p>
                                        </div>
                                    </WmsTooltip>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-2'>
                        <label>Area</label>
                        <select
                            value={item.areaId}
                            className={`form-control countryOfOrigin mr-3`}
                            onChange={(e) => handleWarehouse(e, index)}
                            name='areaId'
                            disabled={!isEditable}
                        >
                            <option value={0}>Select Area</option>
                            {item?.areaList && item?.areaList.map((item) => (
                                <option key={item.Id} value={item.Id}>{item.AreaCode}</option>
                            ))}
                        </select>
                    </div>
                    <div className='col-2'>
                        <label>Row</label>
                        <select
                            value={item.rowId}
                            className={`form-control countryOfOrigin mr-3`}
                            onChange={(e) => handleWarehouse(e, index)}
                            name='rowId'
                            disabled={!isEditable}
                        >
                            <option value={0}>Select Row</option>
                            {item?.rowList && item?.rowList.map((item) => (
                                <option key={item.Id} value={item.Id}>{item.RowCode}</option>
                            ))}
                        </select>
                    </div>
                    <div className='col-2'>
                        <label>Bay</label>
                        <select
                            value={item.bayId}
                            className={`form-control countryOfOrigin mr-3`}
                            onChange={(e) => handleWarehouse(e, index)}
                            name='bayId'
                            disabled={!isEditable}
                        >
                            <option value={0}>Select Bay</option>
                            {item?.bayList && item?.bayList.map((item) => (
                                <option key={item.Id} value={item.Id} title={item.BayCode}>{item.BayCode}</option>
                            ))}
                        </select>
                    </div>
                    <div className='col-2'>
                        <label>Level</label>
                        <select
                            value={item.levelId}
                            className={`form-control countryOfOrigin mr-3`}
                            onChange={(e) => handleWarehouse(e, index)}
                            name='levelId'
                            disabled={!isEditable}
                        >
                            <option value={0}>Select Level</option>
                            {item?.levelList && item?.levelList.map((item) => (
                                <option key={item.Id} value={item.Id}>{item.LevelCode}</option>
                            ))}
                        </select>
                    </div>
                    <div className='col-2'>
                        <label>Position</label>
                        <select
                            value={item.positionId}
                            className={`form-control countryOfOrigin mr-3`}
                            onChange={(e) => handleWarehouse(e, index)}
                            name='positionId'
                            disabled={!isEditable}
                        >
                            <option value={0}>Select Position</option>
                            {item?.positionList && item?.positionList.map((item) => (
                                <option key={item.Id} value={item.Id}>{item.PositionCode}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2 col zIndex2'>
                        <label>Item Expiration</label>
                        <div className='d_flex'>
                            <DatePicker
                                data-testid={'itemExpiryDate'}
                                className="form-control itemExpiryDate"
                                showIcon
                                dateFormat="MM-dd-yyyy"
                                selected={item?.itemExpiryDate ? new Date(item?.itemExpiryDate) : null}
                                name="itemExpiryDate"
                                onChange={(e) => handleWarehouse({ target: { value: e, name: 'itemExpiryDate' } }, index)}
                                disabled={!isEditable}
                            />
                        </div>
                    </div>
                    <div className='col-2 col'>
                        <label>Country Of Origin</label>
                        <div className='d_flex'>
                            <select
                                value={item.countryOfOrigin}
                                className={`form-control countryOfOrigin`}
                                name='countryOfOrigin'
                                disabled={!isEditable}
                                onChange={(e) => handleWarehouse(e, index)}

                            >
                                <option>Select Country</option>
                                {countries?.map((country) => (
                                    <option
                                        value={country.iSO}
                                        key={country.id}
                                    >
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-2 col'>
                        <label>Seller Location</label>
                        <div className='d_flex'>
                            <input
                                className='form-control'
                                type={'text'}
                                name="sellerLocation"
                                value={item.sellerLocation}
                                onChange={(e) => handleWarehouse(e, index)}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                            />
                        </div>
                    </div>
                    <div className='col-2 col'>
                        <label>Supplier Code</label>
                        <div className='d_flex'>
                            <input
                                className='form-control'
                                type={'text'}
                                name="supplierCode"
                                value={item.supplierCode}
                                onChange={(e) => handleWarehouse(e, index)}
                                min={0}
                                disabled={!isEditable}
                            />
                        </div>
                    </div>
                    <div className='col-2 col'>
                        <label>Notes</label>
                        <div className='d_flex'>
                            <input
                                className='form-control'
                                type={'text'}
                                name="comments"
                                value={item.comments}
                                onChange={(e) => handleWarehouse(e, index)}
                                disabled={!isEditable}
                            />
                        </div>
                    </div>
                    <div className='col-1 iconBtns col'>
                        <button
                            className="addplusicon"
                            onClick={addWarehouse}
                            disabled={!isEditable}
                        >
                            <i className="fa fa-plus-circle fsIcon" aria-hidden="true"></i>
                        </button>
                        {productForm?.wareHouseDetails?.length > 1 &&
                            <button
                                className="addplusicon removeParcel"
                                onClick={(e) => removeWarehouse(index)}
                                disabled={!isEditable}
                            >
                                <i className="fa fa-minus-circle fsIcon" aria-hidden="true"></i>
                            </button>
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-4 col zIndex1'>
                        <label className='mr-1'>{renderConsignmentType(productForm.consignmentTypeId, true, Number(item.quantity))} <span className='error'>*</span> </label>

                        {Number(productForm.consignmentTypeId) === 3 && <>
                            <i className="fa fa-info-circle" aria-hidden="true" id={`mainQuantity-${item?.id}`}></i>
                            <WmsTooltip
                                anchorId={`mainQuantity-${item?.id}`}
                                content={quantityTextRender(item.quantity)}
                                place="bottom"
                                className="tooltipTxt"
                            />
                        </>}
                        <input
                            className='form-control'
                            type={'number'}
                            name="quantity"
                            value={item.quantity}
                            onChange={(e) => handleWarehouse(e, index)}
                            min={0}
                            onBlur={handleInputBlur}
                            onKeyDown={(evt) => ["e", '.', "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            disabled={!isEditable}
                        />
                    </div>
                    {Number(productForm.consignmentTypeId) !== 3 && (
                        <>
                            <div className="col-4 zIndex2">
                                <label>Sub Qty{renderConsignmentType(productForm.consignmentTypeId, false)} {' '}
                                    <>
                                        <i className="fa fa-info-circle" aria-hidden="true" id={`quantity-${item?.id}`}></i>
                                        <WmsTooltip
                                            anchorId={`quantity-${item?.id}`}
                                            content={renderTooltip(Number(productForm.consignmentTypeId), Number(item.subQuantity))}
                                            place="right"
                                            className="tooltipTxt"
                                        />
                                    </>
                                </label>
                                <input
                                    className={`form-control subQuantity`}
                                    type={"number"}
                                    min={0}
                                    onBlur={handleInputBlur}
                                    onKeyDown={(evt) => ["e", '.', "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    name="subQuantity"
                                    value={item.subQuantity}
                                    onChange={(e) => handleWarehouse(e, index)}
                                    disabled={!isEditable}
                                />
                            </div>

                            <div className="col-4 zIndex1">
                                <label>Sub Qty - Items (Total qty of items in Boxes)</label>
                                <input
                                    className={`form-control subQuantityItem`}
                                    type={"number"}
                                    min={0}
                                    onBlur={handleInputBlur}
                                    onKeyDown={(evt) => ["e", '.', "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    name="tertiarySubQuantity"
                                    value={item?.tertiarySubQuantity}
                                    onChange={(e) => handleWarehouse(e, index)}
                                    disabled={!isEditable}
                                />
                            </div>
                        </>
                    )}
                    <div className="col-4 col zIndex2">
                        <label>Storage Id</label>
                        <input
                            className={`form-control storageId`}
                            type={"text"}
                            onBlur={handleInputBlur}
                            name="storageId"
                            value={item?.storageId}
                            onChange={(e) => handleWarehouse(e, index)}
                            disabled={!isEditable}
                        />
                    </div>
                </div>

            </div >
        </div >
    );
}

export default WarehouseDetails;