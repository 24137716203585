import React from 'react';
import Table from "react-bootstrap/Table";
import BatchPagination from '../../batch/BatchPagination';

const SellerRateCardListing = (props) => {
    const {
        sortingColumn,
        handleSortingCoulmn,
        sortOrder,
        permissions,
        renderList,
        AllSellerRateCards,
        activeTab,
        sellernumberOfPage,
        numOfPage,
        sellerCurrentPage,
        sellerTotalPages,
        AllSellerRateCardsRecord,
        setsellerCurrentPage,
        setsellernumberOfPage
    } = props;
    return (
        <>

            <div className="main_content_table incomingTbl">
                <Table className="table  table-height">
                    <thead>
                        <tr>
                            <th>
                                <div
                                    className={
                                        sortingColumn === "SellerRateCardName"
                                            ? "active"
                                            : "sortColumn"
                                    }
                                    onClick={(e) => handleSortingCoulmn(e, "SellerRateCardName")}
                                >
                                    Name
                                    <i
                                        className={
                                            sortingColumn === "SellerRateCardName" &&
                                                sortOrder === "ASCENDING"
                                                ? "fa fa-arrow-up"
                                                : "fa fa-arrow-down"
                                        }
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            </th>
                            <th>
                                <div
                                    className={
                                        sortingColumn === "IsActive"
                                            ? "active IsActive"
                                            : "sortColumn IsActive"
                                    }
                                    onClick={(e) => handleSortingCoulmn(e, "IsActive")}
                                >
                                    Status
                                    <i
                                        className={
                                            sortingColumn === "IsActive" &&
                                                sortOrder === "ASCENDING"
                                                ? "fa fa-arrow-up"
                                                : "fa fa-arrow-down"
                                        }
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            </th>
                            <th>
                                <div
                                    className={
                                        sortingColumn === "CarrierProviderName"
                                            ? "active CarrierProviderName"
                                            : "sortColumn CarrierProviderName"
                                    }
                                    onClick={(e) => handleSortingCoulmn(e, "CarrierProviderName")}
                                >
                                    Carrier Provider
                                    <i
                                        className={
                                            sortingColumn === "CarrierProviderName" &&
                                                sortOrder === "ASCENDING"
                                                ? "fa fa-arrow-up"
                                                : "fa fa-arrow-down"
                                        }
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            </th>



                            {permissions?.baseRateCard && (
                                <th>Effective Sellers / Companies</th>
                            )}
                            <th>Effective From</th>
                            <th>Effective Till</th>
                        </tr>
                    </thead>
                    {renderList()}
                </Table>
            </div>
            {AllSellerRateCards && activeTab === 2 && (
                <BatchPagination
                    gridData={AllSellerRateCards}
                    totalVisualRecords={AllSellerRateCards?.length}
                    numberOfPage={sellernumberOfPage}
                    numOfPage={numOfPage}
                    currentPage={sellerCurrentPage}
                    totalPages={sellerTotalPages}
                    totalRecords={
                        AllSellerRateCardsRecord?.Entity?.TotalRecords
                    }
                    setCurrentPage={setsellerCurrentPage}
                    setNumberOfPage={setsellernumberOfPage}
                />
            )}
        </>
    );
}

export default SellerRateCardListing;