import React, { useEffect, useState } from 'react';
import { Button } from "bootstrap-4-react/lib/components";
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersCsv } from '../../store/actions/history';
import _ from "lodash";

const ExportOrderCsv = (props) => {
    const { gridData, csvData } = props;
    const [btnClicked, setBtnClicked] = useState(false);

    const { csvUrl } = useSelector((state) => state.HistoryReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        csvUrl && btnClicked && window.open(csvUrl);
    }, [csvUrl, btnClicked]);

    const handleOpenCsv = async () => {
        setBtnClicked(true);
        let findCheckedRecords = gridData.filter((item) => item.isChecked);
        let checkedRecords = _.map(findCheckedRecords, "ShipmentId");
        let dataServiceIdList = _.map(csvData.serviceIdList, "id");
        let dataCarrierIdList = _.map(csvData.carrierIdList, "id");
        let data = { ...csvData, shipmentIdList: checkedRecords, serviceIdList: dataServiceIdList, carrierIdList: dataCarrierIdList };
        dispatch(getOrdersCsv(data));
    }

    return (
        <Button
            className="cus-seconday-bg-btn top-btn"
            disabled={!gridData.length}
            onClick={handleOpenCsv}
        >
            Export to CSV
        </Button>
    );
}

export default ExportOrderCsv;