import React from "react";
import "../AddCompanyModal/topUpModal.css";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import plus from "../../../assets/plus.png";
import minus from "../../../assets/minus.png";
import {
  addNewCompany,
  addNewSeller,
  fetchAddressFromZipCode,
  getAllCountries,
  getStates,
} from "../../../store/actions";
import { handleInputValueTypes, validatePhoneNumber, validateZipCodeLength } from "../../../config/utils";
import { zipCodeRegex } from "../../../constant";
import Input from 'react-phone-number-input/input'

function AddAddressModal(props) {
  const { showModal = true } = props;
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [countryList, setcountryList] = useState();
  const [stateList, setstateList] = useState();
  const countries = useSelector((state) => state.shipment.countries);
  const USSates = useSelector((state) => state.shipment.states);

  const [formData, setFormData] = useState({
    createdDate: new Date(),
    modifiedDate: new Date(),
    addressModels: [
      {
        state: "",
        zipCode: "",
        city: "",
        country_Id: 218,
        country: 'United States',
        phoneNumber: "+1",
        addressFirst: "",
        addressSecond: "",
        addressThird: "",
      },
    ],
  });
  useEffect(() => {
    if (props?.type === "seller") {
      setFormData({
        ...formData,
        newSellerName: props?.SellerData?.newSellerName,
        companyIds: props?.SellerData?.companyIds,
        email: props?.SellerData?.email,
        steCode: props?.SellerData?.steCode,
        sellerCode: props?.SellerData?.sellerCode,
        id: props?.SellerData?.sellerId,
      });
    } else {
      setFormData({
        ...formData,
        name: props?.companyData?.name,
        id: props?.companyData?.companyId,
        email: props?.companyData?.email,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getStates(218));
  }, []);

  const addAddress = () => {
    const newAddresses = [
      ...formData.addressModels,
      {
        state: "",
        zipCode: "",
        city: "",
        phoneNumber: "",
        country_Id: 218,
        country: 'United States',
        addressFirst: "",
        addressSecond: "",
        addressThird: "",
      },
    ];
    setFormData({ ...formData, addressModels: newAddresses });
  };

  const removeAddress = (index) => {
    /* istanbul ignore next */
    if (formData.addressModels.length > 1) {
      const newAddresses = [...formData.addressModels];
      /* istanbul ignore next */
      newAddresses.splice(index, 1);
      setFormData({ ...formData, addressModels: newAddresses });
    }
  };

  const handleChange = (index, field, value) => {
    const newErrors = { ...errors };
    if (newErrors[`addressModels_${index}`]) {
      delete newErrors[`addressModels_${index}`][field];
    }
    const newAddresses = [...formData.addressModels];
    if (field === 'country_Id') {
      let allCountries = [...countries];
      let countryIndex = allCountries.findIndex(
        (item) => item.id === value.value
      );
      newAddresses[index][field] = value.value;
      newAddresses[index].country = value.label;
      newAddresses[index].phoneNumber = `+(${countries[countryIndex].phoneCountryCode}) `
    } else {
      newAddresses[index][field] = value;
    }

    setErrors(newErrors);
    setFormData({ ...formData, addressModels: newAddresses });
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (countries && countries.length) {
      /* istanbul ignore next */
      let data = countries.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setcountryList(data);
    } else {
      setcountryList([]);
    }
  }, [countries]);
  useEffect(() => {
    /* istanbul ignore next */
    if (USSates && USSates.length) {
      /* istanbul ignore next */
      let data = USSates.map((item) => {
        return {
          label: item.StateName,
          value: item.StateCode,
        };
      });
      setstateList(data);
    } else {
      setstateList([]);
    }
  }, [USSates]);

  const validateAddress = (address, isValid) => {
    const addressErrors = {};
    if (!address?.state) {
      isValid = false;
      addressErrors.state = "State is required";
    }

    if (address?.country_Id === 218) {
      if (!address?.zipCode) {
        isValid = false;
        addressErrors.zipCode = "Zip code is required";
      } else if (!zipCodeRegex.test(address?.zipCode)) {
        /* istanbul ignore next */
        isValid = false;
        addressErrors.zipCode = "Zip code should be in the format xxxxx or xxxxx-xxxx";
      }
    } else {
      if (!address?.zipCode) {
        isValid = false;
        addressErrors.zipCode = "Zip code is required";
        /* istanbul ignore next */
      }
      /* istanbul ignore next */
      if (validateZipCodeLength(address?.zipCode)) {
        isValid = false;
        addressErrors.zipCode = "Zip code should have a minimum length of 4";
      }
    }
    if (validatePhoneNumber(address?.phoneNumber)) {
      /* istanbul ignore next */
      isValid = false;
      addressErrors.phoneNumber =
        "Phone number should have a minimum of 10 digits";
    }
    if (!address?.phoneNumber) {
      isValid = false;
      addressErrors.phoneNumber = "Phone Number is required";

    }
    if (!address?.city) {
      isValid = false;
      addressErrors.city = "City is required";
    }

    if (!address?.addressFirst) {
      isValid = false;
      addressErrors.addressFirst = "Address 1 is required";
    }
    if (!address?.addressSecond) {
      isValid = false;
      addressErrors.addressSecond = "Address 2 is required";
    }

    return { address, isValid, addressErrors }
  }

  const validateAddresses = (isValid, newErrors) => {
    formData.addressModels.forEach((address, index) => {
      let addressErrors = {};
      let data = validateAddress(address, isValid);
      addressErrors = { ...addressErrors, ...data.addressErrors }
      isValid = data.isValid;
      // Store the address errors
      newErrors[`addressModels_${index}`] = addressErrors;
    });

    return { isValid, newErrors }
  }

  const saveTopUpData = () => {
    let isValid = true;
    let newErrors = {};

    // Validate addressModels
    const addressObj = validateAddresses(isValid, newErrors);
    isValid = addressObj.isValid;
    newErrors = { ...newErrors, ...addressObj.newErrors };
    if (isValid && props?.type === "seller") {
      /* istanbul ignore next */
      dispatch(
        addNewSeller({
          ...formData,
          companyIds: formData?.companyIds?.map((d) => d.Id).join(","),
        },
          undefined,
          true,
          formData?.id
        )
      );
      handleCancel();
    } else if (isValid) {
      dispatch(addNewCompany(formData, undefined, true, formData?.id));
      handleCancel();
    } else {
      setErrors(newErrors);
    }
  };

  const handleCancel = () => {
    /* istanbul ignore next */
    if (props && props?.onHide) {
      props.onHide();
    }
  };

  /* istanbul ignore next */
  const fetchAddressByZip = async (zipCode, index) => {
    if (zipCode) {
      let responseAddress = await Promise.resolve(dispatch(fetchAddressFromZipCode(zipCode)));
      if (responseAddress?.data?.Status === 200) {
        let addressRes = responseAddress?.data?.Entity;
        if (addressRes && addressRes?.Country) {
          let formDataObj = { ...formData };
          let selectedCountry = countryList?.filter((country) => country.label === addressRes?.Country)[0];
          formDataObj.addressModels[index].country = selectedCountry.label;
          formDataObj.addressModels[index].country_Id = selectedCountry.value;
          formDataObj.addressModels[index].city = addressRes.City || formDataObj.addressModels[index].city;
          formDataObj.addressModels[index].state = selectedCountry.value === 218 ? USSates.filter((state) => state.StateName === addressRes?.State)[0].StateCode : addressRes?.State;
          setFormData(formDataObj);
        }
      }
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      className="topUpModal"
      centered
      backdrop="static"
      ref={modalRef}
      show={showModal}
    >
      <div className="top_modal_header_container">
        <span className="">Add Address</span>
        <div className="modalDeleteWrapper">
          <button
            type="button"
            onClick={() => handleCancel()}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="modal_container_rate_card seller-Modal">
        {formData.addressModels.map((address, index) => (
          <div className="addressFields" key={address?.id}>
            <div className="row modal_input">
              <div className="col-4 ">
                <span className="input-label">Address {index + 1}-</span>
              </div>
            </div>

            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label">Country:</span>
              </div>
              <div className="col-8">
                <Select
                  options={countryList}
                  onChange={(value) =>
                    handleChange(index, "country_Id", value)
                  }
                  className="activeSellerListInput"
                  value={{
                    label: address?.country,
                    value: address?.country_Id,
                  }}
                  classNamePrefix="list"
                />
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].country_Id && (
                    <p className="error dalogError">
                      {errors[`addressModels_${index}`].country_Id}
                    </p>
                  )}
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel ">
                <span className="input-label">Zip Code:</span>
              </div>
              <div className="col-8">
                <div className={"select_react"}>
                  <input
                    onChange={(e) =>
                      handleChange(index, "zipCode", e.target.value)
                    }
                    onKeyDown={(e) => handleInputValueTypes(e, /^[0-9-]$/)}
                    className="form-control availableBalanceInput"
                    type="text"
                    value={address?.zipCode}
                    onBlur={(e) => fetchAddressByZip(e.target.value, index)}
                  />
                </div>
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].zipCode && (
                    <p className="error dalogError countrytype-error">
                      {errors[`addressModels_${index}`].zipCode}
                    </p>
                  )}
              </div>
            </div>
            {address?.country_Id === 218 ? (
              <div className="row modal_input">
                <div className="col-4 formLabel">
                  <span className="input-label">State:</span>
                </div>
                <div className="col-8">
                  <Select
                    options={stateList}
                    onChange={(value) =>
                      handleChange(index, "state", value.label)
                    }
                    value={{ label: address.state, value: "" }}
                    className="activeSellerListInput"
                    classNamePrefix="list"
                  />
                  {errors[`addressModels_${index}`] &&
                    errors[`addressModels_${index}`].state && (
                      <p className="error dalogError countrytype-error">
                        {errors[`addressModels_${index}`].state}
                      </p>
                    )}
                </div>
              </div>
            ) : (
              <div className="row modal_input">
                <div className="col-4 formLabel">
                  <span className="input-label">State:</span>
                </div>
                <div className="col-8">
                  <input
                    onChange={(e) =>
                      handleChange(index, "state", e.target.value)
                    }
                    onKeyDown={(e) => handleInputValueTypes(e, /^[A-Za-z]$/)}
                    className="form-control availableBalanceInput"
                    type="text"
                    value={address?.state}
                  />
                  {errors[`addressModels_${index}`] &&
                    errors[`addressModels_${index}`].state && (
                      <p className="error dalogError countrytype-error">
                        {errors[`addressModels_${index}`].state}
                      </p>
                    )}
                </div>
              </div>
            )}

            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label">City:</span>
              </div>
              <div className="col-8">
                <input
                  onChange={(e) => handleChange(index, "city", e.target.value)}
                  onKeyDown={(e) => handleInputValueTypes(e, /^[A-Za-z]$/)}
                  className="form-control availableBalanceInput"
                  type="text"
                  value={address?.city}
                />
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].city && (
                    <p className="error dalogError countrytype-error">
                      {errors[`addressModels_${index}`].city}
                    </p>
                  )}
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel ">
                <span className="input-label">Phone Number:</span>
              </div>
              <div className="col-8">
                <div className={"select_react"}>
                  <Input
                    value={address?.phoneNumber}
                    name="phoneNumber"
                    onChange={(e) =>
                      handleChange(index, "phoneNumber", e)
                    }
                    className={`form-control`}
                  />

                  {errors[`addressModels_${index}`] &&
                    errors[`addressModels_${index}`].phoneNumber && (
                      <p className="error dalogError countrytype-error">
                        {errors[`addressModels_${index}`].phoneNumber}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label">Address Line 1:</span>
              </div>
              <div className="col-8">
                <input
                  onChange={(e) =>
                    handleChange(index, "addressFirst", e.target.value)
                  }
                  onKeyDown={(e) =>
                    handleInputValueTypes(e, /^[a-zA-Z0-9\s-]$/)
                  }
                  value={address?.addressFirst}
                  className="form-control availableBalanceInput"
                  type="text"
                />
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].addressFirst && (
                    <p className="error dalogError countrytype-error">
                      {errors[`addressModels_${index}`].addressFirst}
                    </p>
                  )}
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label">Address Line 2:</span>
              </div>
              <div className="col-8">
                <input
                  value={address?.addressSecond}
                  onChange={(e) =>
                    handleChange(index, "addressSecond", e.target.value)
                  }
                  onKeyDown={(e) =>
                    handleInputValueTypes(e, /^[a-zA-Z0-9\s-]$/)
                  }
                  className="form-control availableBalanceInput"
                  type="text"
                />
                {errors[`addressModels_${index}`] &&
                  errors[`addressModels_${index}`].addressSecond && (
                    <p className="error dalogError countrytype-error">
                      {errors[`addressModels_${index}`].addressSecond}
                    </p>
                  )}
              </div>
            </div>
            <div className="row modal_input">
              <div className="col-4 formLabel">
                <span className="input-label">Address Line 3:</span>
              </div>
              <div className="col-8">
                <input
                  value={address?.addressThird}
                  onChange={(e) =>
                    handleChange(index, "addressThird", e.target.value)
                  }
                  onKeyDown={(e) =>
                    handleInputValueTypes(e, /^[a-zA-Z0-9\s-]$/)
                  }
                  className="form-control availableBalanceInput"
                  type="text"
                />
              </div>
            </div>
            {formData.addressModels.length > 1 && (
              <button
                className="addplusicon shipment removeParcel"
                title="Remove this Address"
                onClick={() => removeAddress(index)}
              >
                <img alt="" src={minus} />
              </button>
            )}
          </div>
        ))}

        <button
          className="addplusicon shipment"
          title="Add new Address"
          onClick={addAddress}
        >
          <img alt="" className="plusIcon" src={plus} />
        </button>

        <Row className="modaL_footer">
          <div
            className="col-12 noPadding"
            style={{ textAlign: "right" }}
            xs={12}
          >
            <Button
              className="btn cus-seconday-bg-btn cancel-btn"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              id="saveTopUpBtn"
              className="btn cus-seconday-bg-btn marginLeft5"
              onClick={(e) => {
                saveTopUpData();
              }}
            >
              Add
            </Button>
          </div>
        </Row>
      </div>
    </Modal>
  );
}

export default AddAddressModal;
