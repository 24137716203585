import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_COMPANY_SUCCESS_BEFORE,
  GET_COMPANY_FAIL_BEFORE,
  UPDATE_USER,
  CONFIGURATION_SUCCESS,
  GET_USERS_COMPANY_SELLER_FAILED,
  GET_USERS_COMPANY_SELLER_SUCCESS,
  SHOW_LOADER,
} from "../types";
const user = JSON.parse(localStorage.getItem("user"));
const initialState = {
  isLoggedIn: false,
  user,
  companyList: [],
  configurations: [],
  showLoader: false
};
export default function (state = initialState, action = "") {
  const { type, payload } = action;
  switch (type) {
    case GET_COMPANY_SUCCESS_BEFORE:
      return {
        ...state,
        isLoggedIn: false,
        companyList: payload.company,
      };
    case GET_COMPANY_FAIL_BEFORE:
      return {
        ...state,
        isLoggedIn: false,
        companyList: [],
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        companyList: [],
      };
    case UPDATE_USER:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };
    case CONFIGURATION_SUCCESS:
      return {
        ...state,
        configurations: payload
      };
    case GET_USERS_COMPANY_SELLER_SUCCESS:
      return {
        ...state,
        companyList: payload
      };
    case GET_USERS_COMPANY_SELLER_FAILED:
      return {
        ...state,
        companyList: []
      };
    case SHOW_LOADER:
      return {
        ...state,
        showLoader: payload
      };
    default:
      return state;
  }
}
