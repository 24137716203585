import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountries, getDimensionUnitList, getWeightUnitList } from '../../../store/actions';
import {
    createConsignmentElement,
    createConsole,
    deleteConsignments,
    getAllConsignmentCarrierType,
    getAllConsignmentStatus,
    getAllConsignmentType,
    getConsignmentStatusListById,
    getInboundById,
    getInboundDefaultValues,
    updateConsignmentMedia,
    updateConsoleById
} from '../../../store/actions/inventory.action';
import { Tooltip as WmsTooltip } from "react-tooltip";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddPaletteBox from './PaletteBox';
import AddPaletteDialog from './AddPalette';
import LoaderSpinner from '../../common/Loader';
import { allowedExtensions, consoleObject, elementItemObj, elementObj } from '../../../constant';
import { toast } from "react-toastify";
import { appendFilesToUploadData, createElemPayload, findElementStatus, findItems } from '../../../config/utils';
import ImageSlider from '../../incoming/ImageSlider';
import noImage from "../../../assets/No-Image-Placeholder.png";
import InLargeImageSlider from '../../modal/InlargeImageSlider';
import ConsoleConnection from './consoleConnection';

/* istanbul ignore next */
const ConsoleComponent = () => {
    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [elements, setElements] = useState([]);
    const [originalResponseConsignment, setOriginalResponseConsignment] = useState({});
    const countries = useSelector((state) => state.shipment.countries);
    const userDetail = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const { consignmentTypes, consignmentAllStatus } = useSelector((state) => state.InventoryManagement);
    const [error, setErrors] = useState({});
    const [consignmentTypeList, setConsignmentTypeList] = useState([]);
    const [isEditable, setIsEditable] = useState(location?.state?.isEdit);
    const { dimensionUnitList, weightUnitList } = useSelector((state) => state.shipment);
    const [showPaletteBoxObj, setShowPaletteBoxObj] = useState({ show: false, type: '' });
    const [showConnectionBoxObj, setShowConnectionBoxObj] = useState({ show: false, type: '' });
    const [consignmentStatusList, setConsignmentStatusList] = useState([]);
    const [image, setImage] = useState([]);
    const [weightUnitListing, setWeightUnitListing] = useState(false);
    const [dimensionUnitListing, setDimensionUnitListing] = useState(false);
    const [allCountries, setAllCountries] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [showInlargeModal, setshowInlargeModal] = useState(false);
    const [deletePayload, setDeletePayload] = useState([]);
    const [consginmentStatuses, setConsginmentStatuses] = useState([]);
    const backUrl = location?.state?.backUrl;

    useEffect(() => {
        if (weightUnitList.length) {
            setWeightUnitListing(weightUnitList)
        }
        if (dimensionUnitList.length) {
            setDimensionUnitListing(dimensionUnitList)
        }
    }, [weightUnitList, dimensionUnitList]);

    useEffect(() => {
        setFormData({
            ...formData,
            consignmentStatusId: findElementStatus(elements, formData, formData.consignmentTypeId),
        });
    }, [elements]);

    useEffect(() => {
        if (countries.length) {
            let allCounty = [...countries];
            setAllCountries(allCounty);
        }
    }, [countries]);

    useEffect(() => {
        if (!dimensionUnitList.length) {
            dispatch(getDimensionUnitList());
        }

        if (!weightUnitList.length) {
            dispatch(getWeightUnitList());
        }
    }, [dimensionUnitList, weightUnitList]);


    const fetchStatuses = () => {
        getConsignmentStatusListById(id).then(res => {
            if (res.data.Status === 200) {
                setConsginmentStatuses(res.data.EntityList || []);
            }
        });
    }

    const handleDeleteImage = (index) => {
        let images = [...image];
        images.splice(index, 1);
        setImage(images);
    };

    const handleImageUpload = (e) => {
        let files = [...e.target.files];
        try {
            if (files.length > 0 && files.length <= 5) {
                let filterFiles = files.filter((file, index) => {
                    const extension = file.name.split(".").slice(-1).pop();
                    const fileSize = Math.round(file.size / 1024);
                    /* istanbul ignore next */
                    if (!allowedExtensions.includes(extension ? extension.toLowerCase() : "")) {
                        files.splice(index, 1);
                        toast.error("Unsupported File format", 1000);
                        return false;
                    } else if (fileSize > 1024) {
                        files.splice(index, 1);
                        e.target.value = "";
                        toast.info("Each picture size should be less than 1MB", 1000);
                        return false;
                    }
                    return file;
                });

                setErrors({ ...error, image: "" });
                setImage(filterFiles);
                /* istanbul ignore next */
            } else if (files.length > 5) {
                toast.info("Only 5 images can be upload at one time", 1000);
                e.target.value = "";
            } else {
                e.target.value = "";
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (!consignmentAllStatus?.length) {
            dispatch(getAllConsignmentStatus());
        } else {
            setConsignmentStatusList(consignmentAllStatus);
        }
    }, [consignmentAllStatus]);

    useEffect(() => {
        if (Number(id)) {
            fetchInboundById();
            fetchStatuses();
        } else {
            let conObj = { ...consoleObject }
            fetchDefaultValues(conObj);
        }
    }, [id]);

    useEffect(() => {
        if (userDetail?.Value?.isSuperAdmin || userDetail?.Value?.roleName === 'Warehouse Worker' || userDetail?.Value?.roleName === 'Warehouse Manager') {
            if (Number(id) && originalResponseConsignment.consignmentStatusId === 3) {
                setIsEditable(false);
            }
        } else {
            if (Number(id) && originalResponseConsignment.consignmentStatusId > 1) {
                setIsEditable(false);
            }
        }
    }, [id, originalResponseConsignment]);

    useEffect(() => {
        if (!consignmentTypes.length) {
            dispatch(getAllConsignmentType());
        } else {
            setConsignmentTypeList(consignmentTypes);
        }
    }, [consignmentTypes]);

    useEffect(() => {
        if (!countries.length) {
            dispatch(getAllCountries());
        }
        fetchCarriers();
    }, []);

    const fetchCarriers = () => {
        getAllConsignmentCarrierType().then(res => {
            if (res.data.Status === 200) {
                setCarriers(res.data.EntityList);
            }
        });
    }

    const fetchDefaultValues = async (formObject) => {
        getInboundDefaultValues().then(res => {
            if (res.data.Status === 200) {
                let rr = { ...formObject };
                if (!Number(id)) {
                    rr.consignmentNumber = res.data.Entity.ConsignmentNumber;
                    rr.countryOfOriginId = res.data.Entity.CountryId;
                }
                rr.sellerCode = res.data.Entity.SellerCode;
                setFormData(rr);
            }
        });
    }

    const handleElementJson = (inBoundObj) => {
        if (inBoundObj.consignmentTypeId === 2) {
            let createObj = JSON.parse(JSON.stringify(elementObj));
            createObj.consignmentElements = inBoundObj.consignmentElements
            setElements([createObj]);
        } else if (inBoundObj.consignmentTypeId === 3) {
            let createObj = [JSON.parse(JSON.stringify(elementItemObj))];
            createObj[0].consignmentElements[0].consignmentElements = inBoundObj.consignmentElements
            setElements(createObj);
        } else {
            setElements(inBoundObj.consignmentElements);
        }
    }

    const fetchInboundById = async () => {
        setLoader(true);
        let dataRes = await Promise.resolve(dispatch(getInboundById(id)));
        if (dataRes.data.Status === 200) {
            setOriginalResponseConsignment(JSON.parse(JSON.stringify(dataRes.data.Entity)));
            fetchDefaultValues(dataRes.data.Entity);
            handleElementJson(dataRes.data.Entity);
        }
        setLoader(false);
    };

    const handleFormValue = (e) => {
        const { name, value } = e.target;
        if (name === 'consignmentTypeId') {
            setElements([]);
        }
        setFormData({ ...formData, [name]: value });
        setErrors({ ...error, [name]: '' });
    };

    const handleStatusChange = (e) => {
        const { name, value } = e.target;
        if (Number(value) < originalResponseConsignment.consignmentStatusId && id > 0) {
            toast.error('Consignment status cannot be move to backward state');
        } else {
            setFormData({ ...formData, [name]: value });
            setErrors({ ...error, [name]: '' });
        }
    }

    const handleCancelPalette = () => {
        setShowPaletteBoxObj({
            ...showPaletteBoxObj,
            show: false
        });
    };


    const handleBack = () => {
        if (backUrl) {
            navigate(backUrl, {
                state: {
                    isEditable: isEditable,
                    type: location?.state?.historyType
                }
            });
        } else {
            navigate('/inbound');
        }
    }

    const shouldDisableComponent = () => {
        const isSuperAdmin = userDetail?.Value?.isSuperAdmin;
        const isWarehouseWorkerOrManager = 
            userDetail?.Value?.roleName === 'Warehouse Worker' || 
            userDetail?.Value?.roleName === 'Warehouse Manager';
        const consignmentStatusId = originalResponseConsignment.consignmentStatusId;
    
        const isConsignmentStatusValid = (isSuperAdmin && originalResponseConsignment.consignmentStatusId === 3) || (!isSuperAdmin && !isWarehouseWorkerOrManager && consignmentStatusId > 1)
    
        return loader || !isEditable || (Number(id) && isConsignmentStatusValid);
    };

    const updateMedia = async (type, consignmentId) => {
        if (image.length > 0) {
            let uploadData = new FormData();
            uploadData.append("ConsignmentId", consignmentId);
            uploadData = appendFilesToUploadData(uploadData, image, 'Attachments');
            let uploadMediaRes = await Promise.resolve(dispatch(updateConsignmentMedia(uploadData)));
            if (uploadMediaRes.data && uploadMediaRes.data.Status === 200) {
                handleCreateElements(consignmentId, type);
                setLoader(false);
            }
        } else {
            handleCreateElements(consignmentId, type);
        }
    }

    const handleItems = (numberOfItems, consignments) => {
        return numberOfItems > 0 && consignments.length > 0 && numberOfItems !== consignments.length
    }

    const handleBoxPalletConnectionStatus = (pallet, isValid, errorObj) => {
        const boxesWithConnection = pallet.consignmentElements.filter((box) => box.shippingStatusId > 0).length;
        const totalBoxes = pallet.consignmentElements.length;
        if (totalBoxes > 0 && boxesWithConnection && boxesWithConnection !== totalBoxes) {
            isValid = false;
            errorObj.msg = `Provide connection for all the ${Number(formData.consignmentTypeId) === 1 ? 'boxes' : 'Items'}`;
        } else if (totalBoxes === 0 && pallet.shippingStatusId === 0) {
            isValid = false;
            errorObj.msg = `Please provide connection detail`;
        } else if (totalBoxes > 0 && boxesWithConnection === 0 && pallet.shippingStatusId === 0) {
            isValid = false;
            errorObj.msg = `Please provide connection detail, either at ${Number(formData.consignmentTypeId) === 1 ? 'Pallet or Box level' : 'Box or Item level'}`;
        }
        return {
            errorObj: errorObj,
            isValid: isValid
        }
    }

    const handleConnections = (isValid, errorObj, palletsToValidate) => {
        palletsToValidate.forEach((pallet) => {
            let numberOfItems = 0;
            pallet?.consignmentElements?.forEach(datum => numberOfItems += datum.consignmentElements.length);
            let consignments = findItems(pallet);
            if (handleItems(numberOfItems, consignments)) {
                isValid = false;
                errorObj.msg = `Provide connection for all the Items`;
            } else if (pallet.consignmentElements.length && !numberOfItems) {
                let resObj = handleBoxPalletConnectionStatus(pallet, isValid, errorObj);
                isValid = resObj.isValid
                errorObj = resObj.errorObj
            }
            else {
                isValid = true;
                errorObj.msg = '';
            }
        });

        return {
            errorObj: errorObj,
            isValid: isValid
        }
    }

    const handleConnectionValidation = () => {
        let elems = [...elements];
        let isValid = true;
        let errorObj = {};
        let validation = {};

        if (elems.length) {
            const palletsToValidate = Number(formData.consignmentTypeId) === 1 || Number(formData.consignmentTypeId) === 2 ? elems : elems[0].consignmentElements;
            validation = handleConnections(isValid, errorObj, JSON.parse(JSON.stringify(palletsToValidate)));
            if (!validation.isValid) {
                toast.error(validation.errorObj.msg);
            }
            return validation.isValid
        }

        return true;
    };

    const handleValidations = () => {
        let error = {};
        let isFormValid = true;
        if (!formData.consignmentNumber) {
            error.consignmentNumber = 'This is required.'
            isFormValid = false;
        }
        if (!Number(formData.carrierTypeId)) {
            error.carrierTypeId = 'This is required.'
            isFormValid = false;
        }
        if (Number(formData.consignmentStatusId) > 1 && !formData.trackingNumber) {
            error.trackingNumber = 'This is required.'
            isFormValid = false;
        }
        setErrors(error);
        return isFormValid && handleConnectionValidation();
    }

    const handleSaveConsole = async () => {
        if (handleValidations()) {
            if (Number(id) === 0) {
                let payloadData = { ...formData };
                payloadData.carrierTypeId = Number(formData.carrierTypeId);
                payloadData.countryOfOriginId = Number(formData.countryOfOriginId);
                payloadData.consignmentTypeId = Number(formData.consignmentTypeId);
                payloadData.consignmentStatusId = Number(formData.consignmentStatusId);
                setLoader(true);
                let createConsoleRes = await Promise.resolve(dispatch(createConsole(payloadData)));
                if (createConsoleRes.data.Status === 200) {
                    if (createConsoleRes.data.Entity.id === 0) {
                        toast.error(createConsoleRes.data.Entity.resultMessage);
                        setLoader(false);
                    } else {
                        updateMedia('created', createConsoleRes.data.Entity.id);
                    }
                } else {
                    toast.error('Something went wrong');
                    setLoader(false);
                }
            } else {
                handleUpdateInbound();
            }
        }
    }

    const handleDeleteElement = async () => {
        let dataObj = {
            elements: [...deletePayload],
        }
        let deleteRes = await Promise.resolve(dispatch(deleteConsignments(dataObj)));
        if (deleteRes.data.Status !== 200) {
            toast.error('Something went wrong');
            setLoader(false);
        }
    };

    const handleCreateElements = async (inBoundId, type) => {
        if (elements.length) {
            let uploadData = {}
            uploadData.consignmentId = inBoundId;
            uploadData.consignmentElements = createElemPayload(Number(formData.consignmentTypeId), elements);
            let createConsignmentRes = await Promise.resolve(dispatch(createConsignmentElement(uploadData)));
            if (createConsignmentRes && createConsignmentRes.data.Status === 200) {
                toast.success(`Consignment ${type} Successfully`);
                setLoader(false);
                handleBack();
            } else {
                toast.error(`Something went wrong.`);
                setLoader(false);
            }
        } else {
            handleBack();
            toast.success(`Consignment ${type} Successfully`);
        }
    }

    const handleUpdateInbound = async (type) => {
        setLoader(true);
        if (deletePayload.length) {
            handleDeleteElement();
        }
        let payloadData = { ...formData };
        payloadData.carrierTypeId = Number(formData.carrierTypeId);
        payloadData.countryOfOriginId = Number(formData.countryOfOriginId);
        payloadData.consignmentTypeId = Number(formData.consignmentTypeId);
        payloadData.consignmentStatusId = Number(formData.consignmentStatusId);
        let updateConsoleRes = await Promise.resolve(dispatch(updateConsoleById(payloadData)));
        if (updateConsoleRes.data.Status === 200 && updateConsoleRes.data.Entity.id !== 0) {
            updateMedia('updated', id);
        } else {
            toast.error(updateConsoleRes.data.Entity.resultMessage);
            setLoader(false);
        }
    }

    const handleAddConsoleType = (type) => {
        setShowPaletteBoxObj({
            ...showPaletteBoxObj,
            show: true,
            type: type,
            action: 'ADD'
        });
    }

    const handleCheck = (e, index, boxIndex, type, itemIndex) => {
        const { name, checked } = e.target;
        let allElem = JSON.parse(JSON.stringify([...elements]));
        if (type === 'Pallet') {
            allElem[index][name] = checked;
        } else if (type === 'Box') {
            allElem[index].consignmentElements[boxIndex].isChecked = checked;
        } else if (type === 'Item') {
            allElem[index].consignmentElements[boxIndex].consignmentElements[itemIndex].isChecked = checked;
        }
        setElements(allElem);
    }

    const handleEditRecord = (type, paletteIndex, boxIndex, itemIndex, action) => {
        setShowPaletteBoxObj({
            ...showPaletteBoxObj,
            show: true,
            type: type,
            action: action,
            paletteIndex: paletteIndex,
            itemIndex: itemIndex,
            boxIndex: boxIndex,
        });
    }

    const handleBlurInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
    }

    const handleCancelConnection = () => {
        setShowConnectionBoxObj({
            ...showConnectionBoxObj,
            show: false
        });
    };

    const handleConsoleType = () => {
        if (Number(formData.consignmentTypeId) === 1) {
            return "Pallet"
        } else {
            return Number(formData.consignmentTypeId) === 2 ? "Box" : "Item"
        }
    }

    const handleConsoleConnection = (type) => {
        setShowConnectionBoxObj({
            ...showConnectionBoxObj,
            type: type,
            show: true
        });
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <span>Console</span>
                    <div className="top_right_button_container">
                        <div className="top_right_button_container">
                            <button
                                className="btn cus-seconday-bg-btn top-btn"
                                onClick={handleSaveConsole}
                                disabled={shouldDisableComponent()}
                            >
                                Save
                            </button>
                            <button
                                className="btn cus-seconday-bg-btn top-btn"
                                onClick={() => setIsEditable(true)}
                                disabled={(loader || isEditable) || (Number(id) && formData.consignmentStatusId > 1 && !userDetail?.Value?.isSuperAdmin) || (formData.consignmentStatusId === 3)}
                            >
                                Edit
                            </button>
                            <button className="btn cus-primary-transparent-btn" onClick={handleBack} disabled={loader}>
                                Back
                            </button>
                        </div>
                    </div>
                </div>
                {loader && <LoaderSpinner />}

                <div className="cus-page-content-sec">
                    <div className='row equalHeight'>
                        <div className={Number(id) > 0 ? 'col-9' : 'col-12'}>
                            <div className="incoming_wrapper main_content_table addressDetailWrapper">
                                <div className='row mb-3'>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Consignment Id <span className='require-field'>*</span></label>
                                        <input
                                            className={`form-control`}
                                            type={'text'}
                                            name="consignmentNumber"
                                            value={formData.consignmentNumber}
                                            onChange={handleFormValue}
                                            disabled={!isEditable || Number(id) > 0}
                                            onBlur={handleBlurInput}
                                        />
                                        <div className='error'>{error.consignmentNumber}</div>
                                    </div>

                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Carrier <span className='require-field'>*</span></label>
                                        <select
                                            className={`form-control carrierSelect`}
                                            onChange={handleFormValue}
                                            value={formData.carrierTypeId}
                                            disabled={!isEditable}
                                            name={'carrierTypeId'}>
                                            <option value={'0'}>Select Carrier</option>
                                            {carriers?.map((item) => (
                                                <option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.carrierName}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='error'>{error.carrierTypeId}</div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Tracking Number</label>
                                        <input
                                            className={`form-control`}
                                            type={'text'}
                                            name="trackingNumber"
                                            value={formData.trackingNumber}
                                            onChange={handleFormValue}
                                            disabled={!isEditable}
                                            onBlur={handleBlurInput}
                                        />
                                        <div className='error'>{error.trackingNumber}</div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Sub Tracking Number</label>
                                        <input
                                            className={`form-control`}
                                            type={'text'}
                                            name="subTrackingNumber"
                                            value={formData.subTrackingNumber}
                                            onChange={handleFormValue}
                                            disabled={!isEditable}
                                            onBlur={handleBlurInput}
                                        />
                                        <div className='error'>{error.subTrackingNumber}</div>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Console Type</label>
                                        <select
                                            value={formData.consignmentTypeId}
                                            className={`form-control consignmentTypeId`}
                                            onChange={handleFormValue}
                                            name='consignmentTypeId'
                                            disabled={!isEditable || Number(id) > 0}
                                        >
                                            {consignmentTypeList?.map((item) => (
                                                <option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='error'>{error.consignmentTypeId}</div>
                                    </div>


                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label className='zIndex1'>Status {consginmentStatuses.length > 0 && (
                                            <>
                                                <i className="fa fa-info-circle" aria-hidden="true" id="infoStatus"></i>
                                                <WmsTooltip
                                                    anchorId={`infoStatus`}
                                                    place="right"
                                                    data-tooltip-html={true}
                                                    className="tooltipWidth"
                                                >
                                                    <ul>
                                                        {consginmentStatuses.length > 0 && consginmentStatuses.map((list) => (
                                                            <li key={list.name}>{list.name} - {list.status}</li>
                                                        ))}
                                                    </ul>
                                                </WmsTooltip>
                                            </>
                                        )}
                                        </label>
                                        <select
                                            className={`form-control carrierSelect`}
                                            onChange={handleStatusChange}
                                            value={formData.consignmentStatusId}
                                            disabled={!isEditable}
                                            name={'consignmentStatusId'}>
                                            {consignmentStatusList?.map((item) => (
                                                <option
                                                    value={item.id}
                                                    key={item.id}
                                                    disabled={item.id === 3 || item.id === 4}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Origin Country</label>
                                        <select
                                            className={`form-control countrySelect`}
                                            onChange={handleFormValue}
                                            value={Number(formData.countryOfOriginId)}
                                            name={'countryOfOriginId'}
                                            disabled={!isEditable}
                                        >
                                            <option>Select</option>
                                            {allCountries?.map((country) => {
                                                return (
                                                    <option
                                                        value={country.id}
                                                        key={country.id}
                                                    >
                                                        {country.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                        <label>Images</label>
                                        <div className="fileUpload inventory">
                                            <button
                                                disabled={!isEditable}
                                                type="button"
                                                className="btn cus-seconday-bg-btn"
                                            >
                                                Upload images
                                            </button>
                                            <input
                                                className="hiddenFileField"
                                                onChange={handleImageUpload}
                                                disabled={!isEditable}
                                                type="file"
                                                id="customFile"
                                                multiple
                                            />
                                        </div>
                                        <div>
                                            {image.length > 0 &&
                                                image.map((item, index) => (
                                                    <div
                                                        className="selectedFile"
                                                        key={item?.id}
                                                    >
                                                        <div className="fileName">{item.name}</div>
                                                        <button
                                                            className='deleteImg'
                                                            onClick={() => handleDeleteImage(index)}
                                                        >
                                                            <i
                                                                className="fa fa-times"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </button>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-3 dimesntionsBlock'>
                                    <div className='col-4'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-9 col-sm-12'>
                                                <label>Weight</label>
                                                <input
                                                    className={`form-control numInput`}
                                                    type={'number'}
                                                    name="weight"
                                                    min={0}
                                                    value={formData.weight}
                                                    onChange={handleFormValue}
                                                    disabled={!isEditable}
                                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                    onBlur={handleBlurInput}
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                                <label>Unit</label>
                                                <select
                                                    className={`form-control countrySelect`}
                                                    onChange={handleFormValue}
                                                    value={formData.weightUnitId}
                                                    name={'weightUnitId'}
                                                    disabled={!isEditable}
                                                    onBlur={handleBlurInput}
                                                >
                                                    {weightUnitListing.length > 0 && weightUnitListing.map((weight) => (
                                                        <option key={weight.id} value={weight.id}>{weight.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-8'>
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <label>Length</label>
                                                <input
                                                    className={`form-control numInput`}
                                                    type={'number'}
                                                    name="length"
                                                    value={formData.length}
                                                    onChange={handleFormValue}
                                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                    disabled={!isEditable}
                                                    min={0}
                                                    onBlur={handleBlurInput}
                                                />
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <label>Width</label>
                                                <input
                                                    className={`form-control numInput`}
                                                    type={'number'}
                                                    name="width"
                                                    value={formData.width}
                                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                    onChange={handleFormValue}
                                                    disabled={!isEditable}
                                                    min={0}
                                                    onBlur={handleBlurInput}
                                                />
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <label>Height</label>
                                                <input
                                                    className={`form-control numInput`}
                                                    type={'number'}
                                                    name="height"
                                                    value={formData.height}
                                                    onChange={handleFormValue}
                                                    min={0}
                                                    disabled={!isEditable}
                                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                    onBlur={handleBlurInput}
                                                />
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                                <label>Dimension Unit</label>
                                                <select
                                                    className={`form-control countrySelect`}
                                                    onChange={handleFormValue}
                                                    value={formData.dimensionUnitId}
                                                    name={'dimensionUnitId'}
                                                    disabled={!isEditable}
                                                >
                                                    {dimensionUnitListing.length > 0 && dimensionUnitListing.map((dimension) => (
                                                        <option key={dimension.id} value={dimension.id}>{dimension.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <label>Comments</label>
                                        <textarea
                                            className={`form-control textArea`}
                                            type={'text'}
                                            name="comments"
                                            value={formData.comments}
                                            onChange={handleFormValue}
                                            disabled={!isEditable}
                                            onBlur={handleBlurInput}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Number(id) > 0 && (
                            <div className='col-3 inlineFlex'>
                                <div className="main_content_table addressDetailWrapper imageBlock" style={{ textAlign: 'center' }}>
                                    {formData?.images?.length > 0 ? (
                                        <ImageSlider
                                            isView={isEditable}
                                            images={formData.images}
                                            showLargeSliderModal={showInlargeModal}
                                            setShowLargeSliderModal={setshowInlargeModal}
                                        />
                                    ) : (
                                        <img src={noImage} alt="" className='inBoundDefaultImage' />
                                    )}
                                </div>
                            </div>
                        )}

                        {formData?.images?.length > 0 &&
                            <InLargeImageSlider
                                popupTitle={'Product'}
                                view={true}
                                images={formData?.images}
                                showModal={showInlargeModal}
                                setShowLargeSliderModal={setshowInlargeModal}
                            />
                        }
                    </div>
                    <div className='console-conn'>
                        {(
                            <button
                                className='btn iconBtn'
                                onClick={() => handleConsoleConnection(handleConsoleType())}
                                disabled={!elements?.length}

                            >
                                {'Shortcut to Set or View Connection'}
                            </button>
                        )}
                    </div>
                    <div className='row equalHeight palleteWrapper'>
                        {Number(formData.consignmentTypeId) === 1 && (
                            <div className='col-4 inlineFlex'>
                                <AddPaletteBox
                                    isEditable={isEditable}
                                    list={elements}
                                    type="Pallet"
                                    handleAddConsoleType={handleAddConsoleType}
                                    handleCheck={handleCheck}
                                    elements={elements}
                                    setElements={setElements}
                                    handleEditRecord={handleEditRecord}
                                    formData={formData}
                                    deletePayload={deletePayload}
                                    setDeletePayload={setDeletePayload}
                                />
                            </div>
                        )}
                        {(Number(formData.consignmentTypeId) === 1 || Number(formData.consignmentTypeId) === 2) && (
                            <div className='col-4 inlineFlex'>
                                <AddPaletteBox
                                    isEditable={isEditable}
                                    type="Box"
                                    handleAddConsoleType={handleAddConsoleType}
                                    handleCheck={handleCheck}
                                    elements={elements}
                                    setElements={setElements}
                                    handleEditRecord={handleEditRecord}
                                    formData={formData}
                                    deletePayload={deletePayload}
                                    setDeletePayload={setDeletePayload}
                                />
                            </div>
                        )}
                        {(Number(formData.consignmentTypeId) === 1 || Number(formData.consignmentTypeId) === 2 || Number(formData.consignmentTypeId) === 3) && (
                            <div className='col-4 inlineFlex'>
                                <AddPaletteBox
                                    isEditable={isEditable}
                                    formData={formData}
                                    type="Item"
                                    handleAddConsoleType={handleAddConsoleType}
                                    handleCheck={handleCheck}
                                    handleEditRecord={handleEditRecord}
                                    elements={elements}
                                    setElements={setElements}
                                    deletePayload={deletePayload}
                                    setDeletePayload={setDeletePayload}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {showPaletteBoxObj.show && (
                    <AddPaletteDialog
                        showPaletteBoxObj={showPaletteBoxObj}
                        handleCancelPalette={handleCancelPalette}
                        setElements={setElements}
                        elements={elements}
                        type={showPaletteBoxObj.type}
                        inBoundObject={formData}
                        allCountries={allCountries}
                        dimensionUnitListing={dimensionUnitListing}
                        weightUnitListing={weightUnitListing}
                        consignmentStatusList={consignmentStatusList}
                        consignmentTypeList={consignmentTypeList}
                        userDetail={userDetail}
                    />
                )}
                {showConnectionBoxObj.show && (
                    <ConsoleConnection
                        showPaletteBoxObj={showConnectionBoxObj}
                        handleCancelPalette={handleCancelConnection}
                        inBoundObject={formData}
                        type={showConnectionBoxObj.type}
                        elements={elements}
                        isEditable={isEditable}
                        handleCheck={handleCheck}
                        id={id}
                        setElements={setElements}
                        fetchInboundById={fetchInboundById}
                    />
                )}
            </div>
        </main>
    )
}
export default ConsoleComponent;
