import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { getSelectList } from '../../../config/utils';
import { useSelector } from 'react-redux';
import { fetchCompanyFilters, fetchSellerFilters } from '../../../store/actions/rateCard';
import {
    getAllSellersList
} from "../../../store/actions";
import _ from 'lodash';

const ProductListingFilter = (props) => {
    const { showFilter, handleReset, fetchProductList, dispatch, filterData, setfilterData, sellerList, setSellerList, allSellers, setAllSellers } = props;
    const [companyList, setCompanyList] = useState([]);
    const { filterCompanies, filterSellers } = useSelector((state) => state.RateCardReducer);

    useEffect(() => {
        let companyList = filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
        let sellerList = filterSellers?.Entity?.listSellerMasterOrdersEntity || [];
        if (companyList.length) {
            setCompanyList(getSelectList(filterCompanies?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
        }
        if (sellerList.length) {
            setAllSellers(getSelectList(sellerList, 'id', 'newSellerName'));
            setSellerList(getSelectList(sellerList, 'id', 'newSellerName'));
          }
    }, [filterCompanies, filterSellers]);

    const handleMultiSelect = (value, fieldName) => {
        setfilterData({
            ...filterData,
            [fieldName]: value
        });
        if (fieldName === "companyIdList") {
            let companyData = _.map(value, "Id");
            fetchSellers(companyData);
            setfilterData({ ...filterData, [fieldName]: value, sellerIdList: null });
            if (filterData?.sellerIdList?.length){
                const filteredSellers = filterData.sellerIdList.filter(seller => 
                  companyData.includes(parseInt(seller.companyIds))
                );    
                setfilterData({...filterData, sellerIdList: filteredSellers, companyIdList: value})
              } 
        }
    }

    const fetchSellers = async (companyList) => {
        if (companyList.length === 0) {
            setSellerList(allSellers);
            return;
          }
        let data = {
            companyIds: companyList,
        };
        let res = await getAllSellersList(data);
        if (res?.data?.Status === 200) {
            let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
                (item) => {
                    return {
                        ...item,
                        label: item.sellerAndCompany,
                        value: item.id,
                    };
                }
            );
            setSellerList(dataList);
        }
    };

    useEffect(() => {
        let data = {
            pageIndex: 1,
            pageSize: 10,
            sortColumn: 'name',
            sortOrder: 'ascending'
        };
        let sellerData = {
            screenType: 2,
          };
        dispatch(fetchCompanyFilters(data));
        dispatch(fetchSellerFilters(sellerData));
    }, []);

    return (
        <div className={showFilter ? 'filterContainer filterContainerZIndex show historyFilter history-margin zIndex2' : 'filterContainer historyFilter'}>
            <div className='row mb10'>
                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Company</label>
                        </div>
                        <div className='col-12'>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                options={companyList}
                                classNamePrefix="list"
                                id='companyIdList'
                                name="companyIdList"
                                value={filterData?.companyIdList}
                                onChange={(value) =>
                                    handleMultiSelect(value, "companyIdList")
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Seller</label>
                        </div>
                        <div className='col-12'>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                options={sellerList}
                                classNamePrefix="list"
                                id='sellerIdList'
                                name="sellerIdList"
                                value={filterData?.sellerIdList}
                                onChange={(value) =>
                                    handleMultiSelect(value, "sellerIdList")
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'></label>
                        </div>
                        <div className='col-12 flexEnd'>
                            <button
                                className="btn cus-primary-transparent-btn top-btn"
                                onClick={(e) => handleReset(e, 'clear')}
                            >
                                Clear Filter
                            </button>
                            <button
                                className="cus-seconday-bg-btn btn filterBtn"
                                onClick={() => fetchProductList(true)}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ProductListingFilter;