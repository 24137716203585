import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../fundManagement/fundmanagement.css";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "bootstrap-4-react/lib/components";
import BatchPagination from "../batch/BatchPagination";
import edit_icon from "../../assets/edit_icon.svg";
import eye_icon from "../../assets/eye_icon.svg";
import { getAllAssociatedUsers, getAllUsers, userListBackDetails } from "../../store/actions";
import { EditUserManagement } from "./EditUserManagement";
import AddUserModal from "../modal/AddUserModal";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import LoaderSpinner from "../common/Loader";
import _ from "lodash";
import { checkPermission } from "../../config/utils";
import { accountsManagement, featureSubmodulePermission, permssionCreate, permssionEdit, permssionView, SUBF_USER } from "../../constant";

export function UserManagement(props) {
  const { type, companyId, sellerId, isTab } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserValue = useSelector((state) => state.auth.user);
  const getUserData = useSelector((state) => state?.company?.getAllUsersData);
  const getAllUsersDataStatus = useSelector((state) => state?.company?.getAllUsersDataStatus);
  const { userBackDetails } = useSelector((state) => state?.company);
  const [totalPages, setTotalPages] = useState(1);
  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    sortColumn: "",
    sortOrder: 'DESCENDING',
  };
  const [filterValue, setFilterValue] = useState({});
  const [userModalShow, setUserModalShow] = useState(false);
  const [userData, setUserData] = useState();
  const [editMode, seteditMode] = useState();
  const numOfPage = [30, 50, 100, 200, 500, 1000, 2000];
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(userBackDetails)) {
      setFilterValue({ ...filterValue, ...userBackDetails });
    } else {
      setFilterValue({ ...dataLoad });
    }
  }, [])

  useEffect(() => {
    if (getAllUsersDataStatus === 'PENDING') {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [getAllUsersDataStatus])

  /* istanbul ignore next */
  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterValue.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setFilterValue({
      ...filterValue,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleUserView = (rowData) => {
    dispatch(userListBackDetails(filterValue))
    if (type) {
      props.setAssociatedUser(true);
      setUserData(rowData);
      seteditMode(true);
    } else {
      navigate(`/editUser/user-${rowData?.id}`, {
        state: {
          userdata: rowData,
          editable: true,
        },
      });
    }
  };
  const handleUserEdit = (rowData) => {
    dispatch(userListBackDetails(filterValue))
    if (type) {
      props.setAssociatedUser(true);
      setUserData(rowData);
      seteditMode(false);
    } else {
      navigate(`/editUser/user-${rowData?.id}`, {
        state: {
          userdata: rowData,
          editable: false,
        },
      });
    }
  };

  useEffect(() => {

    if (type) {
      dispatch(
        getAllAssociatedUsers(
          setTotalPages,
          filterValue.pageSize,
          filterValue.pageIndex,
          filterValue,
          companyId,
          sellerId
        )
      );
    } else {
      if (filterValue.pageIndex) {
        dispatch(
          getAllUsers(setTotalPages, filterValue.pageSize, filterValue.pageIndex, filterValue)
        );
      }
    }
  }, [UserValue, filterValue.pageIndex, filterValue.pageSize, type, filterValue.sortColumn, filterValue.sortOrder]);

  const handleContainerClass = () => {
    return isTab ? "inTabElement main__container" : "main__container";
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterValue.sortColumn === columnName && filterValue.sortOrder === "ASCENDING" ? "fa fa-arrow-up" : "fa fa-arrow-down";
    }
    return filterValue.sortColumn === columnName ? 'active' : 'sortColumn'
  };

  return (
    <>
      {type === "associatedUser" ? (
        <div>
          <div className={handleContainerClass()}>
            {props.associatedUser ? (
              <EditUserManagement
                userData={userData}
                editMode={editMode}
                seteditMode={seteditMode}
                type={type}
                associatedUser={props.associatedUser}
                setAssociatedUser={props.setAssociatedUser}
                filterValue={filterValue}
                currentPage={filterValue.pageIndex}
                numberOfPage={filterValue.pageSize}
                setTotalPages={setTotalPages}
                companyId={companyId}
                sellerId={sellerId}
              />
            ) : (
              <>
                {userModalShow && (
                  <AddUserModal
                    show={userModalShow}
                    setTotalPages={setTotalPages}
                    onHide={() => setUserModalShow(false)}
                    handleUserView={handleUserView}
                    filterValue={filterValue}
                    currentPage={filterValue.pageIndex}
                    numberOfPage={filterValue.pageSize}
                    companyId={companyId}
                    sellerId={sellerId}
                    type={type}
                  />
                )}
                <div className="main__tile_heading cus-btn-include-sec">
                  Associated Users
                  <div className="top_right_button_container">
                    {checkPermission(accountsManagement, SUBF_USER, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                      <>
                        <Button
                          className="cus-seconday-bg-btn"
                          onClick={() => setUserModalShow(true)}
                        >
                          Add User
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div className="main_content_table company-table incomingTbl">
                  <Table className="table  table-height cus-left-right-col-layout">
                    <thead border="Green">
                      <tr>
                        <th className="head-company">
                          <div
                            className={handleSortingClass("UserName")}
                            onClick={(e) => handleSortingCoulmn(e, "UserName")}
                          >
                            User Name
                            <i
                              className={handleSortingClass("UserName", true)}
                              aria-hidden="true"
                            ></i>
                          </div>
                        </th>
                        <th>Email Address</th>
                        <th>Phone Number</th>
                        <th>
                          <span className="action-text">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getUserData?.Entity?.listUserOrdersEntity?.map((d) => (
                        <tr key={d?.id}>
                          <td>{d?.newUserName}</td>
                          <td>{d?.userEmailId}</td>
                          <td>{d?.phoneNumber}</td>
                          <td>
                            <img
                              className="View-Icon"
                              onClick={() => handleUserView(d)}
                              src={eye_icon}
                              alt=""
                            ></img>
                            <img
                              className="View-Icon"
                              onClick={() => handleUserEdit(d)}
                              src={edit_icon}
                              alt=""
                            ></img>
                          </td>
                        </tr>
                      ))}
                      {!getUserData?.Entity?.listUserOrdersEntity.length && (
                        <tr>
                          <td colSpan={"4"} align="center">
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                {getUserData?.Entity?.listUserOrdersEntity && (
                  <BatchPagination
                    gridData={getUserData?.Entity?.listUserOrdersEntity}
                    totalVisualRecords={
                      getUserData?.Entity?.listUserOrdersEntity?.length
                    }
                    numberOfPage={filterValue.pageSize}
                    numOfPage={numOfPage}
                    currentPage={filterValue.pageIndex}
                    totalPages={totalPages || 0}
                    totalRecords={getUserData?.Entity?.TotalRecords}
                    setCurrentPage={(value) => setFilterValue({ ...filterValue, pageIndex: value })}
                    setNumberOfPage={(value) => setFilterValue({ ...filterValue, pageSize: value })}
                  />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <main>
          {userModalShow && (
            /* istanbul ignore next */
            <AddUserModal
              show={userModalShow}
              setTotalPages={setTotalPages}
              // activeSellerList={activeSellerList}
              onHide={() => setUserModalShow(false)}
              // getRateCards={getRateCards}
              handleUserView={handleUserView}
              filterValue={filterValue}
              currentPage={filterValue.pageIndex}
              numberOfPage={filterValue.pageSize}
            />
          )}

          <div className="main__container">
            <div className="main__tile_heading cus-btn-include-sec">
              User Management
              <div className="top_right_button_container">
                {checkPermission(accountsManagement, SUBF_USER, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                  <Button
                    className="cus-seconday-bg-btn"
                    onClick={() => setUserModalShow(true)}
                  >
                    Add User
                  </Button>
                )}
              </div>
            </div>
            {loader && <LoaderSpinner />}
            <div className="main_content_table  company-table incomingTbl">
              <Table className={`table  table-height cus-left-right-col-layout ${loader ? "disableTble" : ""}`}>
                <thead border="Green">
                  <tr>
                    <th className="head-company">
                      <div
                        className={handleSortingClass("UserName")}
                        onClick={(e) => handleSortingCoulmn(e, "UserName")}
                      >
                        User Name
                        <i
                          className={handleSortingClass("UserName", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th>Role</th>
                    <th>
                      <span className="action-text">Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getUserData?.Entity?.listUserOrdersEntity?.map((d, i) => (
                    <tr key={d?.id}>
                      <td>{d?.newUserName}</td>
                      <td>{d?.userEmailId}</td>
                      <td>
                        {formatPhoneNumberIntl(d?.phoneNumber) ||
                          d?.phoneNumber}
                      </td>
                      <td>{d?.userRoleName}</td>
                      <td>
                      {checkPermission(accountsManagement, SUBF_USER, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                        <img
                          className="mr-3 View-Icon"
                          onClick={() => handleUserView(d)}
                          src={eye_icon}
                          alt=""
                        />
                      )}
                        {checkPermission(accountsManagement, SUBF_USER, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                          <img
                            onClick={() => handleUserEdit(d)}
                            src={edit_icon}
                            alt=""
                            className="mr-3 View-Icon"
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {getUserData?.Entity?.listUserOrdersEntity && (
              <BatchPagination
                gridData={getUserData?.Entity?.listUserOrdersEntity}
                totalVisualRecords={
                  getUserData?.Entity?.listUserOrdersEntity?.length
                }
                numberOfPage={filterValue.pageSize}
                numOfPage={numOfPage}
                currentPage={filterValue.pageIndex}
                totalPages={totalPages || 0}
                totalRecords={getUserData?.Entity?.TotalRecords}
                setCurrentPage={(value) => setFilterValue({ ...filterValue, pageIndex: value })}
                setNumberOfPage={(value) => setFilterValue({ ...filterValue, pageSize: value })}
              />
            )}
          </div>
        </main>
      )}
    </>
  );
}
