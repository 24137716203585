import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { getSelectList } from '../../../config/utils';
import { getAllConsignmentCarrierType, getAllConsignmentStatus, getAllConsignmentType } from '../../../store/actions/inventory.action';
import { useSelector } from 'react-redux';
import { fetchCompanyFilters, fetchSellerFilters } from '../../../store/actions/rateCard';
import {
    getAllSellersList
} from "../../../store/actions";
import _ from 'lodash';

const InboundFilter = (props) => {
    const { showFilter, handleReset, handleFilter, dispatch, filterData, setfilterData,  sellerList, setSellerList, allSellers, setAllSellers } = props;
    const [allCarriers, setAllCarriers] = useState([]);
    const [consignmentList, setConsignmentList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [consignmentStatusList, setConsignmentStatusList] = useState([]);
    const { consignmentTypes, consignmentAllStatus } = useSelector((state) => state.InventoryManagement);
    const { filterCompanies, filterSellers } = useSelector((state) => state.RateCardReducer);
    const UserValue = useSelector((state) => state.auth.user);

    useEffect(() => {
        let companyList = filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
        let sellerList = filterSellers?.Entity?.listSellerMasterOrdersEntity || [];
        if (companyList.length) {
            setCompanyList(getSelectList(filterCompanies?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
        }
        if (sellerList.length) {
            setAllSellers(getSelectList(sellerList, 'id', 'newSellerName'));
            setSellerList(getSelectList(sellerList, 'id', 'newSellerName'));
          }
    }, [filterCompanies, filterSellers]);

    const fetchSellers = async (companyList) => {
        if (companyList.length === 0) {
            setSellerList(allSellers);
            return;
          }
        let data = {
            companyIds: companyList,
        };
        let res = await getAllSellersList(data);
        if (res?.data?.Status === 200) {
            let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
                (item) => {
                    return {
                        ...item,
                        label: item.sellerAndCompany,
                        value: item.id,
                    };
                }
            );
            setSellerList(dataList);
        }
    };

    /* istanbul ignore next */
    const handleMultiSelect = (value, fieldName) => {
        setfilterData({
            ...filterData,
            [fieldName]: value
        });
        if (fieldName === "companyIdList") {
            let companyData = _.map(value, "Id");
            fetchSellers(companyData);
            if (filterData?.sellerIdList?.length){
                const filteredSellers = filterData.sellerIdList.filter(seller => 
                  companyData.includes(parseInt(seller.companyIds))
                );    
                setfilterData({...filterData, sellerIdList: filteredSellers, companyIdList: value})
              }        }
    }

    useEffect(() => {
        let data = {
            pageIndex: 1,
            pageSize: 10,
            sortColumn: 'name',
            sortOrder: 'ascending'
        };
        let sellerData = {
            screenType: 2,
          };
        dispatch(fetchCompanyFilters(data));
        dispatch(fetchSellerFilters(sellerData));
    }, []);

    useEffect(() => {
        fetchCarriers();
    }, [])

    const fetchCarriers = () => {
        getAllConsignmentCarrierType().then(res => {
            if (res.data.Status === 200) {
                let carriersData = getSelectList(res.data.EntityList, 'id', 'carrierName');
                setAllCarriers(carriersData);
            }
        });
    }

    useEffect(() => {
        if (!consignmentTypes.length) {
            dispatch(getAllConsignmentType());
        } else {
            let consignmentData = getSelectList(consignmentTypes, 'id', 'name');
            setConsignmentList(consignmentData);
        }
    }, [consignmentTypes]);


    useEffect(() => {
        if (!consignmentAllStatus?.length) {
            dispatch(getAllConsignmentStatus());
        } else {
            let selectConsignmentStatusData = getSelectList(consignmentAllStatus, 'id', 'name');
            setConsignmentStatusList(selectConsignmentStatusData);
        }
    }, [consignmentAllStatus]);


    return (
        <div className={showFilter ? 'filterContainer show historyFilter history-margin' : 'filterContainer historyFilter'}>
            <div className='row mb10'>
                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Consignment Type</label>
                        </div>
                        <div className='col-12'>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                options={consignmentList}
                                classNamePrefix="list"
                                id='consignmentTypeIdList'
                                name="consignmentTypeIdList"
                                value={filterData?.consignmentTypeIdList}
                                onChange={(value) =>
                                    handleMultiSelect(value, "consignmentTypeIdList")
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='col-3'>
                    <div className='row carriers'>
                        <div className='col-12'>
                            <label className='labelHead'>Carrier</label>
                        </div>
                        <div className='col-12'>
                            <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={allCarriers}
                                classNamePrefix="allCarriers"
                                name="carrierIdList"
                                id="carrierIdList"
                                value={filterData.carrierIdList}
                                onChange={(value) =>
                                    handleMultiSelect(value, "carrierIdList")
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Status</label>
                        </div>
                        <div className='col-12'>
                            <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={consignmentStatusList}
                                classNamePrefix="listStatus"
                                id="consignmentStatusIdList"
                                name="consignmentStatusIdList"
                                value={filterData?.consignmentStatusIdList}
                                onChange={(value) =>
                                    handleMultiSelect(value, "consignmentStatusIdList")
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Consignment Id</label>
                        </div>
                        <div className='col-12'>
                            <input
                                type="text"
                                className='form-control searchText consignmentId'
                                value={filterData.consignmentNumber}
                                onChange={(e) => handleMultiSelect(e.target.value, "consignmentNumber")}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb10'>

                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Tracking Number</label>
                        </div>
                        <div className='col-12'>
                            <input
                                type="text"
                                className='form-control searchText trackingNumber'
                                value={filterData.trackingNumber}
                                onChange={(e) => handleMultiSelect(e.target.value, "trackingNumber")}
                            />
                        </div>
                    </div>
                </div>
                {UserValue?.Value?.roleName !== 'Seller' && (
                    <>
                    <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Company</label>
                        </div>
                        <div className='col-12'>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                options={companyList}
                                classNamePrefix="list"
                                id='companyIdList'
                                name="companyIdList"
                                value={filterData?.companyIdList}
                                onChange={(value) =>
                                    handleMultiSelect(value, "companyIdList")
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Seller</label>
                        </div>
                        <div className='col-12'>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                options={sellerList}
                                classNamePrefix="list"
                                id='sellerIdList'
                                name="sellerIdList"
                                value={filterData?.sellerIdList}
                                onChange={(value) =>
                                    handleMultiSelect(value, "sellerIdList")
                                }
                            />
                        </div>
                    </div>
                </div>
                    </>
                )}
                <div className='col-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'></label>
                        </div>
                        <div className='col-12 flexEnd'>
                            <button
                                className="btn cus-primary-transparent-btn top-btn"
                                onClick={(e) => handleReset(e, 'clear')}
                            >
                                Clear Filter
                            </button>
                            <button
                                className="cus-seconday-bg-btn btn filterBtn"
                                onClick={() => handleFilter(true)}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default InboundFilter;