import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { verifyUserName } from "../../store/actions/auth";
import "../../components/login/bootstraplogin.css";
import MainLogoV from "../../assets/images/main-logo-vertical.png";
import MainLogoAi from "../../assets/images/bannerLogin.svg"
import EmailIcon from "../../assets/icons/email-icon.png";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const ForgotPassword = (props) => {
  const checkBtn = useRef();
  const [username, setUsername] = useState("");
  let hostName = window.location.hostname;
  const loading = false;
  const { isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const validateEmail = (event) => {
    event.preventDefault();
    dispatch(verifyUserName(username, navigate));
  };

  const handleBlur = (e) => {
    setUsername(e.target.value.trim());
  }

  const onChangeUsername = (e) => {
    const usernameValue = e.target.value;
    setUsername(usernameValue);
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="cus-login-pg login-form-center">
      {/* <Card > */}
      <div>
        <div className="cus-login-pg-sec">
          <div className="cus-login-pg-left-sec">
            <img alt="" className="cus-main-logo-img" src={hostName === 'portal.shipvisionai.com' ? MainLogoAi : MainLogoV} />
          </div>
          <div className="cus-login-pg-right-sec">
            <div className="cus-welcome-txt">
              Welcome to <span>{hostName === 'portal.shipvisionai.com' ? 'ShipVisionAI' : '3PLVision'}</span>
            </div>
            <div className="cus-login-form">
              <>
                <div className="cus-form-sec-heading">Forgot Password</div>
                <div className="cus-login-form-sec">
                  <Form className="loginFormSubmit">
                    <div className="form-group cus-rel">
                      <div className="cus-rel">
                        <Input
                          type="text"
                          className="form-controlzm"
                          autoComplete={"false"}
                          name="username"
                          value={username}
                          onChange={onChangeUsername}
                          validations={[required]}
                          placeholder="Enter your email address"
                          onBlur={handleBlur}
                        />
                        <img alt="" className="cus-sm-icon-cls" src={EmailIcon} />
                      </div>
                    </div>

                    <div className="form-group cus-action-group">

                      <div className="cus-action-btn-sec">
                        <button
                          className="btn cus-seconday-bg-btn"
                          disabled={!username || loading}
                          onClick={validateEmail}
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          Send Request
                        </button>
                      </div>

                      <div className="cus-action-btn-sec">
                        <button
                          className="btn cus-seconday-bg-btn"
                          disabled={loading}
                          onClick={() => navigate("/login")}
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          Cancel
                        </button>
                      </div>
                    </div>

                    <CheckButton style={{ display: "none" }} ref={checkBtn} />

                  </Form>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
