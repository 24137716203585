import {
  EXISTING_RATE_CARDS,
  EXISTING_RATE_CARDS_FAILED,
  GET_ACTIVE_SELLERS,
  GET_ACTIVE_SELLERS_FAILED,
  GET_SELLER_RATECARDS_ZONE_DATA,
  GET_SELLER_DETAILS,
  GET_ACTIVE_COMPANY,
  GET_BASE_RATECARDS,
  GET_ALL_COMPANY,
  GET_BASE_RATECARDS_ZONE_DATA,
  GET_SELLER_RATECARDS,
  GET_FILTER_LIST,
  GET_ALL_COMPANY_STATUS,
  COMPANY_SAVE_PAGINATION_DETAILS,
  BASE_RATE_CARD_SAVE_PAGINATION_DETAILS,
  SELLER_RATE_CARD_SAVE_PAGINATION_DETAILS,
  GET_SELLER_FILTER_LIST,
} from "../types";

const initialState = {
  activeSeller: [],
  activeSellerStatus: '',
  baseRateCardList: [],
  baseRateCardListStatus: '',
  filterCompanies: {},
  companyBackDetails: {},
  baseratecardBackDetails: {},
  sellerratecardBackDetails: {},
  filterSellers: {},
};



const RateCardReducer = (state = initialState, { type, payload } = []) => {
  switch (type) {
    case GET_BASE_RATECARDS:
      return {
        ...state,
        getAllRateCards: payload,

      };
    case GET_BASE_RATECARDS_ZONE_DATA:
      return {
        ...state,
        getRateCardZoneData: payload,

      };
    case GET_ALL_COMPANY:
      return {
        ...state,
        getAllCompany: payload,
        getAllCompanyStatus: 'SUCCESS',
      };
    case GET_ALL_COMPANY_STATUS:
      return {
        ...state,
        getAllCompany: payload,
        getAllCompanyStatus: 'PENDING',

      };
    case GET_ACTIVE_COMPANY:
      return {
        ...state,
        getActiveCompany: payload,

      };
    case GET_SELLER_DETAILS:
      return {
        ...state,
        getSellerData: payload,
      };
    case GET_SELLER_RATECARDS_ZONE_DATA:
      return {
        ...state,
        getSellerRateCardZoneData: payload,
      };
    case GET_SELLER_RATECARDS:
      return {
        ...state,
        getAllSellerCards: payload,
      };
    case GET_ACTIVE_SELLERS:
      return {
        ...state,
        activeSeller: payload,
        activeSellerStatus: 'success'
      };
    case GET_ACTIVE_SELLERS_FAILED:
      return {
        ...state,
        activeSeller: [],
        activeSellerStatus: 'failed'
      };
    case EXISTING_RATE_CARDS:
      return {
        ...state,
        baseRateCardList: payload,
        baseRateCardListStatus: 'success'
      };
    case EXISTING_RATE_CARDS_FAILED:
      return {
        ...state,
        baseRateCardList: [],
        baseRateCardListStatus: 'failed'
      };
    case GET_FILTER_LIST:
      return {
        ...state,
        filterCompanies: payload,
      };
    case COMPANY_SAVE_PAGINATION_DETAILS:
      return {
        ...state,
        companyBackDetails: payload
      }
    case BASE_RATE_CARD_SAVE_PAGINATION_DETAILS:
      return {
        ...state,
        baseratecardBackDetails: payload
      }
      case GET_SELLER_FILTER_LIST:
      return {
        ...state,
        filterSellers: payload,
      };
    case SELLER_RATE_CARD_SAVE_PAGINATION_DETAILS:
      return {
        ...state,
        sellerratecardBackDetails: payload
      }
    default:
      return state;
  }
}

export default RateCardReducer;


