import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { allowedDocExtensions, elementItemObj, elementObj, shippingStatusList } from "../../../constant";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import { convertFilesToBase64, findCount, findIndexWithChecked, handleInfoTooltip } from '../../../config/utils';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllConsignmentElementStatus, getConsignmentElementById } from '../../../store/actions/inventory.action';
import moment from 'moment';
import { Tooltip as WmsTooltip } from "react-tooltip";

/* istanbul ignore next */
const AddPaletteDialog = (props) => {
    const {
        showPaletteBoxObj,
        handleCancelPalette,
        setElements,
        elements,
        type,
        inBoundObject,
        dimensionUnitListing,
        weightUnitListing,
        consignmentTypeList,
        userDetail
    } = props;
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [consignmentDetailsPrevData, setConsignmentDetailsPrevData] = useState({});
    const dispatch = useDispatch();
    const { consignmentElementStatus } = useSelector((state) => state.InventoryManagement);
    const statusString = consignmentTypeList.filter((item) => item.id === Number(inBoundObject.consignmentTypeId))[0]?.name;

    useEffect(() => {
        if (!consignmentElementStatus?.length) {
            dispatch(fetchAllConsignmentElementStatus());
        }
    }, []);

    useEffect(() => {
        if (formData.id && ((showPaletteBoxObj.action === 'EDIT' && formData.id) || showPaletteBoxObj.action === 'VIEW')) {
            fetchConsignmentDetails(formData.id);
        }
    }, [formData.id]);

    const fetchConsignmentDetails = async (id) => {
        let fetchData = await Promise.resolve(dispatch(getConsignmentElementById(id)));
        if (fetchData.data.Status === 200) {
            let formDataObj = { ...formData };
            setConsignmentDetailsPrevData(JSON.parse(JSON.stringify(fetchData.data.Entity)));
            if (fetchData.data.Entity?.attachments.length) {
                formDataObj.attachments = formDataObj.attachments ? formDataObj.attachments : [];
                if (formDataObj.attachments) {
                    formDataObj.attachments.push(...fetchData.data.Entity?.attachments);
                }
                setFormData(formDataObj);
            }
        }
    }

    useEffect(() => {
        if (showPaletteBoxObj.action === 'ADD') {
            let elementData = JSON.parse(JSON.stringify(elementObj));
            let elementsItem = [...elements];
            if (type === 'Box') {
                if (Number(inBoundObject.consignmentTypeId) === 1) {
                    let findCheckdItem = elementsItem.findIndex((item) => item.isChecked);
                    elementData.name = `${elementsItem.length && elementsItem[findCheckdItem].name}_${showPaletteBoxObj.type}_${elements[findCheckdItem].consignmentElements.length + 1}`;
                } else if (Number(inBoundObject.consignmentTypeId) === 2) {
                    elementData.name = `${inBoundObject.sellerCode}_${showPaletteBoxObj.type}_${findCount(elements) + 1}`;
                }
            } else if (type === 'Item') {
                elementData.consignmentTypeId = 3;
                elementData = handleItemAddOnMount(elementData, elementsItem);
            } else {
                elementData.name = `${inBoundObject.sellerCode}_${showPaletteBoxObj.type}_${elements.length + 1}`;
            }
            setFormData(elementData)
        } else {
            handleEditModal();
        }
    }, [showPaletteBoxObj]);

    const handleEditModal = () => {
        let elementData = JSON.parse(JSON.stringify(elements));
        if (type === 'Pallet') {
            elementData[showPaletteBoxObj.paletteIndex].quantity = elementData[showPaletteBoxObj.paletteIndex].consignmentElements.length;
            setFormData(elementData[showPaletteBoxObj.paletteIndex]);
        } else if (type === 'Box') {
            elementData[showPaletteBoxObj.paletteIndex].consignmentElements[showPaletteBoxObj.boxIndex].quantity = elementData[showPaletteBoxObj.paletteIndex].consignmentElements[showPaletteBoxObj.boxIndex].consignmentElements.length;
            setFormData(elementData[showPaletteBoxObj.paletteIndex].consignmentElements[showPaletteBoxObj.boxIndex]);
        } else if (type === 'Item') {
            setFormData(elementData[showPaletteBoxObj.paletteIndex].consignmentElements[showPaletteBoxObj.boxIndex]?.consignmentElements[showPaletteBoxObj.itemIndex]);
        }
    }

    const handleItemAddOnMount = (elementData, elementsItem) => {
        if (Number(inBoundObject.consignmentTypeId) === 1) {
            let findCheckdItem = elementsItem.findIndex((item) => item.isChecked);
            if (findCheckdItem === -1) {
                findCheckdItem = findIndexWithChecked(elementsItem);
            }
            let findCheckdBox = elementsItem[findCheckdItem].consignmentElements.findIndex((item) => item.isChecked);
            elementData.name = `${elementsItem[findCheckdItem].consignmentElements[findCheckdBox].name}_${showPaletteBoxObj.type}_${elementsItem[findCheckdItem].consignmentElements[findCheckdBox].consignmentElements.length + 1}`;
        } else if (Number(inBoundObject.consignmentTypeId) === 2) {
            if (!elements.length) {
                let convertArray = elementData;
                let findCheckdBox = convertArray.consignmentElements.findIndex((item) => item.isChecked);
                elementData.name = `${convertArray[0].consignmentElements[findCheckdBox].name}_${showPaletteBoxObj.type}_${convertArray[0].consignmentElements[findCheckdBox].consignmentElements.length + 1}`;
            } else {
                let findCheckdBox = elements[0].consignmentElements.findIndex((item) => item.isChecked);
                elementData.name = `${elements[0].consignmentElements[findCheckdBox].name}_${showPaletteBoxObj.type}_${elements[0].consignmentElements[findCheckdBox].consignmentElements.length + 1}`;
            }
        } else if (Number(inBoundObject.consignmentTypeId) === 3) {
            if (!elements.length) {
                let rr = [JSON.parse(JSON.stringify(elementItemObj))];
                elementData.name = `${inBoundObject.sellerCode}_${showPaletteBoxObj.type}_${rr[0].consignmentElements[0].consignmentElements.length + 1}`;
            } else {
                elementData.name = `${inBoundObject.sellerCode}_${showPaletteBoxObj.type}_${elementsItem[0].consignmentElements[0].consignmentElements.length + 1}`;
            }
        }
        return elementData;
    }

    const handleFormValue = (e) => {
        const { name, value } = e.target;
        if (name === 'quantity') {
            setFormData({ ...formData, [name]: value ? Number(value) : '' });
        } else if (name === 'shippingStatusId') {
            setFormData({ ...formData, [name]: value ? Number(value) : '' });
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' })
    };

    const handleBlurInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
    }

    const checkBoxDuplicate = () => {
        let dataElem = JSON.parse(JSON.stringify(elements));
        if (showPaletteBoxObj.action === 'EDIT') {
            dataElem[showPaletteBoxObj.paletteIndex].consignmentElements.splice([showPaletteBoxObj.boxIndex], 1);
        }
        let filteredBoxes = [];
        dataElem.forEach((pallet) => {
            pallet.consignmentElements = pallet.consignmentElements.filter((subBrand) => {
                if (subBrand.name.toLowerCase() === formData.name.toLowerCase()) {
                    filteredBoxes.push(subBrand)
                }
            })
        });
        if (filteredBoxes.length) {
            toast.error('You already have box with same name.');
            return false;
        }
        return true;
    }

    const checkItemDuplicate = () => {
        let dataElem = JSON.parse(JSON.stringify(elements));
        if (showPaletteBoxObj.action === 'EDIT') {
            dataElem[showPaletteBoxObj.paletteIndex].consignmentElements[showPaletteBoxObj.boxIndex].consignmentElements.splice([showPaletteBoxObj.itemIndex], 1);
        }
        let filteredItems = [];
        dataElem.forEach(function (brand) {
            brand.consignmentElements.forEach((items) => {
                items.consignmentElements = items.consignmentElements.filter((subBrand) => {
                    if (subBrand.name.toLowerCase() === formData.name.toLowerCase()) {
                        filteredItems.push(subBrand);
                    }
                })
            });
        });
        if (filteredItems.length) {
            toast.error('You already have item with same name.');
            return false;
        }
        return true;
    }

    const handleDuplicateNames = () => {
        let data = [...elements];
        if (showPaletteBoxObj.type === 'Pallet') {
            let findDuplicate = data.filter((item, index) => showPaletteBoxObj.paletteIndex !== index && item.name.toLowerCase() === formData.name.toLowerCase());
            if (findDuplicate.length) {
                toast.error('You already have pallet with same name.');
                return false;
            }
        } else if (showPaletteBoxObj.type === 'Box') {
            return checkBoxDuplicate();
        } else if (showPaletteBoxObj.type === 'Item') {
            return checkItemDuplicate();
        }
        return true;

    }

    const handleValidations = () => {
        let error = {};
        let isFormValid = true;
        if (!formData.name) {
            error.name = 'This is required.'
            isFormValid = false;
        }
        if (showPaletteBoxObj.type === 'Item') {
            if (!Number(formData.quantity)) {
                error.quantity = 'This is required.'
                isFormValid = false;
            }
            if (!formData.productSKU) {
                error.productSKU = 'This is required.'
                isFormValid = false;
            }
        }

        if (formData.id) {
            if (Number(formData.consignmentStatusId < consignmentDetailsPrevData.consignmentStatusId)) {
                isFormValid = false;
                toast.error('Status cannot be change in backward directions');
            }
        }

        if (!formData.id && Number(formData.consignmentStatusId) > 2) {
            isFormValid = false;
            toast.error(`${type} status cannot be set to Received at Warehouse directly. Please select New or In Transit status.`);
        }

        setErrors(error);
        return isFormValid;
    }

    const handleUpdatePalletBox = (newValue, OldValue, data) => {
        let formDataObj = { ...formData };
        let getDiff = newValue - OldValue;
        if (newValue > OldValue) {
            let createBoxData = new Array(Number(getDiff)).fill().map((e, i) => {
                return { ...elementObj, relationId: uuidv4(), consignmentTypeId: 2, name: `${formData.name}_Box_${formDataObj.consignmentElements.length + (i + 1)}` }
            });
            formDataObj.consignmentElements.push(...createBoxData);
        } else if (newValue < OldValue) {
            formDataObj.consignmentElements = formDataObj.consignmentElements.slice(0, -(OldValue - newValue));
        }
        formDataObj.quantity = formDataObj.consignmentElements.length;
        data[showPaletteBoxObj.paletteIndex] = formDataObj;
        setElements(data);
    };

    const handleUpdateBox = (newValue, OldValue, data) => {
        let formDataObj = { ...formData };
        let getDiff = newValue - OldValue;
        let elem = JSON.parse(JSON.stringify(elementObj));
        if (newValue > OldValue) {
            let insertItems = new Array(Number(getDiff)).fill().map((e, i) => {
                return { ...elem, relationId: uuidv4(), consignmentTypeId: 3, name: `${formData.name}_Item_${formDataObj.consignmentElements.length + (i + 1)}` }
            });

            formDataObj.consignmentElements.push(...insertItems);
        } else if (newValue < OldValue) {
            formDataObj.consignmentElements = formDataObj.consignmentElements.slice(0, -(OldValue - newValue));
        }
        formDataObj.quantity = formDataObj.consignmentElements.length;
        data[showPaletteBoxObj.paletteIndex].consignmentElements[showPaletteBoxObj.boxIndex] = formDataObj;
        setElements(data);
    };

    const handleEditConsole = () => {
        let data = JSON.parse(JSON.stringify(elements));
        let formDataObj = { ...formData };
        formDataObj.attachments = formDataObj?.attachments?.filter((item) => !item.id);
        if (showPaletteBoxObj.type === 'Pallet') {
            if (formData.quantity !== data[showPaletteBoxObj.paletteIndex].quantity) {
                handleUpdatePalletBox(Number(formData.quantity), Number(data[showPaletteBoxObj.paletteIndex].quantity), data);
            } else {
                data[showPaletteBoxObj.paletteIndex] = formDataObj;
            }
        } else if (showPaletteBoxObj.type === 'Box') {
            handleUpdateBox(Number(formData.quantity), Number(data[showPaletteBoxObj.paletteIndex].consignmentElements[showPaletteBoxObj.boxIndex].quantity), data);
        } else if (showPaletteBoxObj.type === 'Item') {
            data[showPaletteBoxObj.paletteIndex].consignmentElements[showPaletteBoxObj.boxIndex].consignmentElements[showPaletteBoxObj.itemIndex] = formDataObj;
        }
        setElements(data);
    }

    const handleAddElement = () => {
        if (handleValidations() && handleDuplicateNames()) {
            if (showPaletteBoxObj.action === 'ADD') {
                if (showPaletteBoxObj.type === 'Pallet') {
                    handleAddPalette();
                } else if (showPaletteBoxObj.type === 'Box') {
                    handleBoxPalette();
                } else if (showPaletteBoxObj.type === 'Item') {
                    handleItemPalette();
                }
            } else if (showPaletteBoxObj.action === 'EDIT') {
                handleEditConsole()
            }
            handleCancelPalette();
        }
    }

    const handleItemPalette = () => {
        let elementsItem = [JSON.parse(JSON.stringify(elementItemObj))];
        let arr = { ...formData };
        if (Number(inBoundObject.consignmentTypeId) === 3) {
            arr.consignmentTypeId = 3;
            if (!elements.length) {
                elementsItem[0].consignmentElements[0].consignmentElements.push(arr);
            } else {
                elementsItem = [...elements];
                elementsItem[0].consignmentElements[0].consignmentElements.push(arr);
            }
        } else if (Number(inBoundObject.consignmentTypeId) === 2) {
            elementsItem = JSON.parse(JSON.stringify(elements));
            let findCheckdBox = elementsItem[0].consignmentElements.findIndex((item) => item.isChecked);
            elementsItem[0].consignmentElements[findCheckdBox].consignmentElements.push(arr);
        } else {
            elementsItem = JSON.parse(JSON.stringify(elements));
            let findCheckdItem = elementsItem.findIndex((item) => item.isChecked);
            if (findCheckdItem === -1) {
                findCheckdItem = findIndexWithChecked(elementsItem);
            }
            let findCheckdBox = elementsItem[findCheckdItem].consignmentElements.findIndex((item) => item.isChecked);
            elementsItem[findCheckdItem].consignmentElements[findCheckdBox].consignmentElements.push(arr);
        }
        setElements(elementsItem);
    }

    const handleBoxPalette = () => {
        let elementsItem = [...elements];
        let elem = JSON.parse(JSON.stringify(elementObj));
        let insertItems = new Array(Number(formData.quantity)).fill().map((e, i) => {
            return { ...elem, relationId: uuidv4(), consignmentTypeId: 3, name: `${formData.name}_Item_${i + 1}` }
        });

        let arr = [formData];
        if (Number(inBoundObject.consignmentTypeId) === 2) {
            formData.consignmentElements = insertItems;
            formData.consignmentTypeId = 2;
            let elementData = JSON.parse(JSON.stringify(elementObj));
            if (!elementsItem.length) {
                elementsItem.push(elementData);
                elementsItem[0].consignmentElements.push(formData);
            } else {
                elementsItem[0].consignmentElements.push(formData);
            }
        } else {
            formData.consignmentElements = insertItems;
            let findCheckdItem = elementsItem.findIndex((item) => item.isChecked);
            elementsItem[findCheckdItem].consignmentElements.push(arr[0]);
        }
        setElements(elementsItem);
    }

    const handleAddPalette = async () => {
        let cloneElementObj = JSON.parse(JSON.stringify(elementObj));
        cloneElementObj.consignmentTypeId = 2;
        let arr = [formData];
        let createBoxData = new Array(Number(formData.quantity)).fill().map((e, i) => {
            return { ...elementObj, relationId: uuidv4(), consignmentTypeId: 2, name: `${formData.name}_Box_${i + 1}` }
        });
        formData.consignmentElements = Object.hasOwnProperty('consignmentElements') ? formData.consignmentElements.push(createBoxData) : createBoxData;
        setElements([...elements, ...arr]);
    }

    const handleAttachmentLogic = (base64Array) => {
        let forDataArr = { ...formData };
        if (forDataArr.attachments) {
            forDataArr.attachments.push(...base64Array);
        } else {
            forDataArr.attachments = [];
            forDataArr.attachments.push(...base64Array);
        }
        setFormData(forDataArr);
    }

    const handleDocUpload = (e) => {
        let files = [...e.target.files];
        try {
            if (files.length > 0 && files.length <= 5) {
                let filterFiles = files.filter((file, index) => {
                    const extension = file.name.split(".").slice(-1).pop();
                    const fileSize = Math.round(file.size / 1024);

                    if (!allowedDocExtensions.includes(extension ? extension.toLowerCase() : "")) {
                        files.splice(index, 1);
                        toast.error("Unsupported File format", 1000);
                        return false;
                    } else if (fileSize > 1024) {
                        files.splice(index, 1);
                        e.target.value = "";
                        toast.info("Each picture size should be less than 1MB", 2000);
                        return false;
                    }
                    return file;
                });
                convertFilesToBase64(filterFiles).then(base64Array => {
                    handleAttachmentLogic(base64Array);
                }).catch(error => {
                    console.error(error);
                });
                /* istanbul ignore next */
            } else if (files.length > 5) {
                toast.info("Only 5 images can be upload at one time", 1000);
                e.target.value = "";
            } else {
                e.target.value = "";
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleDeleteImage = (index) => {
        let data = { ...formData };
        if (data.attachments[index].id > 0) {
            data.attachments[index].isDeleted = true;
        } else {
            data.attachments.splice(index, 1);
        }
        setFormData(data);
    };

    return (
        <Modal
            {...props}
            size="xl"
            className="topUpModal"
            centered
            backdrop="static"
            show={showPaletteBoxObj?.show}
            onHide={handleCancelPalette}
        >
            <div className="top_modal_header_container no_mrg">
                <span className="">Add {showPaletteBoxObj.type}</span>
                <div className="modalDeleteWrapper">
                    <button
                        type="button"
                        onClick={() => handleCancelPalette()}
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>
            </div>

            <div className="modal_container_rate_card seller-Modal">
                <div className='row'>
                    {showPaletteBoxObj.type !== 'Item' && (
                        <div className='col-4 mb-3'>
                            <label>Reference Id</label>
                            <input
                                className={`form-control externalReference`}
                                type={'text'}
                                name="externalReference"
                                value={formData.externalReference}
                                onChange={handleFormValue}
                                onBlur={handleBlurInput}
                                disabled={(showPaletteBoxObj.action === 'VIEW')}
                            />
                        </div>
                    )}
                    <div className='col-4 mb-3'>
                        <label>{type} Name</label>
                        <input
                            className={`form-control`}
                            type={'text'}
                            name="name"
                            value={formData.name}
                            onChange={handleFormValue}
                            onBlur={handleBlurInput}
                            disabled={(showPaletteBoxObj.action === 'VIEW')}
                        />
                        <div className="error">{errors.name}</div>
                    </div>

                    {showPaletteBoxObj.type === 'Item' && (
                        <div className='col-4 mb-3'>
                            <label>Description</label>
                            <input
                                className='form-control'
                                type={'text'}
                                name="description"
                                value={formData.description}
                                onChange={handleFormValue}
                                onBlur={handleBlurInput}
                                disabled={(showPaletteBoxObj.action === 'VIEW')}
                            />
                            <div className='error'>{errors.description}</div>
                        </div>
                    )}
                    <div className='col-4 mb-3'>
                        <>
                            <label>{type === 'Pallet' && <>Number of Boxes in this Pallet</>} </label>
                            <label>{type === 'Box' && <>Number of Different SKUs in this Box</>} </label>
                            <label>{type === 'Item' && <>Total quantity of this SKU</>} </label>
                        </>
                        <i id={`${type}-console`} className="fa fa-info-circle ml-2" aria-hidden="true"></i>
                        <WmsTooltip
                            anchorId={`${type}-console`}
                            data-tooltip-html={true}
                            content={() => handleInfoTooltip(type, formData.quantity)}
                            place="right"
                            className="tooltipWidth palletInfo"
                        />

                        <input
                            className={`form-control numberInput quantity`}
                            type={'number'}
                            name="quantity"
                            min={0}
                            disabled={showPaletteBoxObj.action === 'VIEW'}
                            value={formData.quantity || ''}
                            onChange={handleFormValue}
                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                            onBlur={handleBlurInput}
                        />
                        <div className="error">{errors.quantity}</div>
                    </div>

                    <div className='col-4 mb-3'>
                        <label>SKU</label>
                        <input
                            className={`form-control`}
                            type={'text'}
                            name="productSKU"
                            value={formData.productSKU}
                            onChange={handleFormValue}
                            onBlur={handleBlurInput}
                            disabled={(showPaletteBoxObj.action === 'VIEW')}
                        />
                        <div className="error">{errors.productSKU}</div>
                    </div>
                    <div className='col-4 mb-3'>
                        <label>Connection</label>
                        <select
                            className={`form-control countrySelect`}
                            onChange={handleFormValue}
                            value={formData.shippingStatusId}
                            name={'shippingStatusId'}
                            disabled={(showPaletteBoxObj.action === 'VIEW')}
                        >
                            <option value={0}>Select</option>
                            {shippingStatusList.map((list) => (
                                <option key={list.id} value={list.value}>{list.name}</option>
                            ))}
                        </select>
                    </div>
                    {statusString === type && (
                        <>
                            <div className='col-4 mb-3'>
                                <label>Tracking Number</label>
                                <input
                                    className={`form-control`}
                                    type={'text'}
                                    name="trackingNumber"
                                    value={formData.trackingNumber}
                                    onChange={handleFormValue}
                                    onBlur={handleBlurInput}
                                    disabled={(showPaletteBoxObj.action === 'VIEW')}
                                />
                            </div>
                            <div className='col-4 mb-3'>
                                <label>Status</label>
                                <select
                                    className={`form-control carrierSelect`}
                                    onChange={handleFormValue}
                                    value={formData.consignmentStatusId}
                                    name={'consignmentStatusId'}
                                    disabled={(showPaletteBoxObj.action === 'VIEW')}>
                                    {consignmentElementStatus?.map((item) => (
                                        <option
                                            value={item.id}
                                            key={item.id}
                                            disabled={item.id === 3 && !userDetail?.Value?.isSuperAdmin && !userDetail?.Value?.roleName == 'Warehouse Worker' && !userDetail?.Value?.roleName == 'Warehouse Manager'}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}

                    <div className='col-4 mb-3'>
                        <label>Attachments</label>
                        <div className="fileUpload inventory">
                            <button
                                type="button"
                                className="btn cus-seconday-bg-btn"
                                disabled={(showPaletteBoxObj.action === 'VIEW')}
                            >
                                Upload documents
                            </button>
                            <input
                                className="hiddenFileField"
                                onChange={handleDocUpload}
                                disabled={(showPaletteBoxObj.action === 'VIEW')}
                                type="file"
                                id="customFile"
                                multiple
                            />
                        </div>
                        <div>
                            {formData?.attachments && formData?.attachments.length > 0 &&
                                formData.attachments.map((item, index) => (
                                    <>
                                        {!item?.isDeleted &&
                                            <div
                                                className="selectedFile"
                                                key={item?.id}
                                            >
                                                <div className="fileName">
                                                    {item.fileUrl ? (
                                                        <a href={item.fileUrl} target='_blank' rel="noreferrer">{item.name}</a>
                                                    ) : (
                                                        <>
                                                            {item.name}
                                                        </>
                                                    )}
                                                </div>
                                                <button
                                                    className='deleteImg'
                                                    onClick={() => handleDeleteImage(index)}
                                                    disabled={(showPaletteBoxObj.action === 'VIEW')}
                                                >
                                                    <i
                                                        className="fa fa-times"
                                                        aria-hidden="true"
                                                    ></i>
                                                </button>
                                            </div>
                                        }
                                    </>
                                ))}
                        </div>

                    </div>
                </div>

                {showPaletteBoxObj.type === 'Item' && (
                    <div className='row mb-3'>
                        <>
                            <div className='col-4 col'>
                                <label>Item Expiration</label>
                                <div className='d_flex'>
                                    <DatePicker
                                        className="form-control itemExpiryDate"
                                        showIcon
                                        dateFormat="MM-dd-yyyy"
                                        selected={formData?.expirationDate ? new Date(formData?.expirationDate) : null}
                                        name="expirationDate"
                                        onChange={(date) => handleFormValue({ target: { value: moment(date).format("MM-DD-YYYY"), name: 'expirationDate' } })}
                                        disabled={(showPaletteBoxObj.action === 'VIEW')}
                                    />
                                </div>
                            </div>
                        </>
                    </div>
                )}

                <div className='row mb-3 dimesntionsBlock'>
                    <div className='col-4'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-9 col-sm-12'>
                                <label>Weight</label>
                                <input
                                    className={`form-control numInput`}
                                    type={'number'}
                                    name="weight"
                                    min={0}
                                    value={formData.weight}
                                    onChange={handleFormValue}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    onBlur={handleBlurInput}
                                    disabled={(showPaletteBoxObj.action === 'VIEW')}
                                />
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <label>Unit</label>
                                <select
                                    className={`form-control countrySelect`}
                                    onChange={handleFormValue}
                                    value={formData.weightUnitId}
                                    name={'weightUnitId'}
                                    disabled={(showPaletteBoxObj.action === 'VIEW')}
                                >
                                    {weightUnitListing.length > 0 && weightUnitListing.map((weight) => (
                                        <option key={weight.id} value={weight.id}>{weight.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='col-8'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                <label>Length</label>
                                <input
                                    className={`form-control numInput`}
                                    type={'number'}
                                    name="length"
                                    min={0}
                                    value={formData.length}
                                    onChange={handleFormValue}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    onBlur={handleBlurInput}
                                    disabled={(showPaletteBoxObj.action === 'VIEW')}
                                />
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                <label>Width</label>
                                <input
                                    className={`form-control numInput`}
                                    type={'number'}
                                    name="width"
                                    min={0}
                                    value={formData.width}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    onChange={handleFormValue}
                                    onBlur={handleBlurInput}
                                    disabled={(showPaletteBoxObj.action === 'VIEW')}
                                />
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                <label>Height</label>
                                <input
                                    className={`form-control numInput`}
                                    type={'number'}
                                    min={0}
                                    name="height"
                                    value={formData.height}
                                    onChange={handleFormValue}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    onBlur={handleBlurInput}
                                    disabled={(showPaletteBoxObj.action === 'VIEW')}
                                />
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-12'>
                                <label>Dimension Unit</label>
                                <select
                                    className={`form-control countrySelect`}
                                    onChange={handleFormValue}
                                    value={formData.dimensionUnitId}
                                    name={'dimensionUnitId'}
                                    disabled={(showPaletteBoxObj.action === 'VIEW')}
                                >
                                    {dimensionUnitListing.length > 0 && dimensionUnitListing.map((dimension) => (
                                        <option key={dimension.id} value={dimension.id}>{dimension.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12 mb-3'>
                        <label>Comment</label>
                        <textarea
                            min='3'
                            className='form-control textArea'
                            type={'text'}
                            name="comments"
                            value={formData.comments}
                            onChange={handleFormValue}
                            onBlur={handleBlurInput}
                            disabled={(showPaletteBoxObj.action === 'VIEW')}
                        />
                    </div>
                </div>
                <div className='paletteActions mb-3'>
                    <button
                        className="btn cus-primary-transparent-btn top-btn"
                        onClick={handleCancelPalette}
                    >
                        Cancel
                    </button>
                    {showPaletteBoxObj.action === 'VIEW' ? '' :
                        <button
                            className="btn cus-seconday-bg-btn"
                            onClick={handleAddElement}
                        >
                            {showPaletteBoxObj.action === 'EDIT' ? 'Update' : 'Add'}
                        </button>}

                </div>
            </div>
        </Modal>
    );
}

export default AddPaletteDialog;