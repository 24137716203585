import React from "react";
import "./topUpModal.css";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import moment from "moment";
import Select from "react-select";
import {
  getLoginUserDetailsonFund,
  getSellers,
  getSellerWalletDetails,
  UpdateTopUpFundDetails,
} from "../../../store/actions/fundManagement";
import {
  GET_SELLERS,
  GET_WALLET_BALANCE,
} from "../../../store/types";
import { toast } from "react-toastify";

function TopUpMOdal(props) {
  const { showModal = true } = props;
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [selectedsellerData, setselectedSellerData] = useState();
  const [amount, setAmount] = useState(null);
  const [sellerList, setSellerList] = useState();
  const [topUpId, settopUpId] = useState(null);
  const createdBy = useSelector((state) => state?.auth?.user);
  const sellerWalletBalance = useSelector(
    (state) => state?.FundManagementReducer?.getSellerWalletBalance?.EntityList
  );
  const getSellersValue = useSelector(
    (state) => state?.FundManagementReducer?.getSellersDetail?.EntityList
  );
  const [formData, setFormData] = useState({
    createdBy: createdBy?.Value?.employeeId,
    companyId: createdBy?.Value?.companyId,
    sellerId: createdBy?.Value?.sellerId,
    email: createdBy?.Value?.emailId,
    SellerName: {},
    isActive: true,
    id: selectedsellerData && selectedsellerData?.id,
    amount: 0,
    transactionType: 6,
    topupCompayId: null,
    shouldSendEmail: true
  });

  useEffect(() => {
    setselectedSellerData('');
  }, [amount]);

  const TopUpForm = (item, name, sellerdetail) => {
    if (sellerdetail) {
      settopUpId(item?.Id);
    }
    setFormData({ ...formData, [name]: item });
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "SellerName") {
      dispatch(getSellers(item?.Id));
      setFormData({ ...formData, topupSellerId: null, [name]: item, availableAmount: null });
    }
    if (name === "topupSellerId") {
      dispatch(
        getSellerWalletDetails({
          id: 0,
          isAdmin: createdBy?.Value?.isAdmin,
          topupCompanyId: topUpId,
          topupSellerId: item?.value,
        })
      );
    }
  };

  useEffect(() => {
    if (getSellersValue && getSellersValue.length) {
      let data = getSellersValue.map((item) => {
        return {
          label: item.sellerName,
          value: item.sellerId,
        };
      });
      setSellerList(data);
    } else {
      setSellerList([]);
    }
  }, [getSellersValue]);

  const saveTopUpData = () => {
    if (
      formData?.SellerName?.name &&
      formData?.amount > 0 &&
      formData?.topupSellerId
    ) {
      /* istanbul ignore next */
      dispatch(UpdateTopUpFundDetails(
        {
          ...formData,
          topupCompayId: topUpId,
          topupSellerId: formData?.topupSellerId?.value,
          role: createdBy?.Value?.role,
          companySellerId: createdBy?.Value?.companySellerId
        },
        sellerWalletBalance,
        setAmount,
        createdBy?.Value?.isAdmin,
        props.onHide
      )
      );
      /* istanbul ignore next */
      toast.success("Recharged Succesfully");
    } else {
      /* istanbul ignore next */
      if (
        !formData?.SellerName?.name &&
        !formData?.amount >= 0 &&
        !formData?.topupSellerId
      ) {
        setErrors({
          SellerName: "This is a mandatory field",
          amount: "Amount should not be empty or 0",
          topupSellerId: "This is a mandatory field",
        });
      } else if (!formData?.SellerName?.name == {} && formData?.amount >= 0) {
        /* istanbul ignore next */
        setErrors({
          SellerName: "This is a mandatory field",
        });
      } else if (!formData?.amount && !formData?.topupSellerId?.label) {
        /* istanbul ignore next */
        setErrors({
          amount: "Amount should not be empty or 0",
          topupSellerId: "This is a mandatory field",
        });
      } else if (!formData?.amount) {
        /* istanbul ignore next */
        setErrors({
          amount: "Amount should not be empty or 0",
        });
      } else if (!formData?.topupSellerId?.label) {
        /* istanbul ignore next */
        setErrors({
          topupSellerId: "This is a mandatory field",
        });
      }
    }
  };


  const handleCancel = () => {
    dispatch({
      type: GET_WALLET_BALANCE,
      payload: [],
    });
    dispatch({
      type: GET_SELLERS,
      payload: [],
    });
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="topUpModal"
      centered
      backdrop="static"
      ref={modalRef}
      show={showModal}
    >
      <div className="top_modal_header_container">
        <span className="">Top Up</span>
        <div className="modalDeleteWrapper">
          <button
            type="button"
            onClick={() => handleCancel()}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="modal_container_rate_card">
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label">Company Name:</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={props?.activeSellerList}
                onChange={(value) => TopUpForm(value, "SellerName", "seller")}
                className="activeSellerListInput"

              />
            </div>
            <p className="error dialogError"> {errors?.SellerName}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label">Seller Name:</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={sellerList}
                onChange={(value) => TopUpForm(value, "topupSellerId")}
                className="activeSellerListInput"
                isDisabled={!formData?.SellerName?.name}
                value={formData?.topupSellerId}
                classNamePrefix="list-value"

              />
            </div>
            <p className="error dialogError">
              {" "}
              {errors?.topupSellerId}
            </p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Available Balance ($):</span>
          </div>
          <div className="col-8">
            <input
              onChange={(e) => TopUpForm(e, "AvailableBalance")}
              disabled
              value={
                sellerWalletBalance?.length &&
                sellerWalletBalance?.[0]?.availableAmount
              }
              className="form-control availableBalanceInput"
              type="text"
            />
            <p className="error dialogError">{errors.Name}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Transaction Date:</span>
          </div>
          <div className="col-8">
            <div className="form-control availableBalanceInput">
              {" "}
              {moment(new Date()).format("MM-DD-YYYY")}
            </div>
            <p className="error dialogError">{errors.Name}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Enter The Amount ($):</span>
          </div>
          <div className="col-8">
            <input
              onChange={(e) => TopUpForm(e.target.value, "amount")}
              className="form-control amountInput"
              min={1}
              onKeyDown={(event) => {
                if (event.key === "-") {
                  event.preventDefault(); // Prevents typing the minus sign
                }
              }}
              type="number"
            />
            <p className="error dialogError">{errors.amount}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Description:</span>
          </div>
          <div className="col-8">
            <textarea
              /* istanbul ignore next */
              maxLength={100}
              onChange={(e) => TopUpForm(e.target.value, "description")}
              className="form-control amountInput text-area"
              type="text"
            />
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel"></div>
          <div className="col-8">
            <input type="checkbox" checked={formData.shouldSendEmail} onChange={(e) => setFormData({ ...formData, shouldSendEmail: e.target.checked })} /> Send Notification Email
          </div>
        </div>
        <Row className="modaL_footer">
          <div
            className="col-12 noPadding"
            style={{ textAlign: "right" }}
            xs={12}
          >
            <Button
              className="btn cus-seconday-bg-btn"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              id="saveTopUpBtn"
              className="btn cus-seconday-bg-btn marginLeft5"
              onClick={(e) => {
                saveTopUpData();
              }}
            >
              Add
            </Button>
          </div>
        </Row>
      </div>
    </Modal>
  );
}

export default TopUpMOdal;
