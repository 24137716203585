import React from 'react';
import ImageSlider from '../incoming/ImageSlider';
import noImage from "../../assets/No-Image-Placeholder.png";
import InLargeImageSlider from '../modal/InlargeImageSlider';

const OutboundImages = (props) => {
    const { id, formData, isEditable, showInlargeModal, setshowInlargeModal, setFormData, file, formDataCopy, setImageActions, imageActions } = props;

    const handleMarkPrimary = (e, item) => {
        let formDataCopyObj = JSON.parse(JSON.stringify(formData));
        let updateImage = formDataCopyObj.images.map((box) => {
            return {
                ...box,
                isPrimary: box.id === item.id ? true : false,
            };
        });
        formDataCopyObj.images = updateImage;
        formDataCopyObj.imageActions = updateImage;
        formDataCopyObj.fileActions = file;
        setFormData(formDataCopyObj);
    };

    const handleSliderDeleteImage = (e, item) => {
        let formDataObj = JSON.parse(JSON.stringify(formData));
        let updateImage = formDataObj.images.filter(
            (box) => box.id !== item.id
        );
        formDataObj.images = updateImage;

        let cloneFormData = JSON.parse(JSON.stringify(formDataCopy));
        let deletedImages = [];
        cloneFormData?.images.map((box) => {
            if (box.id === item.id) {
                deletedImages.push({
                    ...box,
                    isDeleted: true,
                });
            }
        });
        setImageActions([...imageActions, ...deletedImages]);
        setFormData(formDataObj);
    };
    return (
        <>
            {Number(id) > 0 && (
                <div className="col-3 inlineFlex">
                    <div
                        className="main_content_table addressDetailWrapper imageBlock"
                        style={{ textAlign: "center" }}
                    >
                        {formData?.images?.length > 0 ? (
                            <ImageSlider
                                isView={!isEditable}
                                images={formData.images}
                                handleMarkPrimary={handleMarkPrimary}
                                handleDeleteImage={handleSliderDeleteImage}
                                isUserAdmin={true}
                                showLargeSliderModal={showInlargeModal}
                                setShowLargeSliderModal={setshowInlargeModal}
                            />
                        ) : (
                            <img src={noImage} alt="" className="inBoundDefaultImage" />
                        )}
                    </div>
                </div>
            )}

            {formData?.images?.length > 0 && (
                <InLargeImageSlider
                    popupTitle={"Product"}
                    view={true}
                    images={formData?.images}
                    showModal={showInlargeModal}
                    setShowLargeSliderModal={setshowInlargeModal}
                />

            )}
        </>
    );
}

export default OutboundImages;