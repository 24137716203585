import { clearLocalStorage, getLocalStorageItem } from "../services/StorageService";

export const logout = () => {
    clearLocalStorage();
    window.location.href = '/';
}

export const getUserToken = () => {
    let token = JSON.parse(getLocalStorageItem('userDetails') || '{}');
    if (token?.Value?.Token) {
        return token.Value.Token;
    }
}
