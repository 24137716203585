import React, { useEffect, useState } from 'react';
import { Button } from "bootstrap-4-react/lib/components";
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { getAllcarriers } from '../../store/actions/batch.action';
import { getSelectList } from '../../config/utils';
import { getAllCarrierServicesList } from '../../store/actions';
import _ from 'lodash';

/* istanbul ignore next */
const MarginCarrierFilter = (props) => {
    const dispatch = useDispatch();
    const { showFilter, error, handleFilter, handleResetFilter, payloadData, setPayloadData, handleFilterValue, isMarginFilter } = props;
    const carrierListData = useSelector((state) => state?.batchReducer?.carrierList);
    const carrierServices = useSelector((state) => state?.shipment?.carrierServices);
    const [servicesList, setServicesList] = useState([]);
    const [carrierListOptions, setCarrierListOptions] = useState([]);
    const handleFilterChange = (e) => {
        const { value, name } = e.target;
        setPayloadData({ ...payloadData, [name]: Number(value) });
    }

    useEffect(() => {
        if (!carrierListData?.length) {
            dispatch(getAllcarriers());
        } else {
            setCarrierListOptions(getSelectList(carrierListData, 'id', 'carrierCompanyName'));
        }
    }, [carrierListData]);

    useEffect(() => {
        if (!carrierServices || !carrierServices.length) {
            dispatch(getAllCarrierServicesList());
        } else {
            setServicesList(getSelectList(carrierServices, 'id', 'serviceName'));
        }
    }, [carrierServices]);

    return (
        <div className={showFilter ? 'filterContainer show historyFilter history-margin' : 'filterContainer historyFilter'}>
            <div className='row mb10'>
                <div className='col-4'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Carrier Name</label>
                        </div>
                        <div className='col-12 fieldAndLabel'>
                            <div className={"select_react carrierListSelect"}>
                                <Select
                                    isMulti
                                    options={carrierListOptions}
                                    closeMenuOnSelect={false}
                                    value={payloadData?.filterCarrierCompanyIds}
                                    className="activeSellerListInput"
                                    name="filterCarrierCompanyIds"
                                    placeholder='Select Carriers'
                                    onChange={(e) => handleFilterValue(e, 'filterCarrierCompanyIds')}
                                >
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-4'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Services</label>
                        </div>
                        <div className='col-12 fieldAndLabel'>
                            <div className={"select_react carrierListSelect"}>
                                <Select
                                    isMulti
                                    value={payloadData?.filterCarrierServiceIds}
                                    className="activeSellerListInput"
                                    options={servicesList}
                                    placeholder='Select Services'
                                    onChange={(e) =>
                                        handleFilterValue(e, 'filterCarrierServiceIds')
                                    }
                                >
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                {isMarginFilter &&
                    <div className='col-4'>
                        <div className='row'>
                            <div className='col-12'>
                                <label className='labelHead'>Margin($)</label>
                            </div>
                            <div className='col-6 fieldAndLabel'>
                                <label>Min</label>
                                <input
                                    min={0}
                                    onChange={handleFilterChange}
                                    name={'minMarginFlat'}
                                    value={payloadData.minMarginFlat}
                                    className='form-control inputHistory minMarginFlat'
                                    type='number'
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                />
                            </div>
                            <div className='col-6 fieldAndLabel'>
                                <label>Max</label>
                                <input
                                    min={0}
                                    onChange={handleFilterChange}
                                    name={'maxMarginFlat'}
                                    value={payloadData.maxMarginFlat}
                                    className='form-control inputHistory maxMarginFlat'
                                    type='number'
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                />
                            </div>
                            <div className='col-12'>
                                {error?.minMarginFlat &&
                                    <span className='error filterHisory'> {error.minMarginFlat}</span>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='row mb10'>
                {isMarginFilter &&
                    <div className='col-4'>
                        <div className='row'>
                            <div className='col-12'>
                                <label className='labelHead'>Margin(%)</label>
                            </div>
                            <div className='col-6 fieldAndLabel'>
                                <label>Min</label>
                                <input
                                    min={0}
                                    onChange={handleFilterChange}
                                    name={'minMarginPercentage'}
                                    value={payloadData.minMarginPercentage}
                                    className='form-control inputHistory minMarginPercentage'
                                    type='number'
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                />
                            </div>
                            <div className='col-6 fieldAndLabel'>
                                <label>Max</label>
                                <input
                                    min={0}
                                    onChange={handleFilterChange}
                                    name={'maxMarginPercentage'}
                                    value={payloadData.maxMarginPercentage}
                                    className='form-control inputHistory maxMarginPercentage'
                                    type='number'
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                />
                            </div>

                            <div className='col-12'>
                                {error?.minMarginPercentage &&
                                    <span className='error filterHisory'> {error.minMarginPercentage}</span>
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className='col-4'>
                    <div className='actions-margin-filter'>
                        <Button
                            className="cus-seconday-bg-btn top-btn"
                            onClick={handleFilter}
                        >Apply Filter
                        </Button>
                        <Button
                            className="cus-seconday-bg-btn top-btn"
                            onClick={() => handleResetFilter()}
                        >Reset
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MarginCarrierFilter;