import { batchService } from "../../services/batch.service";
import { batchConstant } from "../constants/batch.constant";

const getHistoryPending = () => ({
    type: batchConstant.GET_HISTORY_STATUS_PENDING,
});
const getHistoryFailed = () => ({
    type: batchConstant.GET_HISTORY_STATUS_PENDING,
});
const getHistorySuccess = (response) => ({
    type: batchConstant.GET_HISTORY_STATUS_SUCCESS,
    payload: response,
});
export const getOrderHistory = (data) => {
    return (dispatch) => {
        dispatch(getHistoryPending());
        batchService.getHistoryService(data).then((result) => {
            dispatch(getHistorySuccess(result.data));
        }).catch((error) => {
            dispatch(getHistoryFailed());
        })
    };
};
export const saveMailLabelForm = (data) => {
    return () => {
        return batchService.saveMailLabelService(data);
    };
};
const getCSVSuccess = (response) => ({
    type: batchConstant.GET_CSV_STATUS_SUCCESS,
    payload: response,
});
export const getOrdersCsv = (data) => {
    return (dispatch) => {
        batchService.getOrderCsvService(data).then((result) => {
            dispatch(getCSVSuccess(result.data.Entity));
        }).catch((error) => {
            dispatch(getCSVSuccess(error));
        })
    };
};
