import "./Navbar.css";
import Dropdown from "react-bootstrap/Dropdown";
import "react-dropdown/style.css";
import MainLogo from "../../assets/images/main-logo.png";
import MainLogoAi from "../../assets/images/dashBoardLogo2.svg";
import CustomToggle from "../common/CustomMenu";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getLoginUserDetailsonFund,
} from "../../store/actions/fundManagement";
import _ from "lodash";
import { checkPermission, formatNumberWithCommas } from "../../config/utils";
import SellerDropdown from "./SellerDropDown";
import { Spinner } from "react-bootstrap";
import { FEATURE_WALLETBALANCEDISPLAY, featureSubmodulePermission, permssionEdit, permssionView, SUBF_WALLETBALANCEDISPLAY } from "../../constant";

const Navbar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dummyState, setdummyState] = useState(0);
  const fundTransactionData = useSelector(
    (state) => state?.FundManagementReducer?.getWalletAmount
  );
  let hostName = window.location.hostname;
  const userDetail = useSelector((state) => state.auth.user);
  const { showLoader } = useSelector((state) => state.auth);
  const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);

  useEffect(() => {
    getFunds()
  }, []);

  const getFunds = () => {
    dispatch(
      getLoginUserDetailsonFund({
        creditCompanySellerId: userDetail?.Value?.companySellerId,
      })
    );
  }

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    navigate("/");
  };

  const handleNavigate = () => {
    setdummyState(dummyState + 1)
    navigate(`/fundManagement/history/fd-${userDetail?.Value?.companySellerId}-3pl`, {
      state: {
        userData: fundTransactionData,
        dummyState
      },
    }
    );
  };

  const renderWalletBalanceSection = () => {
    const hasEditPermission = checkPermission(FEATURE_WALLETBALANCEDISPLAY, SUBF_WALLETBALANCEDISPLAY, permssionEdit, featureSubmodulePermission, employeePermissions)
    
    const hasViewPermission = checkPermission(FEATURE_WALLETBALANCEDISPLAY, SUBF_WALLETBALANCEDISPLAY, permssionView, featureSubmodulePermission, employeePermissions)
  
    if (hasEditPermission) {
      return (
        <div className="walletBalanceSection" onClick={handleNavigate}>
          <span>Wallet Balance</span>${" "}
          {fundTransactionData
            ? formatNumberWithCommas(fundTransactionData?.balanceAmount)
            : 0}
        </div>
      );
    } else if (hasViewPermission) {
      return (
        <div className="walletBalanceSection viewOnlyFund">
          <span>Wallet Balance</span>${" "}
          {fundTransactionData
            ? formatNumberWithCommas(fundTransactionData?.balanceAmount)
            : 0}
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      {showLoader && (
        <div className="fullPageLoader">
          <div className="loaderContainer">
            <Spinner animation='border' size='lg' variant="primary" />;
          </div>
        </div>
      )}
      <nav className="navbars cus-header-sec">
        <div className="cus-header-left-sec">
          <img alt="" className="cus-main-logo-img maxHeight40" src={hostName === 'portal.shipvisionai.com' ? MainLogoAi : MainLogo} />
        </div>
        <div className="cus-header-right-sec">
          <div className="cus-loggedin-user-sec custom-algn-middle">
            <SellerDropdown getFunds={getFunds} navigate={navigate} />

            {renderWalletBalanceSection()}

            <div className="userDetailSection">
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                  className="userMenu"
                >
                  <span>
                    {userDetail?.Value?.userName &&
                      userDetail?.Value?.userName
                        .split(" ")[0]
                        .charAt(0)
                        .toUpperCase()}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="cus-profile-info-sec">
                    <div className="cus-profile-box">
                      <div className="cus-company-sec"></div>
                      <div className="cus-logout-sec">
                        <a
                          href="/#"
                          rel=""
                          className="dropdown-item"
                          onClick={handleLogout}
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                    <div className="cus-profile-info-name">
                      <div className="cus-profile-circle-sec">
                        {/* <img width='30' src={avatar} alt='Profile' /> */}
                        <span>
                          {userDetail?.Value?.userName &&
                            userDetail?.Value?.userName
                              .split(" ")[0]
                              .charAt(0)
                              .toUpperCase()}
                        </span>
                      </div>
                      <div className="cus-profile-detail-sec">
                        <div className="cus-profile-name-sec">
                          {_.startCase(_.toLower(userDetail?.Value?.userName))}
                        </div>
                        <div className="cus-profile-email-sec">
                          {userDetail?.Value?.emailId}
                        </div>
                      </div>
                    </div>
                    <div className="cus-details">
                      <div className="cus-profile-name-sec">
                        Seller : {userDetail?.Value?.sellerName}
                      </div>
                      <div className="cus-profile-name-sec">
                        Company : {userDetail?.Value?.companyName}
                      </div>
                      <div className="cus-profile-name-sec">
                        Role : {userDetail?.Value?.roleName}
                      </div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
