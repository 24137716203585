import {
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  GET_COMPANY_SUCCESS_BEFORE,
  UPDATE_USER,
  GET_ALL_FUNDS,
  CONFIGURATION_SUCCESS,
  GET_USERS_COMPANY_SELLER_SUCCESS,
  GET_USERS_COMPANY_SELLER_FAILED,
  SHOW_LOADER

} from "../types";
import AuthService from "../../services/auth.service";
import { toast } from "react-toastify";
import { apiUrl } from "../../constant";
import axios from "axios";

export const register = (username, email, password) => (dispatch) => {
  return AuthService.register(username, email, password).then((response) => {
    dispatch({
      type: REGISTER_SUCCESS,
    });
    dispatch({
      type: SET_MESSAGE,
      payload: response.data.message,
    });
    return Promise.resolve();
  });
};

export const verifyUserName = (email, navigate) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };
  try {
    const response = await axios.post(
      `${apiUrl}/api/login/forgetpassword`,
      { email },
      config
    );
    /* istanbul ignore next */
    if (response?.data?.Status === 200) {
      navigate('/login');
      toast.success(response?.data?.Entity);
    } else {
      toast.error(response?.data?.Entity);
    }
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};

export const resetPassword =
  (token, password, navigate) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/login/ResetPassword`,
        { token, password },
        config
      );
      /* istanbul ignore next */
      if (response?.data?.Status === 200) {
        toast(response?.data?.Entity);
        navigate('/login');
      }
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const login = (username, password) => (dispatch) => {
  return Promise.resolve(AuthService.login(username, password).then(
    (data) => {
      if (data?.StatusCode === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        dispatch({
          type: SET_MESSAGE,
          payload: "",
        });
        localStorage.setItem("userDetails", JSON.stringify(data));
        dispatch(getAppConfiguration());
        return Promise.resolve();
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: data?.Value,
        });
      }
    }));
};

export const getCompany = () => (dispatch) => {
  return AuthService.getCompany().then((data) => {
    dispatch({
      type: GET_COMPANY_SUCCESS_BEFORE,
      payload: { company: data.data },
    });
    return Promise.resolve();
  });
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: GET_ALL_FUNDS,
    payload: []
  });

};

export const saveUser = (response) => ({
  type: UPDATE_USER,
  payload: response,
});

export const refreshToken = (token) => (dispatch) => {
  return dispatch(AuthService.refreshTokenService(token));
};

const getAppConfigurationSuccess = (response) => ({
  type: CONFIGURATION_SUCCESS,
  payload: response,
});

export const getAppConfiguration = () => {
  return (dispatch) => {
    AuthService.configurationService()
      .then((result) => {
        dispatch(getAppConfigurationSuccess(result.data.EntityList));
        localStorage.setItem("config", JSON.stringify(result.data.EntityList));
      })
      .catch((error) => {
        dispatch(getAppConfigurationSuccess([]));
      });
  };
};

export const updateConfig = (values) => {
  return () => {
    return axios.put(`${apiUrl}/api/Configuration`, values);
  };
};

const getUserCompanySellerSuccess = (response) => ({
  type: GET_USERS_COMPANY_SELLER_SUCCESS,
  payload: response,
});

const getUserCompanySellerFailed = (response) => ({
  type: GET_USERS_COMPANY_SELLER_FAILED,
  payload: response,
});

export const getUserCompanySeller = (data) => {
  return (dispatch) => {
    AuthService.getCompanySellerService(data)
      .then((result) => {
        dispatch(getUserCompanySellerSuccess(result.data.Entity));
      })
      .catch((error) => {
        dispatch(getUserCompanySellerFailed());
      });
  };
};

export const generateTokenByCompanySeller = (payload) => {
  return () => {
    return axios.post(`${apiUrl}/api/login/GenerateTokenByCompanySellerAndUser`, payload);
  }
};

export const showFullLoader = (response) => ({
  type: SHOW_LOADER,
  payload: response,
});