import React, { useEffect, useState, useRef, useCallback } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Switch from "react-switch";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup } from "react-bootstrap";
import { BsPencil, BsSave2 } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "bootstrap-4-react/lib/components";

import _ from "lodash";
import {
  GetBaseZoneRateMasterData,
  UpdateBaseRateCard,
  UpdateSellerRateCard,
  GetSellerBaseZoneRateMasterData,
} from "../../../store/actions/rateCard";
import BaseZoneRateEditModal from "../../modal/BaseZoneRateEditModal";
import AlertDialogModal from "../../common/AlertDialog";
import { saveIndividualRate } from "../../../store/actions/batch.action";
import { toast } from "react-toastify";
import { getSellers } from "../../../store/actions/fundManagement";
import {
  getSellersDetailsOnEdit,
  getSellersforMultipleCompany,
} from "../../../store/actions";
 /* istanbul ignore next */
function CardDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);

  const [cellEdit, setCellEdit] = React.useState(false);
  const [companyEdit, setcompanyEdit] = React.useState(false);
  const [rate, setRate] = React.useState("");


  const [sellerList, setSellerList] = useState();
  const [getSellerData, setgetSellerData] = useState();
  const [negativerate, setNegativeRate] = React.useState(false);
  const [cellEditBoolean, setCellEditBoolean] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [effectiveCompany, seteffectiveCompany] = useState();
  const [effectiveSeller, seteffectiveSeller] = useState();
  const authId = useSelector((state) => state.auth.user);
  const [selectedRate, setSelectedRate] = React.useState(false);
  const [statechange, setstatechange] = React.useState(false);
  const rowData = location?.state?.data;
  const companyData = location?.state?.activeCompany;
  const isFirstRender = useRef(0);
  const tab = location?.state?.tab;
  const [active, setActive] = React.useState(rowData?.isActive);
  const editableRate = location?.state?.editable;
  const [editable, setEditable] = React.useState(editableRate);
  const cardType = location?.state?.type;

  const [formData, setFormData] = useState({
    name: rowData?.name || rowData?.sellerRateCardName,
    carrierProvider: rowData?.carrierName || rowData?.carrierProviderName,
    createdDate: rowData?.createdDate,
    effectiveCompanies: rowData?.effectiveCompanies,
    effectiveFrom: rowData?.effectiveFrom,
    effectiveTo: rowData?.effectiveTo,

    IsActive: rowData?.isActive,
  });



  const [statusModalShow, setStatusModalShow] = useState(false);
  const [newArray, setNewArray] = useState([]);
  const AllRateCardsZoneData = useSelector(
    (state) => state?.RateCardReducer?.getRateCardZoneData?.EntityList
  );
  const permissions = useSelector(
    (state) => state?.sidebar?.employeePermissions
  );
  const getMultipleSellers = useSelector(
    (state) => state?.company?.getMultipleCompanySellers
  );


  const updateFormData = () => {

    setFormData({
      ...formData,
      effectiveSellerIds: sellerList?.filter((seller) =>
        formData?.effectiveSellerIds?.some((effectiveSeller) => {
          return (
            seller.id === effectiveSeller.id &&
            seller.companyIds === effectiveSeller.companyIds
          );
        })
      ),
    });
  };
  useEffect(() => {
    if (
      getMultipleSellers?.Entity?.listSellerMasterOrdersEntity &&
      getMultipleSellers?.Entity?.listSellerMasterOrdersEntity.length
    ) {
      var data = getMultipleSellers?.Entity?.listSellerMasterOrdersEntity?.map(
        (item, index) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: index + 1,
          };
        }
      );
      setSellerList(data);
    } else {
      setSellerList([]);
    }

    if (!companyEdit) {
      setFormData({
        ...formData,
        effectiveSellerIds: data?.filter((item) => {
          return effectiveSeller?.some(
            (s) =>
              s?.SellerId === item?.id &&
              s?.CompanyId === parseInt(item?.companyIds)
          );
        }),
        effectiveCompanyIdS: companyData?.filter((option) =>
          effectiveCompany?.includes(option.value)
        ),
      });
    }

  }, [getMultipleSellers, effectiveCompany, effectiveSeller, companyEdit]);

  useEffect(() => {
    const newArrayUpdate = [];
    const uniqueWeights = [];
    for (let i = 0; i < AllRateCardsZoneData?.length; i++) {
      const currentItem = AllRateCardsZoneData[i];
      const weight = currentItem.weight;
      if (uniqueWeights.indexOf(weight) === -1) {
        uniqueWeights.push(weight);
        newArrayUpdate.push({
          ...currentItem,
          weight: weight,
          rates: [currentItem],
        });
      } else {
        const index = uniqueWeights.indexOf(weight);
        newArrayUpdate[index].rates.push(currentItem);
      }
    }
    setNewArray(newArrayUpdate);
  }, [AllRateCardsZoneData]);
  
  useEffect(async () => {
    if (tab === 2) {
      if (rowData?.carrierId) {
        dispatch(
          GetSellerBaseZoneRateMasterData(
            rowData?.carrierId,
            rowData?.sellerRateCardMasterId
          )
        );
      }
    } else {
      if (rowData?.carrierId) {
        dispatch(GetBaseZoneRateMasterData(rowData?.carrierId));
      }
    }
    const res = await dispatch(
      getSellersDetailsOnEdit(rowData?.sellerRateCardMasterId)
    );
    seteffectiveCompany(
      _.map(res?.data?.Entity?.effectiveCompanyIdS, "CompanyIds")
    );
    seteffectiveSeller(res?.data?.Entity?.EffectiveCompanyIdsAndSellerIds);
    setgetSellerData(res?.data?.Entity);
    dispatch(
      getSellersforMultipleCompany(
        _.map(res?.data?.Entity?.effectiveCompanyIdS, "CompanyIds")
      )
    );
  }, [rowData]);
  useEffect(() => {

    updateFormData(); // Update formData when effectiveCompanyIds change
  }, [formData?.effectiveCompanyIdS?.length, sellerList]);
  const getBaseZoneRate = () => {
    if (tab === 2) {
      dispatch(
        GetSellerBaseZoneRateMasterData(
          rowData?.carrierId,
          rowData?.sellerRateCardMasterId
        )
      );
    } else {
      dispatch(GetBaseZoneRateMasterData(rowData?.carrierId));
    }
  };
  const handleModalOpen = (e, data, weight) => {
    setSelectedRate(weight);
    setCellEdit(data);
    setCellEditBoolean(true);
    // setModalShow(true);
  };
  const handleSave = (e, data, weight) => {
    setSelectedRate(weight);
    setCellEdit(data);
    setCellEditBoolean(false);
    handleSaveRate();
  };
  const navigate = useNavigate();
  const handleClick = () => {
    if (formData?.IsActive !== rowData.isActive) {
      setStatusModalShow(true);
    } else {
      if (editable && formData?.name != "") {
        if (cardType !== "sellerRatecard") {
          dispatch(
            UpdateBaseRateCard(
              {
                ...rowData,
                name: formData?.name,
                companySellerId: authId?.Value?.companySellerId,
                effectiveFrom: null,
                effectiveTo: null,
                isActive: formData?.IsActive,
                sellerId: authId?.Value?.sellerId,
                companyId: authId?.Value?.companyId,
                employeeId: authId?.Value?.companyId,
              },
              navigate,
              setErrors
            )
          );
        }

        if (tab === 2) {
          dispatch(
            UpdateSellerRateCard(
              {
                ...rowData,
                name: formData?.name,
                effectiveFrom: null,
                companySellerId: authId?.Value?.companySellerId,
                effectiveTo: null,
                effectiveCompanyIdsAndSellerIds:
                  formData?.effectiveSellerIds?.map((d) => {
                    return {
                      companyId: d?.companyIds,
                      sellerId: d?.id,
                    };
                  }),
                createdDate: new Date(),
                isActive: formData?.IsActive,
                sellerId: authId?.Value?.sellerId,
                companyId: authId?.Value?.companyId,
                employeeId: authId?.Value?.companyId,
              },
              navigate,
              setErrors
            )
          );
        }
      } else {
        setErrors({
          Name: "This is a mandatory field",
        });
      }
      // navigate("/rateCard" ,{ state: { zoneTab: rowData.activeTab } });
    }
  };

  const handleStatusChange = (e) => {
    setFormData({
      ...formData,
      IsActive: e,
    });
    setActive(e);
    setstatechange(true);
  };

  const handleValueChange = (e, name) => {
    if (name === "effectiveCompanyIdS") {
      setcompanyEdit(true);
      seteffectiveCompany([]);
      seteffectiveSeller([]);
      // change this getseller api because now we need to send multiple company id
      dispatch(getSellersforMultipleCompany(_.map(e, "Id")));
      setFormData({
        ...formData,
        [name]: e,
      });
    } else if (name === "effectiveSellerIds") {
      setFormData({
        ...formData,
        [name]: e,
      });
    } else {
      const { value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setstatechange(true);
  };

  const handleYesDeleteAlert = () => {
    if (statechange && formData?.name != "") {
      if (cardType !== "sellerRatecard") {
        dispatch(
          UpdateBaseRateCard(
            {
              ...rowData,
              name: formData?.name,
              effectiveFrom: null,
              effectiveTo: null,
              isActive: formData?.IsActive,
              sellerId: authId?.Value?.sellerId,
              companyId: authId?.Value?.companyId,
              employeeId: authId?.Value?.companyId,
            },
            navigate,
            setErrors
          )
        );
      }
      if (tab === 2) {
        dispatch(
          UpdateSellerRateCard(
            {
              ...rowData,
              name: formData?.name,
              effectiveFrom: null,
              effectiveTo: null,

              effectiveCompanyIdsAndSellerIds:
                formData?.effectiveSellerIds?.map((d) => {
                  return {
                    companyId: d?.companyIds,
                    sellerId: d?.id,
                  };
                }),
              createdDate: new Date(),
              isActive: formData?.IsActive,
              sellerId: authId?.Value?.sellerId,
              companyId: authId?.Value?.companyId,
              employeeId: authId?.Value?.companyId,
            },
            navigate,
            setErrors
          )
        );
      }
    } else {
      setErrors({
        Name: "This is a mandatory field",
      });
    }
    setstatechange(false);
    setStatusModalShow(false);
  };
  const handleCancelSave = () => {
    setStatusModalShow(false);
  };
  const handleRateValue = (value) => {
    if (value < 0) {
      setNegativeRate(true);
    }
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      setRate(value);
    }
  };

  const handleSaveRate = async () => {
    let data = {
      ...cellEdit,
      updatedRate: rate,
      createdBy: authId?.Value?.employeeId,
      companyId: authId?.Value?.companyId,
      sellerId: authId?.Value?.sellerId,
    };
    delete data.rates;
    delete data.weight;
    let form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }
    if (rate > 0 && rate <= 99.99) {
      let rateSaveRes = await dispatch(saveIndividualRate(data));
      setNegativeRate(false);
      setRate("");
      getBaseZoneRate();
      if (
        rateSaveRes &&
        rateSaveRes.data.Entity.isValid &&
        rateSaveRes.data.Status === 200
      ) {
        toast(rateSaveRes.data.Entity.message);
        props.onHide();
      } else {
        toast(rateSaveRes.data.Entity.message);
      }
    } else if (rate <= 0 && rate != "") {
      toast.error("Rate cannot be Zero or negative");
    } else if (rate > 99.99) {
      toast.error("Rate cannot be greater than 99.99");
    }
  };
  // const effectiveSellerList = useCallback(() => {
  //   return (

  //   );
  // }, [formData?.effectiveSellerIds, editable, editable, sellerList]);

  return (
    <main>
      {modalShow && (
        <BaseZoneRateEditModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          selectedRateRecord={selectedRate}
          getBaseZoneRate={getBaseZoneRate}
          userData={authId}
        />
      )}
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          Rate Card Detail
          <div className="top_right_button_container">
            {permissions?.baseRateCard && rowData?.isActive && (
              <>
                {editable ? (
                  <Button
                    className="cus-seconday-bg-btn top-btn"
                    onClick={handleClick}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    className="cus-seconday-bg-btn top-btn"
                    onClick={() => setEditable(true)}
                  >
                    Edit
                  </Button>
                )}
              </>
            )}
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                setFormData({ ...formData, sellerIds: [] });
                navigate("/rateCard", {
                  state: { zoneTab: rowData?.activeTab },
                });
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="container-fluid">
          <div className="top_right_button_container cus-content-only-sec">
            <Row className="cus-mar-bottom">
              {editable ? (
                <Col xs={4}>
                  <InputGroup className="">
                    <div className="input-label-Created-date">Name </div>
                    <input
                      onChange={(e) => handleValueChange(e, "name")}
                      className="form-controlzm"
                      value={formData?.name}
                    />
                    {!formData?.name && (
                      <p className="error dialogError">{errors?.Name}</p>
                    )}
                    {/* </input> */}
                  </InputGroup>
                </Col>
              ) : (
                <Col xs={4}>
                  <InputGroup className="">
                    <div className="input-label-Created-date">Name</div>
                    <div className="input-box">{formData?.name}</div>
                  </InputGroup>
                  {!formData?.name && (
                    <p className="error dialogError">{errors?.Name}</p>
                  )}
                </Col>
              )}
              <Col xs={4}>
                <Form.Group>
                  <div className="input-label-Created-date">Created Date:</div>
                  <div className="input-box">{formData?.createdDate}</div>
                </Form.Group>
              </Col>
              <Col xs={4} className="status-data">
                Status
                <span style={{ marginLeft: "10px" }}>
                  <Switch
                    disabled={!editable}
                    onChange={(e) => handleStatusChange(e)}
                    checked={active}
                  />
                </span>
              </Col>
              {/* <Col>
                {permissions.baseRateCard && rowData?.isActive && (
                  <Button className="cus-seconday-bg-btn" onClick={handleClick}>
                    {editable || editable ? "Save" : "Edit"}
                  </Button>
                )}
                <Button
                  className="cus-primary-transparent-btn"
                  onClick={() => {
                    navigate("/rateCard" , { state: { zoneTab: rowData.activeTab } });
                    
                  }}
                >
                  Back
                </Button>
              </Col> */}
            </Row>
            {tab === 2 && (
              <Row className="cus-mar-bottom">
                <>
                  <Col xs={4}>
                    <Form.Group>
                      <div className="input-label-Created-date">
                        Effective Companies:
                      </div>
                      <div className="input-box rateDetail-select">
                        <Select
                          isMulti
                          key={formData?.effectiveCompanyIdS}
                          options={companyData}
                          onChange={(value) =>
                            handleValueChange(value, "effectiveCompanyIdS")
                          }
                          className="activeSellerListInput"
                          isDisabled={!editable}
                          defaultValue={formData?.effectiveCompanyIdS}
                          classNamePrefix="list"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group>
                      <div className="input-label-Created-date">
                        Effective Sellers:
                      </div>
                      <div className="input-box rateDetail-select">
                        <Select
                          isMulti
                          options={sellerList}
                          onChange={(value) =>
                            handleValueChange(value, "effectiveSellerIds")
                          }
                          className="activeSellerListInput"
                          isDisabled={!editable}
                          value={formData?.effectiveSellerIds}
                          classNamePrefix="list"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </>

                <Col xs={4}>
                  <Form.Group>
                    <div className="input-label-Created-date">
                      Carrier Provider:
                    </div>
                    <div className="input-box">
                      {" "}
                      {formData?.carrierProvider}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col xs={4}>
                <Form.Group>
                  <div className="input-label-Created-date">
                    Effective From:
                  </div>
                  <div className="input-box">{formData.effectiveFrom}</div>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <div className="input-label-Created-date">
                    Effective Till:
                  </div>
                  <div className="input-box">{formData?.effectiveTo}</div>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="main_content_table cus-margin-top">
            {!editable ? (
              <Table striped hover size="sm" className="table-Data">
                <thead>
                  <tr>
                    <th>Weight</th>
                    <th>Zone 1</th>
                    <th>Zone 2</th>
                    <th>Zone 3</th>
                    <th>Zone 4</th>
                    <th>Zone 5</th>
                    <th>Zone 6</th>
                    <th>Zone 7</th>
                    <th>Zone 8</th>
                    <th>Zone 9</th>
                  </tr>
                </thead>
                <tbody>
                  {newArray &&
                    newArray?.map((d, index) => (
                      <tr key={index}>
                        <td>{d?.weight}</td>
                        {d?.rates?.map((_, rateIndex) => (
                          <td key={rateIndex}>
                            {tab === 2 ? _.updatedRate : _.rate}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <Table striped hover size="sm" className="table-Data">
                <thead>
                  <tr>
                    <th>Weight</th>
                    <th>Zone 1</th>
                    <th>Zone 2</th>
                    <th>Zone 3</th>
                    <th>Zone 4</th>
                    <th>Zone 5</th>
                    <th>Zone 6</th>
                    <th>Zone 7</th>
                    <th>Zone 8</th>
                    <th>Zone 9</th>
                  </tr>
                </thead>
                <tbody>
                  {newArray &&
                    newArray?.map((d) => (
                      <>
                        <tr>
                          <td>{d?.weight}</td>
                          {d?.rates?.map((_, index) => (
                            <td key={index}>
                              <Row>
                                {/* {cellEdit ? (
                                  <Col>
                                    <input defaultValue={_.Rate}></input>
                                  </Col>
                                ) : (
                                  <Col>{_.Rate}</Col>
                                )} */}
                                {cellEdit?.sellerZoneRateId ===
                                  _?.sellerZoneRateId && cellEditBoolean ? (
                                  <Col>
                                    <input
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      style={{ width: "50px" }}
                                      defaultValue={
                                        tab === 2 ? _.updatedRate : _.rate
                                      }
                                      onChange={(e) =>
                                        handleRateValue(e.target.value)
                                      }
                                    ></input>
                                  </Col>
                                ) : (
                                  <Col>
                                    {tab === 2 ? _.updatedRate : _.rate}
                                  </Col>
                                )}
                                {tab === 2 && (
                                  <>
                                    {cellEdit?.sellerZoneRateId ===
                                      _?.sellerZoneRateId && cellEditBoolean ? (
                                      <Col
                                        style={{
                                          cursor: "pointer",
                                          color: "blue",
                                          textAlign: "right",
                                        }}
                                        onClick={(e) => handleSave(e, _, _)}
                                      >
                                        <BsSave2
                                          style={{
                                            cursor: "pointer",
                                            color: "black",
                                            fontSize: "12px",
                                          }}
                                        ></BsSave2>
                                      </Col>
                                    ) : (
                                      <Col
                                        style={{
                                          cursor: "pointer",
                                          color: "blue",
                                          textAlign: "right",
                                        }}
                                        onClick={(e) =>
                                          handleModalOpen(e, _, _)
                                        }
                                      >
                                        <BsPencil
                                          style={{
                                            cursor: "pointer",
                                            color: "black",
                                            fontSize: "12px",
                                          }}
                                        ></BsPencil>
                                      </Col>
                                    )}
                                  </>
                                )}
                              </Row>{" "}
                            </td>
                          ))}
                        </tr>
                      </>
                    ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
      <AlertDialogModal
        modalClass={"modalDeleteWrapper"}
        modalShow={statusModalShow}
        handleCancelDeleteAlert={handleCancelSave}
        handleYesDeleteAlert={handleYesDeleteAlert}
        messageText={`Are you sure you want to ${rowData?.isActive ? "deactivate" : "activate"
          }  the rate card?`}
        headingText={"Are you sure?"}
      />
    </main>
  );
}

export default CardDetails;
