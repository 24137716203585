import React, { useState, useEffect, useRef } from "react";
import "./userModal.css";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { GET_SELLERS } from "../../../store/types";
import {
  createUser,
  getAllAssociatedUsers,
  getAllCountries,
  getAllUsers,
  getSellersforMultipleCompany,
  getUserRole,
} from "../../../store/actions";
import { getAllCompanyDetails } from "../../../store/actions/rateCard";
import Input from "react-phone-number-input/input";
import { checkSellersInCompanyList, filterFromList, getSelectList, getUserRoleName, validatePhoneNumber } from "../../../config/utils";
import { emailRegex, passwordMinRegex } from "../../../constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import LoaderSpinner from "../../common/Loader";

function AddUserModal(props) {
  const { showModal = true } = props;
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [roleList, setRoleList] = useState();
  const [companyList, setCompanyList] = useState([]);
  const [sellerList, setSellerList] = useState();
  const [loader, setLoader] = useState(false);
  const [defaultSellerList, setDefaultSellerList] = useState([]);
  const [sellerCompanyError, setSellerCompanyError] = useState('');

  const getAllCompany = useSelector(
    (state) => state.RateCardReducer?.getAllCompany
  );
  const createdBy = useSelector((state) => state?.auth?.user);
  const { countries } = useSelector((state) => state?.shipment);

  const getSellersValue = useSelector(
    (state) => state?.company?.getMultipleCompanySellers
  );

  const [formData, setFormData] = useState({
    createdBy: createdBy?.Value?.employeeId,
    modifiedBy: createdBy?.Value?.employeeId,
    createdDate: new Date(),
    modifiedDate: new Date(),
    isActive: true,
    country_Id: 218,
    phoneNumber: "+1 ",
  });

  useEffect(() => {
    if (!countries.length) {
      dispatch(getAllCountries());
    }
  }, [countries]);

  useEffect(async () => {

    dispatch(getAllCompanyDetails(null, 500, 1));
    const roleData = await getUserRole();
    /* istanbul ignore next */

    if (roleData?.data?.EntityList && roleData?.data?.EntityList?.length) {
      let data = roleData?.data?.EntityList?.map((item) => {
        /* istanbul ignore next */
        return {
          ...item,
          value: item.id,
          label: item.name,
        };
      });
      /* istanbul ignore next */
      setRoleList(data);
    }
  }, [createdBy]);

  useEffect(() => {
    /* istanbul ignore next */
    if (
      getAllCompany?.Entity &&
      getAllCompany?.Entity?.listCompanyMasterOrdersEntity.length
    ) {
      let data = getAllCompany?.Entity?.listCompanyMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            value: item.Id,
            label: item.name,
          };
        }
      );
      setCompanyList(data);
    }
  }, [getAllCompany]);

  const handleUserCompany = (item, isArray) => {
    dispatch(getSellersforMultipleCompany(_.map(isArray ? item : [item], "Id")));
    if (formData.defaultCompanyId) {
      const result = item.filter(c => formData.defaultCompanyId.value === c.value);
      if (result.length) {
        setFormData({ ...formData, userCompanyIdList: isArray ? item : [item] });
      } else {
        setFormData({ ...formData, userCompanyIdList: isArray ? item : [item], defaultCompanyId: null, defaultSellerId: null });
      }
    } else {
      setFormData({ ...formData, userCompanyIdList: isArray ? item : [item] });
    }
  }

  const TopUpForm = (item, name) => {
    setErrors({ ...errors, [name]: "", });
    setFormData({ ...formData, [name]: item });
    let isArray = Array.isArray(item);
    if (name === "userCompanyIdList") {
      handleUserCompany(item, isArray);
    }
    if (name === "userRole") {
      if (Number(item?.id) === 3) {
        setFormData({ ...formData, userCompanyIdList: [], userSellerIdList: [], [name]: item, defaultCompanyId: null, defaultSellerId: null });
      }
    }
    if (name === "userSellerIdList") {
      setFormData({ ...formData, userSellerIdList: isArray ? item : [item] });
      setSellerCompanyError('');
    }
    if (name === "defaultCompanyId") {
      setFormData({ ...formData, [name]: item, defaultSellerId: null });
    }
  };

  useEffect(() => {
    if (formData.country_Id && countries.length) {
      let getSelectedCountry = filterFromList(
        countries,
        formData.country_Id
      )[0];
      setFormData({
        ...formData,
        phoneNumber: `+ ${getSelectedCountry?.phoneCountryCode}`,
      });
    }
  }, [formData.country_Id]);

  useEffect(() => {
    if (getSellersValue && getSellersValue?.Entity?.listSellerMasterOrdersEntity?.length) {
      let sellerListData = [...getSellersValue?.Entity?.listSellerMasterOrdersEntity];
      let prepareListData = getSelectList(sellerListData, "id", "sellerAndCompany")
      setSellerList(prepareListData);
      const result = formData?.userSellerIdList?.filter(c => sellerListData.some(s => s.id === c.id));
      const filterDefaultSeller = formData.defaultCompanyId && formData?.userSellerIdList?.filter(seller => Number(seller.companyIds) === Number(formData.defaultCompanyId.Id));
      setDefaultSellerList(filterDefaultSeller);
      setFormData({ ...formData, userSellerIdList: result });
    } else {
      setSellerList([]);
      setFormData({ ...formData, userSellerIdList: [] });
    }
  }, [getSellersValue, formData.defaultCompanyId, formData.userCompanyIdList]);

  const checkRequiredFields = (requiredFields, formData) => {
    let isValid = true;
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field] || (Array.isArray(formData[field]) && !formData[field].length)) {
        isValid = false;
        newErrors[field] = `This is required`;
      }
    });

    return { isValid, newErrors };
  };

  const checkEmail = (formData, isValid, newErrors) => {
    if (formData.userEmailId && !emailRegex.test(formData.userEmailId)) {
      isValid = false;
      newErrors.userEmailId = "Invalid email address";
    }
    return { isValid, newErrors };
  };

  const checkPassword = (formData, isValid, newErrors) => {
    if (formData?.password && !passwordMinRegex.test(formData?.password)) {
      isValid = false;
      newErrors.password = "Please enter between 8 and 15 characters that contain at least one numeric, special, uppercase, and lowercase letter";
    }
    if (formData?.confirmPassword !== formData?.password) {
      isValid = false;
      newErrors.confirmPassword = "Password Mismatch";
    }
    return { isValid, newErrors };
  };

  const checkPhoneNumber = (formData, isValid, newErrors) => {
    if (validatePhoneNumber(formData.phoneNumber)) {
      isValid = false;
      newErrors.phoneNumber = "Enter valid phone number";
    }
    return { isValid, newErrors };
  };

  const checkSellerList = (formData, isValid, newErrors) => {
    if (!checkSellersInCompanyList(formData.userCompanyIdList, formData.userSellerIdList)) {
      setSellerCompanyError("Select at least one seller for every company");
      isValid = false;
    } else {
      setSellerCompanyError("");
    }
    return { isValid, newErrors };
  };

  const saveUserData = () => {
    const requiredFields = [
      "name",
      "password",
      "newUserName",
      "userEmailId",
      "phoneNumber",
      "confirmPassword",
      "userCompanyIdList",
      "userSellerIdList",
      "defaultCompanyId",
      "defaultSellerId",
      "userRole",
    ];

    let isValid = true;
    let newErrors = {};
    ({ isValid, newErrors } = checkRequiredFields(requiredFields, formData));
    ({ isValid, newErrors } = checkEmail(formData, isValid, newErrors));
    ({ isValid, newErrors } = checkPassword(formData, isValid, newErrors));
    ({ isValid, newErrors } = checkPhoneNumber(formData, isValid, newErrors));
    ({ isValid, newErrors } = checkSellerList(formData, isValid, newErrors));
    handleCreateUser(isValid, newErrors);
  };


  const handleOnSaveAddressType = () => {
    props.onHide();
    dispatch(
      getAllAssociatedUsers(
        props.setTotalPages,
        props.numberOfPage,
        props.currentPage,
        props.filterValue,
        props.companyId,
        props.sellerId
      )
    );
  }

  const handleOnSaveAddress = (
  ) => {
    props.onHide();
    dispatch(
      getAllUsers(
        props.setTotalPages,
        props.numberOfPage,
        props.currentPage,
        props.filterValue,
      )
    );
  };

  const handleCreateUser = async (isValid, newErrors) => {
    if (isValid) {
      try {
        dispatch(
          createUser(
            formData,
            handleOnSaveAddressType,
            handleOnSaveAddress,
            props?.type,
            setLoader
          )
        );
      } catch (e) {
        toast.error(e?.response?.data?.errorDescription);
      }
    } else {
      setErrors({ ...errors, ...newErrors });
    }
  };

  const handleCancel = () => {
    dispatch({
      type: GET_SELLERS,
      payload: [],
    });
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="topUpModal"
      centered
      backdrop="static"
      ref={modalRef}
      show={showModal}
    >
      <div className="top_modal_header_container">
        <span className="">Add User {loader && <LoaderSpinner />}</span>
        <div className="modalDeleteWrapper">
          <button
            type="button"
            onClick={() => handleCancel()}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
      </div>

      <div className="modal_container_rate_card">
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Full Name:</span>
          </div>
          <div className="col-8">
            <input
              onChange={(e) => TopUpForm(e.target.value, "name")}
              value={formData?.name}
              className="form-control availableBalanceInput"
              type="text"
            />
            <p className="error dialogError">{errors.name}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">User Name:</span>
          </div>
          <div className="col-8">
            <input
              onChange={(e) => TopUpForm(e.target.value, "newUserName")}
              value={formData?.newUserName}
              className="form-control availableBalanceInput"
              type="text"
            />
            <p className="error dialogError">{errors.newUserName}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Email Address:</span>
          </div>
          <div className="col-8">
            <input
              onChange={(e) => TopUpForm(e.target.value, "userEmailId")}
              value={formData?.userEmailId}
              className="form-control availableBalanceInput"
              type="text"
            />
            <p className="error dialogError">{errors.userEmailId}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label">Role</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={roleList}
                onChange={(value) => TopUpForm(value, "userRole")}
                className="activeSellerListInput"
                value={formData?.userRole}
                classNamePrefix="list-value"
                placeholder="Select Role..."
              />
            </div>
            <p className="error dialogError"> {errors?.userRole}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label">Company:</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={companyList}
                onChange={(value) => TopUpForm(value, "userCompanyIdList")}
                className="userCompanyIdList"
                value={formData?.userCompanyIdList}
                placeholder="Select Company..."
                isMulti={!getUserRoleName(roleList, 'Super Admin', formData?.userRole)}
                closeMenuOnSelect={false}
              />
            </div>
            <p className="error dialogError"> {errors?.userCompanyIdList}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label">Seller:</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={sellerList}
                onChange={(value) => TopUpForm(value, "userSellerIdList")}
                className="activeSellerListInput"
                value={formData?.userSellerIdList}
                classNamePrefix="list-value"
                isMulti={!getUserRoleName(roleList, 'Super Admin', formData?.userRole)}
                placeholder="Select Seller..."
                closeMenuOnSelect={false}
              />
            </div>
            <p className="error dialogError"> {errors?.userSellerIdList}</p>
            <p className="error dialogError"> {sellerCompanyError}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label">Default Company:</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={formData?.userCompanyIdList}
                onChange={(value) => TopUpForm(value, "defaultCompanyId")}
                className="activeSellerListInput"
                isMulti={false}
                value={formData?.defaultCompanyId}
                classNamePrefix="list-value"
                placeholder="Select Default Company..."
              />
            </div>
            <p className="error dialogError"> {errors?.defaultCompanyId}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel ">
            <span className="input-label">Default Seller:</span>
          </div>
          <div className="col-8">
            <div className={"select_react"}>
              <Select
                options={defaultSellerList}
                onChange={(value) => TopUpForm(value, "defaultSellerId")}
                className="activeSellerListInput"
                isMulti={false}
                value={formData?.defaultSellerId}
                classNamePrefix="list-value"
                placeholder="Select Default Seller..."
              />
            </div>
            <p className="error dialogError"> {errors?.defaultSellerId}</p>
          </div>
        </div>

        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Country:</span>
          </div>
          <div className="col-8">
            <select
              value={formData.country_Id}
              className="form-control country_Id"
              onChange={(e) => TopUpForm(Number(e.target.value), "country_Id")}
            >
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Phone Number:</span>
          </div>
          <div className="col-8">
            <Input
              value={formData?.phoneNumber}
              name="phoneNumber"
              onChange={(e) => TopUpForm(e, "phoneNumber")}
              className={`form-control availableBalanceInput phoneNumber`}
            />
            <p className="error dialogError">{errors.phoneNumber}</p>
          </div>
        </div>

        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Password:</span>
          </div>
          <div className="col-8">
            <input
              onChange={(e) => TopUpForm(e.target.value, "password")}
              type={"text"}
              onBlur={(e) => saveUserData()}
              name="password"
              value={formData?.password}
              className="form-control availableBalanceInput password"
            />
            <p className="error dialogError">{errors.password}</p>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-4 formLabel">
            <span className="input-label">Confirm Password:</span>
          </div>
          <div className="col-8">
            <input
              onChange={(e) => TopUpForm(e.target.value, "confirmPassword")}
              type={"text"}
              name="confirmPassword"
              value={formData?.confirmPassword}
              className="form-control availableBalanceInput confirmPassword"
            />
            <p className="error dialogError">{errors.confirmPassword}</p>
          </div>
        </div>

        <Row className="modaL_footer">
          <div
            className="col-12 noPadding"
            style={{ textAlign: "right" }}
            xs={12}
          >
            <Button
              className="btn cus-seconday-bg-btn"
              onClick={() => handleCancel()}
              disabled={loader}
            >
              Cancel
            </Button>
            <Button
              disabled={loader}
              id="saveTopUpBtn"
              className="btn cus-seconday-bg-btn marginLeft5"
              onClick={saveUserData}
            >
              Add
            </Button>
          </div>
        </Row>
      </div>
    </Modal>
  );
}

export default AddUserModal;
