import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoaderSpinner from "../../common/Loader";
import {
  getAllCountries,
  getDimensionUnitList,
  getWeightUnitList,
} from "../../../store/actions";
import { allowedExtensions } from "../../../constant";
import {
  createInventoryProduct,
  deleteWarehouseLocation,
  getAllConsignmentType,
  getProductById,
  resetProductDetails,
  updateAttachmentsByProductsId,
  updateInventoryProduct,
  uploadProductMedia,
} from "../../../store/actions/inventory.action";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { appendFilesToUploadData, handleDocsUpload } from "../../../config/utils";
import ProductImageSlider from "./ProductImageSlider";
import AlertDialogModal from "../../common/AlertDialog";
import { Accordion } from "react-bootstrap";
import WarehouseDetails from "./WarehouseDetails";
import { InventoryProductBackDetails } from "../../../store/actions/employee";
import _ from "lodash";

const ProductInventory = () => {
  const { id } = useParams();
  const location = useLocation();
  const [isEditable, setIsEditable] = useState(location?.state?.isEdit);
  const userDetails = useSelector((state) => state.auth.user);
  const countries = useSelector((state) => state.shipment.countries);
  const { productDetails, productDetailsStatus } = useSelector(
    (state) => state.InventoryManagement
  );
  const { warehouseList } = useSelector((state) => state.InventoryManagement);
  const [loader, setLoader] = useState(false);
  const [deletedWareHouses, setDeletedWareHouses] = useState([]);
  const [image, setImage] = useState([]);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setErrors] = useState({});
  const [productFormCopy, setProductFormCopy] = useState({});
  const { consignmentTypes } = useSelector(
    (state) => state.InventoryManagement
  );
  const { dimensionUnitList, weightUnitList } = useSelector(
    (state) => state.shipment
  );

  useEffect(() => {
    return () => {
      dispatch(resetProductDetails());
    };
  }, []);

  useEffect(() => {
    if (!dimensionUnitList.length) {
      dispatch(getDimensionUnitList());
    }

    if (!weightUnitList.length) {
      dispatch(getWeightUnitList());
    }
  }, [dimensionUnitList, weightUnitList]);

  const productObj = {
    isSuperAdmin: true,
    createdBy: 0,
    id: 0,
    productName: "",
    externalProductId: "",
    productDescription: "",
    productSku: "",
    quantity: "",
    productUpc: "",
    warehouseLocation: "",
    hsn: "",
    uom: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    weightUnit: 1,
    DimensionUnit: 1,
    ounces: "",
    unit: 1,
    ean: "",
    isbn: "",
    itemExpiryRaw: "",
    condition: "",
    itemExpiryDate: new Date(moment().add(1, "y")),
    inventoryProductCondition_Id: 1,
    mpn: "",
    sellingPrice: "",
    costPrice: "",
    isActive: 0,
    wareHouseDetails: [
      {
        warehouseId: 0,
        comments: "",
        itemExpiryDate: "",
        countryOfOrigin: "",
        sellerLocation: "",
        supplierCode: "",
        quantity: 0,
        areaId: 0,
        rowId: 0,
        bayId: 0,
        levelId: 0,
        positionId: 0,
        storageId: ''
      },
    ],
    option1: "",
    name1: "",
    option2: "",
    name2: "",
    option3: "",
    name3: new Date(),
    consignmentTypeId: 3,
  };
  const [productForm, setProductForm] = useState({});

  useEffect(() => {
    if (Number(id)) {
      getProductDetailsById(id);
    } else {
      setProductForm({ ...productObj });
    }
  }, [id]);

  useEffect(() => {
    if (!consignmentTypes.length) {
      dispatch(getAllConsignmentType());
    }
  }, [consignmentTypes]);

  useEffect(() => {
    if (Number(id) > 0) {
      setProductFormCopy(JSON.parse(JSON.stringify(productDetails)));
      let rr = { ...productDetails };
      if (rr?.wareHouseDetails && !rr.wareHouseDetails.length) {
        rr.wareHouseDetails = [...productObj.wareHouseDetails];
      }
      if (rr?.attachments) {
        setAttachmentFile([...productDetails?.attachments] || []);
      }
      setProductForm(rr);
    }
  }, [productDetails]);

  useEffect(() => {
    if (productDetailsStatus === "pending") {
      setLoader(true);
    }
    setLoader(false);
  }, [productDetailsStatus]);

  const getProductDetailsById = (productId) => {
    dispatch(getProductById(productId));
  };

  useEffect(() => {
    if (!countries.length) {
      dispatch(getAllCountries());
    }
  }, [userDetails?.Value?.employeeId]);

  const handleFormValidation = () => {
    let error = {};
    let isFormValid = true;
    let isQuantityValid = true;
    if (!productForm.productName) {
      error.productName = "This is required.";
      isFormValid = false;
    }
    if (!productForm.productDescription) {
      error.productDescription = "This is required.";
      isFormValid = false;
    }
    if (!productForm.productSku) {
      error.productSku = "This is required.";
      isFormValid = false;
    }
    if (Number(id) === 0 && !image.length) {
      error.image = "Select product image.";
      isFormValid = false;
    }

    if (productForm?.wareHouseDetails.length > 0) {
      let findQuantityValid = productForm?.wareHouseDetails.filter(
        (item, index) => item.quantity === 0
      );
      if (findQuantityValid.length) {
        isQuantityValid = false;
      }
    }
    setErrors(error);
    if (!isFormValid) {
      toast.error("Validation failed");
      return;
    } else if (!isQuantityValid) {
      toast.error("Enter product quantity for each warehouse locations.");
      return;
    }

    return isFormValid;
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    setProductForm({ ...productForm, [name]: value.trim() });
  };

  const handleFormValue = (e) => {
    const { name, value } = e.target;
    setProductForm({ ...productForm, [name]: value });
    setErrors({ ...error, [name]: "" });
    setIsFormEdited(true);
  };

  const handleDateChange = (date, field) => {
    setProductForm({ ...productForm, [field]: date });
  };

  const uploadImages = async (id, type) => {
    let uploadData = new FormData();
    uploadData.append("id", id);
    uploadData = appendFilesToUploadData(uploadData, image, "images");
    let uploadMediaRes = await Promise.resolve(
      dispatch(uploadProductMedia(uploadData))
    );
    if (uploadMediaRes.data && uploadMediaRes.data.Status === 200) {
      toast.success(`Product ${type} successfully`);
      navigate("/inventory");
      setLoader(false);
    }
  };

  const handleUpdateProduct = () => {
    if (image.length > 0) {
      uploadImages(id, "updated");
    } else {
      toast.success(`Product Updated successfully`);
      navigate("/inventory");
      setLoader(false);
    }
  };

  const handleImagesLogic = (productFormObj) => {
    let filterEditedImages = productFormObj.images.filter(
      (item) => item.isEditable
    );
    if (filterEditedImages.length) {
      productFormObj.imageActions = filterEditedImages;
    }
    delete productFormObj.images;
    return productFormObj;
  };
  /* istanbul ignore next */
  const handleCreateProduct = async (productFormObj) => {
    let createProductRes = await Promise.resolve(
      dispatch(createInventoryProduct(productFormObj))
    );
    if (createProductRes.data && createProductRes.data.Status === 200) {
      handleUploadAttachments(createProductRes.data.Entity);
      uploadImages(createProductRes.data.Entity, "has been added");
    } else {
      toast.error(createProductRes.data.Message.AppStatusDescription);
      setLoader(false);
    }
  };

  const handleDeleteWareHouse = () => {
    if (deletedWareHouses.length) {
      let data = {
        inventoryIds: [...deletedWareHouses],
        productId: Number(id),
        modifiedBy: 0
      }
      dispatch(deleteWarehouseLocation(data));
    }
  }

  const handleSaveProduct = async () => {
    /* istanbul ignore next */
    if (handleFormValidation()) {
      let productFormObj = JSON.parse(JSON.stringify(productForm));
      productFormObj.quantity = Number(productFormObj.quantity);
      productFormObj.costPrice = Number(productFormObj.costPrice);
      productFormObj.sellingPrice = Number(productFormObj.sellingPrice);
      productFormObj.inventoryProductCondition_Id = Number(
        productFormObj.inventoryProductCondition_Id
      );
      productFormObj.unit = Number(productFormObj.unit);
      productFormObj.length = Number(productFormObj.length);
      productFormObj.width = Number(productFormObj.width);
      productFormObj.height = Number(productFormObj.height);
      productFormObj.consignmentTypeId = productFormObj.consignmentTypeId
        ? productFormObj.consignmentTypeId
        : 3;
      productFormObj.attachmentActions = attachmentFile.filter((item) => item.id && item.isDeleted);
      productFormObj.weight = Number(productFormObj.weight);
      productFormObj.ounces = Number(productFormObj.ounces);
      productFormObj.wareHouseDetails.map(function (item) {
        delete item.address;
        delete item.bayList;
        delete item.areaList;
        delete item.levelList;
        delete item.positionList;
        delete item.rowList;
        return item;
      });

      setIsFormEdited(false);
      setLoader(true);
      dispatch(InventoryProductBackDetails());
      if (Number(id)) {
        let payloadData = handleImagesLogic(productFormObj);
        let saveResponse = await Promise.resolve(
          dispatch(updateInventoryProduct(payloadData))
        );
        if (saveResponse.data && saveResponse.data.Status === 200) {
          handleUploadAttachments(id);
          handleUpdateProduct();
          handleDeleteWareHouse();
        } else {
          toast.error(saveResponse.data.Message.AppStatusDescription);
        }
        setLoader(false);
      } else {
        handleCreateProduct(productFormObj);
      }
    }
  };

  const handleEditable = () => {
    setIsEditable(true);
  };

  const handleImageUpload = (e) => {
    let files = [...e.target.files];
    try {
      if (files.length > 0 && files.length <= 5) {
        let filterFiles = files.filter((file, index) => {
          const extension = file.name.split(".").slice(-1).pop();
          const fileSize = Math.round(file.size / 1024);
          if (
            !allowedExtensions.includes(
              extension ? extension.toLowerCase() : ""
            )
          ) {
            files.splice(index, 1);
            toast.error("Unsupported File format", 1000);
            return false;
          } else if (fileSize > 1024) {
            files.splice(index, 1);
            e.target.value = "";
            toast.info("Each picture size should be less than 1MB", 1000);
            return false;
          }
          return file;
        });

        setErrors({ ...error, image: "" });
        setImage([...image, ...filterFiles]);
      } else if (files.length > 5) {
        toast.info("Only 5 images can be upload at one time", 1000);
        e.target.value = "";
      } else {
        e.target.value = "";
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteImage = (index) => {
    let images = [...image];
    images.splice(index, 1);
    setImage(images);
  };

  const handleDeleteDoc = (item, index) => {
    let files = [...attachmentFile];
    if (item.id) {
      files[index].isDeleted = true;
    } else {
      files.splice(index, 1);
    }
    setAttachmentFile(files);
  };

  const handleBack = (type = false) => {
    if (isFormEdited && !type) {
      setShowUnsavedDialog(true);
      return;
    }
    navigate("/inventory");
  };

  const removeWarehouse = (index) => {
    let data = { ...productForm };
    if (Number(id) > 0) {
      let deletedWareHouseList = [...deletedWareHouses];
      deletedWareHouseList.push(data.wareHouseDetails[index].id)
      setDeletedWareHouses(deletedWareHouseList);
    }
    data.wareHouseDetails.splice(index, 1);
    setProductForm(data);
  };

  const addWarehouse = () => {
    let data = { ...productForm };
    data.wareHouseDetails.push({ wareHouseName: "test", quantity: 1 });
    setProductForm(data);
  };

  useEffect(() => {
    productForm?.wareHouseDetails?.forEach((warehouse, index) => {
      if (warehouse?.warehouseId) {
        let warehouseListData = warehouseList?.listWarehouse?.filter(
          (item) => item.id === Number(warehouse?.warehouseId)
        );
        let updatedWareHouseDetails = [...productForm?.wareHouseDetails];
        if (warehouseListData?.length > 0) {
          updatedWareHouseDetails[index].address = warehouseListData[0];
        }
        setProductForm((prevState) => ({
          ...prevState,
          wareHouseDetails: updatedWareHouseDetails,
        }));
      }
    });
  }, [warehouseList?.listWarehouse]);

  const handleWarehouse = (e, index, isNumber = false) => {
    const { value, name } = e.target;
    let data = _.cloneDeep(productForm);
    data.wareHouseDetails[index][name] = isNumber ? Number(value) : value;
    if (name === "warehouseId") {
      let warehouseListData = warehouseList.listWarehouse.filter(
        (item) => item.id === Number(value)
      );
      data.wareHouseDetails[index].address = warehouseListData[0];
      data.wareHouseDetails[index].areaId = 0;
      data.wareHouseDetails[index].rowId = 0;
      data.wareHouseDetails[index].bayId = 0;
      data.wareHouseDetails[index].levelId = 0;
      data.wareHouseDetails[index].positionId = 0;
    }
    if (name === 'itemExpiryDate') {
      let dateSelected = moment(value).utc(true).format();
      data.wareHouseDetails[index][name] = dateSelected;
    }
    setProductForm(data);
  };

  const updateWareHouseDetails = (index, name, list) => {
    let data = { ...productForm };
    data.wareHouseDetails[index][name] = list;
    setProductForm(data);
  };

  const handleUploadAttachments = async (id) => {
    let findUploadedFiles = attachmentFile.filter((attachment) => !attachment.id);
    if (findUploadedFiles.length) {
      let uploadData = new FormData();
      uploadData.append("id", id);
      uploadData = appendFilesToUploadData(uploadData, findUploadedFiles, "Attachments");
      let saveResponse = await Promise.resolve(dispatch(updateAttachmentsByProductsId(uploadData)));
      if (saveResponse.data && saveResponse.data.Status !== 200) {
        console.error(saveResponse.data.Entity);
      }
    }
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          {id === "0" && "Create Product"}{" "}
          {id !== "0" && isEditable && "Edit Product"}{" "}
          {id !== "0" && !isEditable && "View Product"}
          <div className="top_right_button_container">
            <button
              className="btn cus-seconday-bg-btn top-btn"
              disabled={loader || !isEditable}
              onClick={handleSaveProduct}
            >
              {Number(id) ? "Save" : "Create"}
            </button>
            {Number(id) > 0 && (
              <button
                className="btn cus-seconday-bg-btn top-btn"
                disabled={loader || isEditable}
                onClick={handleEditable}
              >
                Edit
              </button>
            )}
            <button
              className="btn cus-primary-transparent-btn"
              onClick={() => handleBack(false)}
            >
              Back
            </button>
          </div>
        </div>
        {loader && <LoaderSpinner />}

        <div className="cus-page-content-sec">
          <div className="incomingAccordion accordion">
            <div className="row">
              <div className={Number(id) > 0 ? "col-9" : "col-12"}>
                <Accordion
                  defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                  alwaysOpen={true}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Details{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row addressForm addressForm">
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            Product SKU{" "}
                            <span className="smallTxt">
                              (Stock Keeping Unit)
                            </span>{" "}
                            <span className="error">*</span>
                          </label>
                          <input
                            className={`form-control productSku ${error.productSku && "errorField"
                              }`}
                            type={"text"}
                            name="productSku"
                            value={productForm.productSku}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                            disabled={!isEditable}
                          />
                          <div className="error">{error.productSku}</div>
                        </div>
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            Product Name <span className="error">*</span>
                          </label>
                          <input
                            className={`form-control productName ${error.productName && "errorField"
                              }`}
                            type={"text"}
                            name="productName"
                            value={productForm.productName}
                            disabled={!isEditable}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                          />
                          <div className="error">{error.productName}</div>
                        </div>

                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            Product Description <span className="error">*</span>
                          </label>
                          <input
                            className={`form-control productDescription ${error.productDescription && "errorField"
                              }`}
                            type={"text"}
                            name="productDescription"
                            value={productForm.productDescription}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                            disabled={!isEditable}
                          />
                          <div className="error">
                            {error.productDescription}
                          </div>
                        </div>
                      </div>
                      <div className="row addressForm addressForm">
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>Product Id</label>
                          <input
                            className={`form-control`}
                            type={"text"}
                            name="externalProductId"
                            value={productForm.externalProductId}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                            disabled={!isEditable}
                          />
                        </div>
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            Product Type <span className="error">*</span>
                          </label>
                          <select
                            value={productForm.consignmentTypeId}
                            className={`form-control consignmentTypeId`}
                            onChange={handleFormValue}
                            name="consignmentTypeId"
                            disabled={!isEditable}
                          >
                            {consignmentTypes?.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            Upload Product Images{" "}
                            <span className="error">*</span>
                          </label>
                          <div className="fileUpload inventory">
                            <button
                              disabled={!isEditable}
                              type="button"
                              className="btn cus-seconday-bg-btn"
                            >
                              Upload images
                            </button>
                            <input
                              className="hiddenFileField"
                              onChange={handleImageUpload}
                              disabled={!isEditable}
                              type="file"
                              id="customFile"
                              multiple
                            />
                            <div className="error">{error.image}</div>
                          </div>
                          <div>
                            {image.length > 0 &&
                              image.map((item, index) => (
                                <div className="selectedFile mr-2" key={item?.id}>
                                  <div className="fileName">{item.name}</div>
                                  <button
                                    className="deleteImg"
                                    onClick={() => handleDeleteImage(index)}
                                  >
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>Upload Attachments</label>
                          <div className="fileUpload inventory">
                            <button
                              disabled={!isEditable}
                              type="button"
                              className="btn cus-seconday-bg-btn"
                            >
                              Upload Documents
                            </button>
                            <input
                              className="hiddenFileField"
                              onChange={(e) => {
                                handleDocsUpload(e, toast, error, setErrors, setAttachmentFile, attachmentFile);
                              }}
                              disabled={!isEditable}
                              type="file"
                              id="customFile"
                              multiple
                            />
                          </div>
                          <div>
                            {attachmentFile.length > 0 &&
                              attachmentFile.map((item, index) => (
                                <>
                                  {!item.isDeleted && (
                                    <div className="selectedFile mr-2" key={item?.id}>
                                      <div className="fileName">
                                        {item.fileUrl ? (
                                          <a
                                            href={item.fileUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {item.name}
                                          </a>
                                        ) : (
                                          <>{item.name}</>
                                        )}
                                      </div>
                                      <button
                                        className="deleteFile"
                                        onClick={() =>
                                          handleDeleteDoc(item, index)
                                        }
                                        disabled={!isEditable}
                                      >
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                      </button>
                                    </div>
                                  )}
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Dimensions{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row mb-15">
                        <div className="col col-lg-12 col-md-12 col-sm-1">
                          <div className="row">
                            <div className="col-2">
                              <label>Weight</label>
                              <input
                                className="form-control weight"
                                type="number"
                                maxLength={10}
                                min={0}
                                name="weight"
                                value={productForm.weight}
                                onChange={handleFormValue}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                              />
                            </div>
                            <div className="col-2">
                              <label>Weight Unit</label>
                              <select
                                className="form-control"
                                onChange={handleFormValue}
                                name="weightUnit"
                                value={productForm.weightUnit}
                                disabled={!isEditable}
                              >
                                <option>Select</option>
                                {weightUnitList.length > 0 &&
                                  weightUnitList.map((weight) => (
                                    <option key={weight.id} value={weight.id}>
                                      {weight.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-2">
                              <label>Length</label>
                              <input
                                className="form-control length"
                                type="number"
                                maxLength={10}
                                min={0}
                                name="length"
                                value={productForm.length}
                                onChange={handleFormValue}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                              />
                            </div>
                            <div className="col-2">
                              <label>Width</label>
                              <input
                                className="form-control width"
                                type="number"
                                maxLength={10}
                                min={0}
                                name="width"
                                value={productForm.width}
                                onChange={handleFormValue}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                              />
                            </div>
                            <div className="col-2">
                              <label>Height</label>
                              <input
                                className="form-control height"
                                type="number"
                                maxLength={10}
                                min={0}
                                name="height"
                                value={productForm.height}
                                onChange={handleFormValue}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                              />
                            </div>
                            <div className="col-2">
                              <label>Dimension Unit</label>
                              <select
                                className="form-control"
                                onChange={handleFormValue}
                                name="DimensionUnit"
                                value={productForm.DimensionUnit}
                                disabled={!isEditable}
                              >
                                <option>Select</option>
                                {dimensionUnitList.length > 0 &&
                                  dimensionUnitList.map((dimension) => (
                                    <option
                                      key={dimension.id}
                                      value={dimension.id}
                                    >
                                      {dimension.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row addressForm addressForm"></div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Warehouse Location
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </Accordion.Header>
                    <Accordion.Body>
                      {productForm?.wareHouseDetails &&
                        productForm?.wareHouseDetails.length > 0 &&
                        productForm?.wareHouseDetails.map((item, index) => (
                          <div key={item.id}>
                            <WarehouseDetails
                              item={item}
                              handleFormValue={handleFormValue}
                              isEditable={isEditable}
                              handleInputBlur={handleInputBlur}
                              addWarehouse={addWarehouse}
                              index={index}
                              removeWarehouse={removeWarehouse}
                              productForm={productForm}
                              setProductForm={setProductForm}
                              handleWarehouse={handleWarehouse}
                              updateWareHouseDetails={updateWareHouseDetails}
                              error={error}
                              userDetails={userDetails}
                            />
                          </div>
                        ))}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Technicals{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row addressForm addressForm">
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            Product UPC{" "}
                            <span className="smallTxt">
                              (Universal Product Code)
                            </span>
                          </label>
                          <input
                            className="form-control"
                            type={"text"}
                            name="productUpc"
                            value={productForm.productUpc}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                            disabled={!isEditable}
                          />
                        </div>

                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            EAN{" "}
                            <span className="smallTxt">
                              (International Article Number)
                            </span>
                          </label>
                          <input
                            className={`form-control`}
                            type={"text"}
                            name="ean"
                            value={productForm.ean}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                            disabled={!isEditable}
                          />
                        </div>
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            MPN{" "}
                            <span className="smallTxt">
                              (Manufacturer Part Number)
                            </span>
                          </label>
                          <input
                            className="form-control"
                            type={"text"}
                            name="mpn"
                            value={productForm.mpn}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                            disabled={!isEditable}
                          />
                        </div>
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            ISBN{" "}
                            <span className="smallTxt">
                              (International Standard Book Number)
                            </span>
                          </label>
                          <input
                            className={`form-control`}
                            type={"text"}
                            name="isbn"
                            value={productForm.isbn}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                            disabled={!isEditable}
                          />
                        </div>

                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>
                            HSN{" "}
                            <span className="smallTxt">
                              (Harmonised System of Nomenclature)
                            </span>
                          </label>
                          <input
                            className={`form-control`}
                            type={"text"}
                            name="hsn"
                            value={productForm.hsn}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                            disabled={!isEditable}
                          />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Miscellaneous{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row addressForm addressForm">
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>Selling Price ($)</label>
                          <input
                            className={`form-control sellingPrice`}
                            type={"number"}
                            name="sellingPrice"
                            value={productForm.sellingPrice}
                            onChange={handleFormValue}
                            onBlur={handleInputBlur}
                            min={0}
                            disabled={!isEditable}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                          />
                        </div>
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>Cost Price ($)</label>
                          <input
                            className={`form-control costPrice`}
                            type={"number"}
                            min={0}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            name="costPrice"
                            value={productForm.costPrice}
                            onChange={handleFormValue}
                            disabled={!isEditable}
                            onBlur={handleInputBlur}
                          />
                        </div>
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <label>Condition</label>
                          <input
                            className={`form-control condition`}
                            type={"text"}
                            name="condition"
                            value={productForm.condition}
                            onChange={handleFormValue}
                            disabled={!isEditable}
                            onBlur={handleInputBlur}
                          />
                        </div>

                        <div className="col col-lg-12 col-sm-12">
                          <label>Comments</label>
                          <input
                            className={`form-control`}
                            type={"text"}
                            name="comments"
                            value={productForm.comments}
                            onChange={handleFormValue}
                            disabled={!isEditable}
                            onBlur={handleInputBlur}
                          />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Others{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row addressForm addressForm">
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <div className="row">
                            <div className="col-4">
                              <input
                                className={`form-control productSku`}
                                type={"text"}
                                name="option1"
                                value={productForm.option1}
                                onChange={handleFormValue}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                                placeholder="Option 1"
                              />
                            </div>
                            <div className="col-8">
                              <input
                                className={`form-control productSku`}
                                type={"text"}
                                name="name1"
                                value={productForm.name1}
                                onChange={handleFormValue}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                                placeholder="Option 1 Value"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <div className="row">
                            <div className="col-4">
                              <input
                                className={`form-control productSku`}
                                type={"text"}
                                name="option2"
                                value={productForm.option2}
                                onChange={handleFormValue}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                                placeholder="Option 2"
                              />
                            </div>
                            <div className="col-8">
                              <input
                                className={`form-control productSku`}
                                type={"text"}
                                name="name2"
                                value={productForm.name2}
                                onChange={handleFormValue}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                                placeholder="Option 2 Value"
                              />
                            </div>
                          </div>
                          <div className="error">{error.productSku}</div>
                        </div>
                        <div className="col col-lg-4 col-md-4 col-md-2 col-sm-1">
                          <div className="row">
                            <div className="col-4">
                              <input
                                className={`form-control productSku`}
                                type={"text"}
                                name="option3"
                                value={productForm.option3}
                                onChange={handleFormValue}
                                onBlur={handleInputBlur}
                                disabled={!isEditable}
                                placeholder="Option 3"
                              />
                            </div>
                            <div className="col-8">
                              <DatePicker
                                className="form-control itemExpiryDate"
                                showIcon
                                autoComplete="one-time-code"
                                dateFormat="MM-dd-yyyy"
                                selected={
                                  productForm?.name3
                                    ? new Date(productForm?.name3)
                                    : ""
                                }
                                name="name3"
                                onChange={(date) =>
                                  handleDateChange(date, "name3")
                                }
                                disabled={!isEditable}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              {Number(id) > 0 && (
                <ProductImageSlider
                  productForm={productForm}
                  setProductForm={setProductForm}
                  isEditable={isEditable}
                  userDetails={userDetails}
                  productFormCopy={productFormCopy}
                  setProductFormCopy={setProductFormCopy}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <AlertDialogModal
        modalShow={showUnsavedDialog}
        handleCancelDeleteAlert={() => setShowUnsavedDialog(false)}
        modalClass={"modalDeleteWrapper"}
        messageText="Are you sure you want to cancel?"
        handleYesDeleteAlert={() => handleBack(true)}
        headingText={"ALERT"}
      />
    </main>
  );
};

export default ProductInventory;
