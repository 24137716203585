import React, { useState } from 'react';
import ImageSlider from '../../incoming/ImageSlider';
import InLargeImageSlider from '../../modal/InlargeImageSlider';
import noImage from "../../../assets/No-Image-Placeholder.png";

const ProductImageSlider = (props) => {
    const { setProductForm, productForm, isEditable } = props;
    const [showLargeSliderModal, setShowLargeSliderModal] = useState(false);

    const handleMarkPrimary = (e, item) => {
        let formDataCopyObj = { ...productForm };
        let updateImage = formDataCopyObj.images.map((box) => {
            return {
              ...box,
              isPrimary: box.id === item.id,
              isEditable: box.isDeleted ? true : box.id === item.id,
            };
        });
        formDataCopyObj.images = updateImage;
        setProductForm(formDataCopyObj);
    };

    const handleSliderDeleteImage = (e, item) => {
        let formDataObj = { ...productForm }
        let updateImageToCopyObj = formDataObj.images.map((box) => {
            return {
                ...box,
                isDeleted:  box.isPrimary ? false : (box.isEditable || (box.id === item.id)),
                isEditable: box.isEditable || (box.id === item.id),
            };
        });
        formDataObj.images = updateImageToCopyObj;
        setProductForm(formDataObj);
    };

    return (
        <div className='col-3'>
            <p><b>Images</b></p>
            {productForm?.images?.length > 0 ? (
                <ImageSlider
                    isView={!isEditable}
                    handleMarkPrimary={handleMarkPrimary}
                    images={productForm?.images.filter(
                        (item) => !item.isDeleted
                    )}
                    handleDeleteImage={handleSliderDeleteImage}
                    isUserAdmin={true}
                    setShowLargeSliderModal={setShowLargeSliderModal}
                    showLargeSliderModal={showLargeSliderModal}
                />
            ) : (
                <img src={noImage} alt="" />
            )}

            {showLargeSliderModal && (
                <InLargeImageSlider
                    popupTitle={productForm.productName}
                    view={!isEditable}
                    handleMarkPrimary={handleMarkPrimary}
                    images={productForm?.images.filter(
                        (item) => !item.isDeleted
                    )}
                    handleDeleteImage={handleSliderDeleteImage}
                    isUserAdmin={true}
                    showModal={showLargeSliderModal}
                    setShowLargeSliderModal={setShowLargeSliderModal}
                />
            )}
        </div>
    );
}

export default ProductImageSlider;