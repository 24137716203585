import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getPermissionsByEmailId, sellerListBackDetails, userListBackDetails } from "../../store/actions";
import { Tooltip as WmsTooltip } from "react-tooltip";
import management from "../../../src/assets/management.png";
import incomingIcon from "../../../src/assets/incoming-icon.png";
import barcodeIcon from "../../../src/assets/barcode-icon.png";
import { inboundBackDetails, InventoryProductBackDetails, outboundBackDetails } from "../../store/actions/employee";
import { packageBackDetails, packagesYearBackDetails, productYearBackDetails, productBackDetails } from "../../store/actions/incoming";
import { companyListBackDetails } from "../../store/actions/rateCard";
import { checkPermission } from "../../config/utils";
import { accountsManagement, addressBook, barCode, batchModule, featureModule, featureSubmodule, historyModule, inventoryManagement, orderManagement, packages, products, returnsManagement, scanFormModule, settings, shipmentModule, SUBF_COMPANY, SUBF_INBOUND, SUBF_LISTING, SUBF_OUTBOUND, SUBF_SELLER, SUBF_USER } from "../../constant";

const Sidebar = ({ sidebarOpen }, props) => {
    const [expanded, setExpanded] = useState(false);
    const myRef = useRef();
    const dispatch = useDispatch();
    const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);
    const handleClickOutside = (e) => {
        if (!myRef.current.contains(e.target)) {
            setExpanded(false);
        }
    };

    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    });

    useEffect(() => {
        dispatch(getPermissionsByEmailId());
    }, []);

    return (
        <div
            ref={myRef}
            className={sidebarOpen ? "sidebar" : "leftSideBar"}
            id="sidebar"
        >
            <SideNav
                onSelect={(selected) => {
                    setExpanded(false);
                }}
                onToggle={(expand) => setExpanded(expand)}
                expanded={expanded}
            >
                <SideNav.Toggle />
                {employeePermissions?.length > 0 && (
                    <SideNav.Nav>
                        <NavItem eventKey="Dashboard" active={path === "/"}>
                            <NavIcon id="home">
                                <Link to="/">
                                    <i
                                        className="fa fa-fw fa fa-tachometer"
                                        style={{ fontSize: "1.4em" }}
                                    />
                                </Link>
                            </NavIcon>
                            <WmsTooltip anchorId={"home"} place="right" content={"Home"} />
                            <NavText>
                                <Link to="/">Home</Link>
                            </NavText>
                        </NavItem>

                        {checkPermission(orderManagement, '', '', featureModule, employeePermissions) && (
                            <NavItem
                                eventKey="shipment"
                                active={
                                    path === "/scan-form" ||
                                    path === "/shipment" ||
                                    path === "/BatchShipping" ||
                                    path === "/trackhistory"
                                }
                            >
                                <NavIcon id="shipmentIcon">
                                    <i className="fa fa-cube" style={{ fontSize: "1.4em" }} />
                                </NavIcon>
                                {checkPermission(orderManagement, shipmentModule, '', featureSubmodule, employeePermissions) && (
                                    <NavItem
                                        eventKey="shipment"
                                        className="menuItem SubMenu"
                                        active={path === "/shipment"}
                                    >
                                        <NavIcon id="batchIcon">
                                            <Link to="shipment">
                                                <i
                                                    className="fa fa-fw fa-solid fa-cube"
                                                    style={{ fontSize: "1.4em" }}
                                                />
                                            </Link>
                                        </NavIcon>
                                        <NavText>
                                            <Link to="shipment">Shipment</Link>
                                        </NavText>
                                    </NavItem>
                                )}

                                {checkPermission(orderManagement, batchModule, '', featureSubmodule, employeePermissions) && (
                                    <NavItem
                                        eventKey="batchShipping"
                                        className="menuItem SubMenu"
                                        active={path === "/BatchShipping"}
                                    >
                                        <NavIcon id="batchIcon">
                                            <Link to="BatchShipping">
                                                <i>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="20"
                                                        fill="currentColor"
                                                        className="bi bi-back"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z" />
                                                    </svg>
                                                </i>
                                            </Link>
                                        </NavIcon>
                                        <NavText>
                                            <Link to="BatchShipping">Batch Shipping</Link>
                                        </NavText>
                                    </NavItem>
                                )}

                                {checkPermission(orderManagement, historyModule, '', featureSubmodule, employeePermissions) && (
                                    <NavItem
                                        eventKey="trackhistory"
                                        className="menuItem SubMenu"
                                        active={path === "/trackhistory"}
                                    >
                                        <NavIcon>
                                            <Link to="trackhistory">
                                                <i
                                                    className="fa fa-history"
                                                    style={{ fontSize: "1.4em" }}
                                                />
                                            </Link>
                                        </NavIcon>
                                        <NavText>
                                            <Link to="trackhistory">History/Track</Link>
                                        </NavText>
                                    </NavItem>
                                )}
                                {checkPermission(orderManagement, scanFormModule, '', featureSubmodule, employeePermissions) && (
                                    <NavItem
                                        eventKey="ScanForms"
                                        className="menuItem SubMenu"
                                        active={path === "/scan-form"}
                                    >
                                        <NavIcon>
                                            <Link to="trackhistory">
                                                <i className="fa fa-qrcode" style={{ fontSize: "1.4em" }} />
                                            </Link>
                                        </NavIcon>
                                        <NavText>
                                            <Link to="scan-form">Scan Forms</Link>
                                        </NavText>
                                    </NavItem>
                                )}
                                <NavText>Orders Management</NavText>
                            </NavItem>
                        )}

                        {checkPermission(addressBook, '', '', featureModule, employeePermissions) && (
                            <NavItem eventKey="address-book" active={path === "/address-book"}>
                                <NavIcon id="address-book">
                                    <Link to="/address-book">
                                        <i
                                            className="fa fa-address-book-o"
                                            style={{ fontSize: "1.4em" }}
                                            aria-hidden="true"
                                        />
                                    </Link>
                                </NavIcon>
                                <WmsTooltip
                                    anchorId={"address-book"}
                                    place="right"
                                    content={"Address Book"}
                                />
                                <NavText>
                                    <Link to="/address-book">Address Book</Link>
                                </NavText>
                            </NavItem>
                        )}

                        {checkPermission(returnsManagement, '', '', featureModule, employeePermissions) && (
                            <NavItem
                                eventKey="Incoming"
                                active={
                                    path.includes("incoming-packages") || path.includes("incoming-product")
                                }
                            >
                                <NavIcon id="companyIcon">
                                    <img src={incomingIcon} style={{ width: "26px" }} alt="" />
                                </NavIcon>
                                {checkPermission(returnsManagement, packages, '', featureSubmodule, employeePermissions) && (
                                <NavItem
                                    eventKey="/incoming-packages"
                                    className="menuItem SubMenu"
                                    active={path === "/incoming-packages"}
                                >
                                    <NavIcon id="incomingIcon">
                                        <Link to="/incoming-packages">
                                            <i
                                                className="fa fa-archive"
                                                style={{ fontSize: "1.4em" }}
                                                aria-hidden="true"
                                            />
                                        </Link>
                                    </NavIcon>
                                    <WmsTooltip
                                        anchorId={"incomingIcon"}
                                        place="right"
                                        content={"Packages"}
                                    />
                                    <NavText>
                                        <span className="spanWrapper" onClick={() => {
                                            dispatch(packagesYearBackDetails(0));
                                            dispatch(packageBackDetails({}));
                                        }}>
                                            <Link to="incoming-packages">Packages</Link>
                                        </span>
                                    </NavText>
                                </NavItem>
                                )}
                                {checkPermission(returnsManagement, products, '', featureSubmodule, employeePermissions) && (
                                <NavItem
                                    eventKey="/incoming-packages"
                                    className="menuItem SubMenu"
                                    active={path === "/incoming-products"}
                                >
                                    <NavIcon id="productIcon">
                                        <Link to="/incoming-products">
                                            <i
                                                className="fa fa-cubes"
                                                style={{ fontSize: "1.4em" }}
                                                aria-hidden="true"
                                            />
                                        </Link>
                                    </NavIcon>
                                    <WmsTooltip
                                        anchorId={"productIcon"}
                                        place="right"
                                        content={"Product"}
                                    />
                                    <NavText>
                                        <span className="spanWrapper" onClick={() => {
                                            dispatch(productYearBackDetails(0));
                                            dispatch(productBackDetails({}));
                                        }}>
                                            <Link to="/incoming-products">Products</Link>
                                        </span>
                                    </NavText>
                                </NavItem>
                                )}

                                <NavText>Returns Management</NavText>
                            </NavItem>
                        )}

                        {checkPermission(accountsManagement, '', '', featureModule, employeePermissions) && (
                            <NavItem
                                eventKey="companySeller"
                                active={
                                    path === "/CompanyManagement" || path === "/sellerManagement" || path.includes('editUser') || path.includes('editSeller') || path.includes('editCompany')
                                }
                            >
                                <NavIcon id="companyIcon2">
                                    {/* <Link to="shipment"> */}
                                    <img src={management} style={{ width: "26px" }} alt="" />
                                    {/* </Link> */}
                                </NavIcon>
                                {checkPermission(accountsManagement, SUBF_COMPANY, '', featureSubmodule, employeePermissions) && (
                                <NavItem
                                    eventKey="/CompanyManagement"
                                    className="menuItem SubMenu"
                                    active={path === "/CompanyManagement"}
                                >
                                    <NavIcon id="addCompany">
                                        <Link to="/CompanyManagement">
                                            <span>
                                                <i
                                                    className="fa fa-building-o"
                                                    style={{ fontSize: "1.4em" }}
                                                />
                                            </span>
                                        </Link>
                                    </NavIcon>
                                    <WmsTooltip
                                        anchorId={"addCompany"}
                                        place="right"
                                        content={"Company"}
                                    />
                                    <NavText>
                                        <span className="spanWrapper" onClick={() => dispatch(companyListBackDetails({}))}>
                                            <Link to="CompanyManagement">Company</Link>
                                        </span>
                                    </NavText>
                                </NavItem>
                                )}
                                {checkPermission(accountsManagement, SUBF_SELLER, '', featureSubmodule, employeePermissions) && (
                                <NavItem
                                    eventKey="/SellerManagement"
                                    className="menuItem SubMenu"
                                    active={path === "/SellerManagement"}
                                >
                                    <NavIcon id="addSeller">
                                        <Link to="/SellerManagement">
                                            <span>
                                                <i
                                                    className="fa fa-user-o"
                                                    style={{ fontSize: "1.4em" }}
                                                />
                                            </span>
                                        </Link>
                                    </NavIcon>
                                    <WmsTooltip
                                        anchorId={"addSeller"}
                                        place="right"
                                        content={"Seller"}
                                    />
                                    <NavText>
                                        <span className="spanWrapper" onClick={() => dispatch(sellerListBackDetails({}))}>
                                            <Link to="/SellerManagement"> Seller</Link>
                                        </span>
                                    </NavText>
                                </NavItem>
                                )}
                                {checkPermission(accountsManagement, SUBF_USER, '', featureSubmodule, employeePermissions) && (
                                <NavItem
                                    eventKey="shipment"
                                    className="menuItem SubMenu"
                                    active={path === "/userManagement"}
                                >
                                    <NavIcon id="batchIcon">
                                        <Link>
                                            <i className="fa fa-user-o" style={{ fontSize: "1.4em" }} />
                                        </Link>
                                    </NavIcon>
                                    <NavText>
                                        <span className="spanWrapper" onClick={() => dispatch(userListBackDetails({}))}>
                                            <Link to="/userManagement">User</Link>
                                        </span>
                                    </NavText>
                                </NavItem>
                                )}
                                <NavText>Account Management</NavText>
                            </NavItem>
                        )}

                        {checkPermission(inventoryManagement, '', '', featureModule, employeePermissions) && (
                            <NavItem
                                eventKey="inventoryManagement"
                                active={
                                    path === "/inventory"
                                }
                            >
                                <NavIcon id="companyIcon">
                                    <i
                                        className="fa fa-cubes"
                                        style={{ fontSize: "1.4em" }}
                                        aria-hidden="true"
                                    />
                                </NavIcon>
                                {checkPermission(inventoryManagement, SUBF_LISTING, '', featureSubmodule, employeePermissions) && (
                                <NavItem
                                    eventKey="/inventory"
                                    className="menuItem SubMenu"
                                    active={path.includes("inventory")}
                                >
                                    <NavIcon id="addCompany">
                                        <Link to="/inventory">
                                            <span>
                                                <i
                                                    className="fa fa-th-large"
                                                    style={{ fontSize: "1.4em" }}
                                                />
                                            </span>
                                        </Link>
                                    </NavIcon>
                                    <WmsTooltip
                                        anchorId={"addCompany"}
                                        place="right"
                                        content={"Company"}
                                    />
                                    <NavText>
                                        <span className="spanWrapper" onClick={() => dispatch(InventoryProductBackDetails({}))}>
                                            <Link to="inventory">Listing</Link>
                                        </span>
                                    </NavText>
                                </NavItem>
                                )}
                                {checkPermission(inventoryManagement, SUBF_INBOUND, '', featureSubmodule, employeePermissions) && (
                                <NavItem
                                    eventKey="/inbound"
                                    className="menuItem SubMenu"
                                    active={path.includes("inbound")}
                                >
                                    <NavIcon id="addCompany">
                                        <Link to="/inbound">
                                            <span>
                                                <i
                                                    className="fa fa-archive"
                                                    style={{ fontSize: "1.4em" }}
                                                />
                                            </span>
                                        </Link>
                                    </NavIcon>
                                    <WmsTooltip
                                        anchorId={"addCompany"}
                                        place="right"
                                        content={"Company"}
                                    />
                                    <NavText>
                                        <span className="spanWrapper" onClick={() => dispatch(inboundBackDetails({}))}>
                                            <Link to="inbound">Inbound</Link>
                                        </span>
                                    </NavText>
                                </NavItem>
                                )}
                                {checkPermission(inventoryManagement, SUBF_OUTBOUND, '', featureSubmodule, employeePermissions) && (
                                <NavItem
                                    eventKey="/outbound"
                                    className="menuItem SubMenu"
                                    active={path.includes("outbound")}
                                >
                                    <NavIcon id="addCompany">
                                        <Link to="/outbound">
                                            <span>
                                                <i
                                                    className="fa fa-outdent"
                                                    style={{ fontSize: "1.4em" }}
                                                />
                                            </span>
                                        </Link>
                                    </NavIcon>
                                    <WmsTooltip
                                        anchorId={"addCompany"}
                                        place="right"
                                        content={"Company"}
                                    />
                                    <NavText>
                                        <span className="spanWrapper" onClick={() => dispatch(outboundBackDetails({}))}>
                                            <Link to="outbound">Outbound</Link>
                                        </span>
                                    </NavText>
                                </NavItem>
                                )}
                                <NavText>Inventory Management</NavText>
                            </NavItem>
                        )}

                        {checkPermission(settings, '', '', featureModule, employeePermissions) && (
                            <>
                                <NavItem eventKey="/setting" active={path === "/setting"}>
                                    <NavIcon id="setupIcon">
                                        <Link to="/setting">
                                            <i
                                                className="fa fa-fw fa-regular fa-gear"
                                                style={{ fontSize: "1.4em" }}
                                                aria-hidden="true"
                                            />
                                        </Link>
                                    </NavIcon>
                                    <WmsTooltip
                                        anchorId={"setupIcon"}
                                        place="right"
                                        content={"Settings"}
                                    />
                                    <NavText>
                                        <Link to="setting">Settings</Link>
                                    </NavText>
                                </NavItem>
                            </>
                        )}

                        {checkPermission(barCode, '', '', featureModule, employeePermissions) && (
                            <NavItem eventKey="/barcode" active={path.includes("barcode")}>
                                <NavIcon id="barcodeIcon">
                                    <Link to="/barcode">
                                        <img
                                            src={barcodeIcon}
                                            style={{ height: "26px", width: "26px" }}
                                            alt=""
                                        />
                                    </Link>
                                </NavIcon>
                                <WmsTooltip
                                    anchorId={"barcodeIcon"}
                                    place="right"
                                    content={"Barcode"}
                                />
                                <NavText>
                                    <Link to="barcode">Barcode</Link>
                                </NavText>
                            </NavItem>
                        )}

                    </SideNav.Nav>
                )}
            </SideNav>
        </div >
    );
};

export default Sidebar;