import * as React from "react";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import delimeter from "../../assets/delimeter.svg";
import Trash from "../../assets/trashRed.svg";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getCsvDataAction,
  fileUpload,
  updateSkipDuplicate,
  getIntegrationTemplateUrl,
} from "../../store/actions";
import "./csvListUploadForm.css";
import _ from "lodash";
import { isUrl } from "../../config/utils";
import { Tooltip as WmsTooltip } from "react-tooltip";
import LoaderSpinner from "../common/Loader";

export function UploadDialog(props) {
  const [gridData, setGridData] = useState([]);
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [File, setFile] = useState();
  const [weightDimension, setWeightDimension] = useState();
  const [fileName, setFileName] = useState("");
  const [isSkipDuplicateCheck, setisSkipDuplicateCheck] = useState();
  const [isZoneCalculatorCheck, setisZoneCalculatorCheck] = useState(false);
  const [sampleTemplateUrl, setSampleTemplateUrl] = useState('');
  const [formData, setFormData] = useState({
    Name: "",
    CarrierId: "",
    BaseMasterId: 0
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(formData.csvTemp)) {
      fetchSampleTemplateUrl(formData.csvTemp);
    }
  }, [formData.csvTemp]);

  const fetchSampleTemplateUrl = async (integrationObj) => {
    let data = `integrationId=${integrationObj.value}&integrationName=${integrationObj.label}`
    let response = await Promise.resolve(dispatch(getIntegrationTemplateUrl(data)));
    if (response?.data?.Status === 200) {
      setSampleTemplateUrl(response?.data?.Entity);
    }
  }

  const createdBy = useSelector((state) => state?.auth?.user);
  const getGridData = async () => {
    var resp = await dispatch(getCsvDataAction(`?createdBy=${createdBy?.Value?.employeeId}&sellerId=${createdBy?.Value?.sellerId}&companyId=${createdBy?.Value?.companyId}&isAdmin=${createdBy?.Value?.isSuperAdmin}`));
    if (resp.data.Status === 200) {
      setGridData(Object.values(resp.data.EntityList).reverse());
    }
  };

  const onTrashClick = () => {
    setFileName(null);
  };

  const validateForm = () => {
    let isValid = true;
    let errorsObj = {};
    if (!formData.Id) {
      isValid = false;
      errorsObj.Id = 'Required field'
    }
    if (!weightDimension) {
      isValid = false;
      errorsObj.weightDimension = 'Select weight dimensions'
    }
    if (!fileName) {
      isValid = false;
      errorsObj.chooseFile = 'Choose file'
    }
    setErrors(errorsObj);
    return isValid;
  };

  const handleCsvForm = (item) => {
    setisSkipDuplicateCheck(item?.isSkipDuplicateCheck);
    setFormData({
      Name: item.label,
      IntegrationId: item?.value,
      Id: item?.value,
      CreatedBy: createdBy,
      IsHeaderRowToImport: item?.IsHeaderRowToImport,
      csvTemp: item
    });
    setErrors({
      ...errors,
      Id: ''
    });
  };

  const handleWeightDimension = (val) => {
    setWeightDimension(val);
    setErrors({
      ...errors,
      weightDimension: '',
    });
  };

  useEffect(() => {
    getGridData();
  }, [formData]);

  const handleUploadClick = async (event, Id) => {
    const fileNamechosen = event.target.files[0].name;
    setFile(event.target.files[0])
    setFileName(fileNamechosen);
  };

  const handleUploadFile = async () => {
    if (validateForm()) {
      setShowLoader(true);
      const fd = new FormData();
      fd.append("FromFile", File);
      fd.append("IntegrationId", formData?.IntegrationId);
      fd.append("CreatedBy", createdBy?.Value?.employeeId);
      fd.append("SellerId", createdBy?.Value?.sellerId);
      fd.append("CompanyId", createdBy?.Value?.companyId);
      fd.append("DimensionUnit", weightDimension?.DimensionUnit);
      fd.append("WeightUnit", weightDimension?.WeightUnit);
      fd.append("CompanySellerId", createdBy?.Value?.companySellerId);
      fd.append("Role", createdBy?.Value?.role);
      fd.append("ModifiedBy", createdBy?.Value?.employeeId);
      if (isZoneCalculatorCheck) {
        fd.append("CalculateZone", isZoneCalculatorCheck);
      }
      setErrors({
        ...errors,
        chooseFile: ''
      });
      let resp = await dispatch(fileUpload(fd));
      if (!isZoneCalculatorCheck) {
        if (resp.data.Status === 200) {
          toast.success(resp.data?.Entity);
          props.onHide();
          if (props.type && props.type === "shipment") {
            props.getPendingOrderNumbers();
          }
          props.parentCallRefresh && props.parentCallRefresh();
        } else {
          toast.error(resp.data?.Entity);
          props.onHide();
          props.parentCallRefresh && props.parentCallRefresh();
        }
      } else {
        if (resp.data.Status === 200) {
          const downloadUrl = resp.data?.Entity;
          if (isUrl(downloadUrl)) {
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success("File Downloaded Successfully");
            props.onHide();
          } else {
            toast.error(downloadUrl);
            props.onHide();
          }
          props.parentCallRefresh && props.parentCallRefresh();
        } else {
          props.parentCallRefresh && props.parentCallRefresh();
        }
        props.onHide();
      }
      setShowLoader(false);
      setFileName("");
      setisSkipDuplicateCheck();
      setFormData({});
    }
  };

  const handleCancelUpload = () => {
    setFileName("")
    setisSkipDuplicateCheck();
    setFormData({});
    props.onHide();
  };

  const handleAutGenerateOrder = async (event, autGenerateId) => {
    setisSkipDuplicateCheck(event);
    let url = `flagStatus=${event}&Id=${autGenerateId}`;
    let response = dispatch(updateSkipDuplicate(url));
    if (createdBy > 0) {
      var resp = await dispatch(getCsvDataAction(`?createdBy=${createdBy?.Value?.employeeId}&sellerId=${createdBy?.Value?.sellerId}&companyId=${createdBy?.Value?.companyId}&isAdmin=${createdBy?.Value?.isSuperAdmin}`));
      if (resp.data.Status === 200) {
        setGridData(Object.values(resp.data.EntityList));
      }
    }
    if (response.data && response.data.Status === 200) {
      toast.success("Saved Successfully");
    }
  };
  const handleZoneCalculator = async (e) => {
    setisZoneCalculatorCheck(e.target.checked)
  }
  return (
    <div>
      <Modal {...props} className="modalDeleteWrapper" size="lg">
        <Modal.Header closeButton>
          <div className="modal-main-div">
            <text className="modal-m-text"> Upload CSV {showLoader && <LoaderSpinner />}</text>
          </div>
        </Modal.Header>
        <Modal.Body className="selectSvcModal">
          <div className="row modal_input">
            <div className="col-4 formLabel csv_label">Select CSV Template</div>
            <div className="col-8">
              <div className={"select_react_Csv"}>
                <Select
                  options={
                    gridData &&
                    gridData?.map((data) => {
                      return {
                        label: data?.Name,
                        value: data?.Id,
                        IsAutGenerateOrderId: data?.IsAutGenerateOrderId,
                        IsHeaderRowToImport: data?.IsHeaderRowToImport,
                        isSkipDuplicateCheck: data?.isSkipDuplicateCheck,
                      };
                    })
                  }
                  onChange={(value) => handleCsvForm(value)}
                  value={formData?.csvTemp}
                  placeholder="Select template.."
                />
                <p className="error select-error">{errors.Id}</p>
              </div>
            </div>
          </div>
          <div className="row modal_input">
            <div className="col-4 formLabel csv_label">Skip Header Row</div>
            <div className="col-6">
              <div className="checkBoxWrapper">
                <Form.Check
                  checked={formData?.IsHeaderRowToImport ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="row modal_input">
            <div className="col-4 csv_label">Skip Duplicate Address Check</div>
            <div className="col-6">
              <div className="checkBoxWrapper">
                <input
                  type={"checkbox"}
                  id={"default-isSkipDuplicateCheck"}
                  checked={isSkipDuplicateCheck}
                  onChange={(e) =>
                    handleAutGenerateOrder(e.target.checked, formData?.Id)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row modal_input">
            <div className="col-4 csv_label">Enable Zone Calculation</div>
            <div className="col-6">
              <div className="checkBoxWrapper">
                <input
                  type={"checkbox"}
                  id={"isZoneCalculatorCheck"}
                  name="isZoneCalculatorCheck"
                  value={isZoneCalculatorCheck}
                  checked={isZoneCalculatorCheck}
                  onChange={handleZoneCalculator}
                />

                <i class="ml-2 fa fa-info-circle" aria-hidden="true" id="zoneCalculation"></i>
                <WmsTooltip
                  anchorId={'zoneCalculation'}
                  content="In case of an unmentioned sender address, the system will calculate the Zone based on the default address set for the logged-in user."
                  place="top"
                  className="zoneCalculation"
                />
              </div>
            </div>
          </div>
          <div className="row modal_input">
            <div className="col-4 formLabel csv_label">Delimiter</div>
            <div className="col-8">
              <img className="delimeter" src={delimeter} alt="" />
            </div>
          </div>
          <div className="row modal_input">
            <div className="col-4 formLabel csv_label">Unit Setting</div>
            <div className="col-8 unit-margin-level">
              <input
                type="radio"
                id="html1"
                name="fav_language"
                onChange={(e) =>
                  handleWeightDimension(JSON.parse(e.target.value))
                }
                value={JSON.stringify({
                  DimensionUnit: "CMS",
                  WeightUnit: "KG",
                })}
              />
              <label for="html1" className="unit-value">
                KG/CMS
              </label>
              <input
                type="radio"
                id="html2"
                name="fav_language"
                onChange={(e) =>
                  handleWeightDimension(JSON.parse(e.target.value))
                }
                value={JSON.stringify({
                  DimensionUnit: "INCH",
                  WeightUnit: "LBS",
                })}
              />
              <label for="html2" className="unit-value">
                LBS/INCH
              </label>
              <input
                type="radio"
                id="html3"
                name="fav_language"
                onChange={(e) =>
                  handleWeightDimension(JSON.parse(e.target.value))
                }
                value={JSON.stringify({
                  DimensionUnit: "INCH",
                  WeightUnit: "OZ",
                })}
              />
              <label for="html3" className="unit-value">
                OZ/INCH
              </label>
            </div>
            <p className="error error-dimension">{errors.weightDimension}</p>
          </div>
          <div className="row modal_input">
            <div className="col-8">
              {fileName ? (
                <div>
                  {fileName}
                  <span className="trash-icon">
                    {" "}
                    <img
                      className="trash-Img"
                      onClick={onTrashClick}
                      src={Trash}
                      alt=""
                    ></img>
                  </span>
                </div>
              ) : (
                <>
                  <button className="btn-input-csv">
                    <label className="btn-chooseFile" htmlFor={`actual-btn-6`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-upload"
                        viewBox="0 0 16 16"
                        cursor="pointer"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                      </svg>{" "}
                      &nbsp; Choose File{" "}
                    </label>
                  </button>
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    id={`actual-btn-6`}
                    onChange={(e) => {
                      handleUploadClick(e, formData?.Id);
                    }}
                    hidden
                  />
                </>
              )}
              <p className="error  file-upload">{errors.chooseFile}</p>
            </div>
          </div>
          {sampleTemplateUrl && (
            <div className="downLoad">
              <a rel="noreferrer" href={sampleTemplateUrl} target="_blank">Download Template</a>
            </div>
          )}
          <Row className="modaL_footer">
            <Col xs={6}></Col>
            <Col className="csv_Buttons" xs={6}>
              {!isZoneCalculatorCheck ? (
                <Button
                  className="btn cus-seconday-bg-btn"
                  onClick={() => handleUploadFile()}
                  disabled={showLoader}
                >
                  Upload
                </Button>
              ) : (
                <Button
                  className="btn cus-seconday-bg-btn"
                  onClick={() => handleUploadFile()}
                  disabled={showLoader}
                >
                  Download
                </Button>
              )}

              <Button
                className="btn cus-seconday-bg-btn marginLeft5"
                onClick={() => handleCancelUpload()}
                disabled={showLoader}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}
