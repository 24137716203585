import React, { useState, useEffect } from "react";
import { Button } from "bootstrap-4-react/lib/components";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import FilterProduct from "./filterProduct";
import eye_icon from "../../../assets/eye_icon.svg";
import edit_icon from "../../../assets/edit_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterProductsList,
  getProductConditionList,
  getProductWarehouseList,
  productBackDetails,
} from "../../../store/actions/incoming";
import BatchPagination from "../../batch/BatchPagination";
import { featureSubmodulePermission, numOfPage, permssionEdit, permssionView, products, returnsManagement } from "../../../constant";
import moment from "moment";
import LoaderSpinner from "../../common/Loader";
import { checkPermission, renderTableRow } from "../../../config/utils";
import ViewImage from "../../common/ViewImage";

const ProductList = (props) => {
  const navigate = useNavigate();
  const UserValue = useSelector((state) => state.auth.user);
  const productsList = useSelector(
    (state) => state.IncomingProductReducer?.productsList
  );
  const productsListPageInfo = useSelector(
    (state) => state.IncomingProductReducer?.productsListPageInfo
  );
  const productsListStatus = useSelector(
    (state) => state.IncomingProductReducer?.productsListStatus
  );
  const productBackDetailsObj = useSelector((state)=> state.IncomingReducer.productBackDetailsObj)

  const dispatch = useDispatch();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const landingSearch = location.state?.searchTxt;
  const [totalPages, setTotalPages] = useState(1);
  const trackingNumber = location.state?.trackingNumber;
  const packageDate = location?.state?.backDate;
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [showHideImageModal, setShowHideImageModal] = useState(false);
  const [sellerList, setSellerList] = useState([]);
  const [allSellers, setAllSellers] = useState([]);

  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    sortColumn: "",
    date: location?.state?.date,
    searchKeywords: "",
    sellerIdList: [],
    companyIdList: [],
    showFilter: false,
    searchTxt: '',
    sortOrder: 'DESCENDING',
    showSearch: false,
    companySellerId: 0,
    isSuperAdmin: UserValue?.Value?.isSuperAdmin,
    warehouseList: [],
    conditionList: [],
    processingDateTo:"",
    processingDateFrom:'',
  };

  const [filterValue, setFilterValue] = useState({});

  useEffect(() => {
    if (!_.isEmpty(productBackDetailsObj)) {
      setFilterValue({ ...filterValue, ...productBackDetailsObj });
    } else {
      setFilterValue({ ...dataLoad });
    }
  }, [])

  useEffect(() => {
    if (productsList && productsList.length) {
      setTotalPages(
        Math.ceil(productsListPageInfo?.TotalRecords / filterValue.pageSize)
      );
    }
  }, [productsList]);

  useEffect(() => {
    /* istanbul ignore next */
    if (!_.isEmpty(filterValue)) {
    if (location?.state?.date && moment(location?.state?.date).isValid()) {
      fetchFilterProductList();
    } else if (location.state?.trackingNumber) {
      fetchFilterProductList();
    } else if (location?.state?.searchTxt || location?.state?.searchTxt === "") {
      fetchFilterProductList("fromLandingPage");
    } else {
      navigate("/incoming-products");
    }}
  }, [filterValue.pageIndex, filterValue.pageSize, filterValue.sortColumn, filterValue.sortOrder]);

  useEffect(() => {
    if (productsListStatus === "pending") {
      setLoader(true);
    } else {
      /* istanbul next */
      setLoader(false);
    }
  }, [productsListStatus]);

  const fetchFilterProductList = (type) => {
    if (type === "fromLandingPage") {
      const data = {
        pageIndex: filterValue.pageIndex,
        pageSize: filterValue.pageSize,
        sortColumn: filterValue.sortColumn,
        sortOrder: filterValue.sortOrder,
        date: location?.state?.date,
        searchKeywords: landingSearch || '',
        warehouseList : _.map(filterValue.warehouseList, "id"),
        conditionList : _.map(filterValue.conditionList, "id"),
        userId: UserValue?.Value?.employeeId,
        companySellerId: UserValue?.Value?.companySellerId,
        isSuperAdmin: UserValue?.Value?.isSuperAdmin,
        sellerIdList: _.map(filterValue.sellerIdList, "id"),
        companyIdList: _.map(filterValue.companyIdList, "Id"),
        processingDateTo: null,
        processingDateFrom: null,
      };
      dispatch(getFilterProductsList(data));
    } else if (trackingNumber) {
      const data = {
        pageIndex: filterValue.pageIndex,
        pageSize: filterValue.pageSize,
        sortColumn: filterValue.sortColumn,
        sortOrder: filterValue.sortOrder,
        date: location?.state?.date,
        searchKeywords: filterValue.searchTxt,
        warehouseList : _.map(filterValue.warehouseList, "id"),
        conditionList : _.map(filterValue.conditionList, "id"),
        userId: UserValue?.Value?.employeeId,
        trackingNumber,
        companySellerId: UserValue?.Value?.companySellerId,
        isSuperAdmin: UserValue?.Value?.isSuperAdmin,
        sellerIdList: _.map(filterValue.sellerIdList, "id"),
        companyIdList: _.map(filterValue.companyIdList, "Id"),
        processingDateTo: null,
        processingDateFrom: null,
      };
      dispatch(getFilterProductsList(data));
    } else {
      const data = {
        pageIndex: filterValue.pageIndex,
        pageSize: filterValue.pageSize,
        sortColumn: filterValue.sortColumn,
        sortOrder: filterValue.sortOrder,
        date: location?.state?.date,
        searchKeywords: filterValue.searchTxt,
        warehouseList : _.map(filterValue.warehouseList, "id"),
        conditionList : _.map(filterValue.conditionList, "id"),
        userId: UserValue?.Value?.employeeId,
        companySellerId: UserValue?.Value?.companySellerId,
        isSuperAdmin: UserValue?.Value?.isSuperAdmin,
        sellerIdList: _.map(filterValue.sellerIdList, "id"),
        companyIdList: _.map(filterValue.companyIdList, "Id"),
        processingDateTo: '',
        processingDateFrom: '',
        trackingNumber: ''
      };
      dispatch(getFilterProductsList(data));
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (UserValue?.Value?.userName) {
      dispatch(getProductConditionList());
      dispatch(getProductWarehouseList());
    }
  }, [UserValue]);

  const handleEditRow = (item) => {
    dispatch(productBackDetails(filterValue))
    navigate(`/incoming-product/fd-${location?.state?.date}-3pl/${item.id}`, {
      state: {
        view: false,
        date: location?.state?.date || new Date().toISOString(),
        landingSearch,
        trackingNumber
      },
    });
  };

  const handleViewRow = (item) => {
    dispatch(productBackDetails(filterValue))
    navigate(`/incoming-product/fd-${location?.state?.date}-3pl/${item.id}`, {
      state: {
        view: true,
        date: location?.state?.date || new Date().toISOString(),
        landingSearch,
        trackingNumber
      },
    });
  };


  /* istanbul ignore next */
  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterValue.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setFilterValue({
      ...filterValue,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const resetPagination = () => {
    setFilterValue({ ...dataLoad, showFilter: true, pageSize: filterValue.pageSize });
    let data = JSON.parse(JSON.stringify(dataLoad));
    data.showFilter = true;
    data.pageSize = filterValue.pageSize
    dispatch(getFilterProductsList(data));
    setSellerList(allSellers)  };

  const handleSearchFilter = (e, type) => {
    if (type === "clear") {
      handleResetSearch();
      return;
    }
    if (filterValue.pageIndex !== 1) {
      setFilterValue({ ...filterValue, pageIndex: 1 });
    } else {
      fetchFilterProductList();
    }
  };
  const handleResetSearch = () => {
    let data = {
      ...dataLoad,
      pageSize: filterValue.pageSize
    };
    setFilterValue({ ...data });
    dispatch(getFilterProductsList(data));
  }

  const handleEnterClick = (e) => {
    if (e.key === "Enter") {
      handleSearchFilter(e, "enter");
    }
  };

  const handleBlur = (e) => {
    const { value } = e.target;
    setFilterValue({...filterValue, searchTxt: value.trim()})
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterValue.sortColumn === columnName && filterValue.sortOrder === "ASCENDING" ? "fa fa-arrow-up" : "fa fa-arrow-down";
    }
    return filterValue.sortColumn === columnName ? 'active' : 'sortColumn'
  };

  const handlePackagePageNavigation = (trackingNo) => {
    navigate(`/incoming-packages/fd-${trackingNo}-3pl`, {
      state: {
        trackingNumber: trackingNo,
        date: location?.state?.date || location?.state?.backDate,
        backUrl: `/incoming-products/fd-${location?.state?.date}-3pl`
      },
    });
  }

  const handleBackNavigation = () => {
    if (packageDate) {
      handlePackagePageNavigation();
    } else {
      navigate("/incoming-products");
      dispatch(productBackDetails({}));
    }
  }

  const handleFilterToogle = (key, value) =>{
    setFilterValue({...filterValue, [key]: value})
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          <div>
            Products
            {location.state?.searchTxt || trackingNumber ? (
              <p className="packageDate"></p>
            ) : (
              <h5 className="mt-2">
                {moment(new Date(location?.state?.date)).format("MM-DD-YYYY")}
              </h5>
            )}
          </div>
          <div className="top_right_button_container">
            
            <Button
              className="cus-seconday-bg-btn top-btn m2"
              onClick={() => handleFilterToogle("showFilter", !filterValue.showFilter)}
            >
              Filter
            </Button>

            <Button
              className="cus-seconday-bg-btn top-btn m2"
              onClick={() => handleFilterToogle("showSearch", !filterValue.showSearch)}
            >
              <i className="fa fa-search" aria-hidden="true"></i> Search
            </Button>
            <Button
              className="cus-primary-transparent-btn top-btn"
              onClick={handleBackNavigation}
            >
              Back
            </Button>
          </div>
        </div>
        {loader && <LoaderSpinner />}
        <div className={
            filterValue.showSearch
              ? "filterContainer show searchBox mb-4"
              : "filterContainer searchBox mb-4"
          }
          >
          <div className="searchInputBox">
            <input
              value={filterValue.searchTxt}
              onBlur={handleBlur}
              onKeyDown={handleEnterClick}
              onChange={(e) => handleFilterToogle("searchTxt", e.target.value)}
              className="form-control"
              placeholder="Search Item Name, Tracking Number, Warehouse, Serial No."
            />
          </div>
          <button
            className="btn cus-seconday-bg-btn marginLeft5 searchBtn"
            onClick={(e) => handleSearchFilter(e, "search")}
          >
            <i className="fa fa-search" aria-hidden="true"></i> Search
          </button>
          <button
            disabled={!filterValue.searchTxt}
            className="btn cus-primary-transparent-btn clearbtn"
            onClick={(e) => handleSearchFilter(e, "clear")}
          >
            Clear Search
          </button>
        </div>
        {filterValue.showFilter && !trackingNumber &&
              <FilterProduct
                handleReset={resetPagination}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                setLoader={setLoader} 
                sellerList={sellerList}
                setSellerList={setSellerList}
                allSellers={allSellers}
                setAllSellers={setAllSellers}
              />
            }
        <div className="main_content cus-page-content-sec">
          <div className="main_content_table_history incomingTbl">
            <div className="tableFixHead">
              <Table className={`table table-height cus-left-right-col-layout ${loader ? "disableTble" : ""}`}>
                <thead>
                  <th width="5%">Thumbnail</th>
                  {UserValue?.Value?.roleName !== 'Seller' && (
                    <th width="15%">Company / Seller Name</th>
                  )}
                  <th width="10%">Tracking Number</th>
                  <th width="10%">
                    <div
                      className={handleSortingClass('status')}
                      onClick={(e) => handleSortingCoulmn(e, "status")}
                    >
                      Status
                      <i
                        className={handleSortingClass('status', true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th width="13%">
                    <div
                      className={handleSortingClass('processingDate')}
                      onClick={(e) => handleSortingCoulmn(e, "processingDate")}
                    >
                      Processing Date
                      <i
                        className={handleSortingClass('processingDate', true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th width="10%">
                    <div
                      className={handleSortingClass('sku')}
                      onClick={(e) => handleSortingCoulmn(e, "sku")}
                    >
                      SKU
                      <i
                        className={handleSortingClass('sku', true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>

                  <th width="8%">
                    <div
                      className={handleSortingClass('itemname')}
                      onClick={(e) => handleSortingCoulmn(e, "itemname")}
                      id="itemname"
                    >
                      Name
                      <i
                        className={handleSortingClass('itemname', true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th width="10%">
                    <div
                      className={handleSortingClass('condition')}
                      onClick={(e) => handleSortingCoulmn(e, "condition")}
                      id="condition"
                    >
                      Item Condition
                      <i
                        className={handleSortingClass('condition', true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>

                  <th width="12%">
                    <div
                      className={handleSortingClass('warehouse')}
                      onClick={(e) => handleSortingCoulmn(e, "warehouse")}
                      id="warehouse"
                    >
                      Item Warehouse
                      <i
                        className={handleSortingClass('warehouse', true)  }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th width="12%">Action</th>
                </thead>
                <tbody>
                  {productsList && productsList.length > 0 && (
                    <>
                      {productsList.map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            {item?.imageUrl ? (
                              <div
                                className="thumbImg"
                                onClick={() => {
                                  setSelectedRecord(item);
                                  setShowHideImageModal(!showHideImageModal);
                                }}
                              >
                                <img src={item.imageUrl} alt="" />
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                          {UserValue?.Value?.roleName !== 'Seller' && (
                            <td>{item.companySellerName}</td>
                          )}
                          <td>
                            <div
                              className="seller-modal"
                              title="Click to navigate back to Packages"
                              onClick={() => handlePackagePageNavigation(item.trackingNo)}
                            >
                              {item.trackingNo}
                            </div>
                          </td>
                          <td>{item.status}</td>
                          <td>
                            {item.processingDate ? moment(item.processingDate).format(
                              "MM-DD-YYYY"
                            ) : '-'}
                          </td>
                          <td>{item.sku}</td>
                          <td>{item.itemName}</td>
                          <td>{item.condition}</td>
                          <td>{item.warehouse}</td>
                          <td>
                          {checkPermission(returnsManagement, products, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                            <button className="actionItem">
                              <img
                                style={{ cursor: "pointer" }}
                                onClick={() => handleViewRow(item)}
                                src={eye_icon}
                                alt=""
                              />
                            </button>)}
                            {checkPermission(returnsManagement, products, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                            <button className="actionItem">
                              <img
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEditRow(item)}
                                src={edit_icon}
                                alt=""
                                className="img-2"
                              />
                            </button>)}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                  {productsListStatus === "success" && productsList.length === 0 && (
                    renderTableRow(9, 'No record found')
                  )}
                  {productsListStatus !== "success" && renderTableRow(9, 'Loading...')}
                </tbody>
              </Table>
            </div>
          </div>

          <BatchPagination
            gridData={productsList || []}
            totalVisualRecords={productsList?.length}
            numberOfPage={filterValue.pageSize}
            numOfPage={numOfPage}
            loader={loader}
            currentPage={filterValue.pageIndex}
            totalPages={totalPages || 0}
            totalRecords={productsListPageInfo?.TotalRecords}
            setCurrentPage={(value) => setFilterValue({ ...filterValue, pageIndex: value })}
            setNumberOfPage={(value) => setFilterValue({ ...filterValue, pageSize: value })}
          />
          <ViewImage
            selectedRecord={selectedRecord}
            setShowHideImageModal={setShowHideImageModal}
            showHideImageModal={showHideImageModal}
          />
        </div>
      </div>
    </main>
  );
};

export default ProductList;