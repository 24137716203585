import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "bootstrap-4-react/lib/components";
import { unitList } from "../../constant";
import { marketPlaceIntegrationListing } from "../../store/actions/marketplace.action";
import LoaderSpinner from "../common/Loader";

const FilterBatch = (props) => {
    const {
        modalClass,
        setMinWeight,
        setMaxWeight,
        setParcelUnit,
        setFilterApplied,
        filterApplied,
        parcelUnit,
        maxWeight,
        minWeight,
        loader,
        marketplaceIntegrationId,
        setMarketplaceIntegrationId,
    } = props;
    const [showDialog, setShowDialog] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [formData, setFormData] = useState({
        unit: "INCH",
        minWeight: minWeight,
        maxWeight: maxWeight,
        marketplaceIntegrationId: marketplaceIntegrationId,
    });
    const [integrationList, setIntegrationList] = useState([]);

    useEffect(() => {
        if (!showDialog) {
            setFormData({
                unit: parcelUnit || "INCH",
                minWeight: minWeight,
                maxWeight: maxWeight,
            });
        } else {
            if (!integrationList.length) {
                fetchMarketPlaceListing();
            }
        }
    }, [showDialog]);

    const fetchMarketPlaceListing = () => {
        let data = {
            company_seller_id: '',
        };
        setShowLoader(true);
        marketPlaceIntegrationListing(data).then((res) => {
            if (res && res?.data?.Status === 200) {
                setIntegrationList(res?.data?.EntityList);
            } else {
                setIntegrationList([]);
            }
        }).catch((err) => {
            console.log(err)
        })
        setShowLoader(false);
    };

    const handleFilterBatch = () => {
        setMinWeight(Number(formData.minWeight));
        setMaxWeight(Number(formData.maxWeight));
        setMarketplaceIntegrationId(formData.marketplaceIntegrationId);
        setParcelUnit(formData.unit);
        setFilterApplied(!filterApplied);
        handleFilterDialog();
    };

    const handleFieldChange = (e, field) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const handleFilterDialog = () => {
        setShowDialog(!showDialog);
    };

    const clearFilter = () => {
        setMinWeight(0);
        setMaxWeight(0);
        setParcelUnit("");
        setFilterApplied(!filterApplied);
        setMarketplaceIntegrationId(null);
    };

    return (
        <>
            <Button
                className="cus-seconday-bg-btn"
                onClick={handleFilterDialog}
                disabled={loader}
            >
                Filter Orders
            </Button>
            {parcelUnit && (
                <button
                    className="btn cus-primary-transparent-btn"
                    id="clearFilterBtn"
                    onClick={clearFilter}
                    disabled={loader}
                >
                    Clear Filter
                </button>
            )}
            <Modal
                className={modalClass || ""}
                size="md"
                show={showDialog}
                onHide={handleFilterDialog}
            >
                <Modal.Header>
                    <div className="modal-main-div">
                        <span className="modal-m-text">Filter</span>{" "}
                        {showLoader && <LoaderSpinner />}
                    </div>
                    <div className="modalDeleteWrapper">
                        <button
                            id="closeBtn"
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => handleFilterDialog()}
                        ></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="filterBatch createBatchForm">
                        <div className="row ">
                            {integrationList?.length > 0 && (
                                <div className="col-12">
                                    <label>Marketplace Integration</label>
                                    <select
                                        className="unitDropdown form-control"
                                        onChange={(e) =>
                                            handleFieldChange(e, "marketplaceIntegrationId")
                                        }
                                        value={
                                            formData.marketplaceIntegrationId ||
                                            marketplaceIntegrationId
                                        }
                                    >
                                        <option value={0}>Select Integration</option>
                                        {integrationList.length > 0 &&
                                            integrationList.map((item) => (
                                                <option
                                                    key={`${item.integration_id}`}
                                                    value={item.integration_id}
                                                >
                                                    {item.integration_name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label>Unit</label>
                                <select
                                    className="unitDropdown form-control"
                                    onChange={(e) => handleFieldChange(e, "unit")}
                                    value={formData.unit}
                                >
                                    {unitList.length > 0 &&
                                        unitList.map((item, index) => (
                                            <option key={item.id} value={item.value}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label>Min Weight</label>
                                <input
                                    min={0}
                                    maxLength={5}
                                    onChange={(e) => handleFieldChange(e, "minWeight")}
                                    className="form-control"
                                    type="number"
                                    value={formData.minWeight}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(evt.key) &&
                                        evt.preventDefault()
                                    }
                                />
                            </div>
                            <div className="col-6">
                                <label>Max Weight</label>
                                <input
                                    min={0}
                                    maxLength={5}
                                    onChange={(e) => handleFieldChange(e, "maxWeight")}
                                    className="form-control"
                                    type="number"
                                    value={formData.maxWeight}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(evt.key) &&
                                        evt.preventDefault()
                                    }
                                />
                            </div>
                            <div className="col error">
                                {Number(formData.minWeight) > Number(formData.maxWeight)
                                    ? "Min weight cannot be greater than Max weight"
                                    : ""}
                            </div>
                        </div>

                        <div className="formActions">
                            <Button
                                className="btn cus-seconday-bg-btn"
                                onClick={() => handleFilterDialog()}
                            >
                                Cancel
                            </Button>
                            <button
                                disabled={
                                    Number(formData.minWeight) > Number(formData.maxWeight)
                                }
                                className="btn cus-seconday-bg-btn marginLeft5"
                                onClick={handleFilterBatch}
                            >
                                Apply Filter
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FilterBatch;
