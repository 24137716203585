import { batchService } from "../../services/batch.service";
import { batchConstant } from "../constants/batch.constant";

export const createBatch = (data) => {
  return () => {
    return batchService.createBatchService(data);
  };
};
export const getOrderBatchById = (data) => {
  return () => {
    return batchService.getOrderBatchService(data);
  };
};
export const getBatchesList = (response) => ({
  type: batchConstant.GET_BATCHES_LIST,
  payload: response,
});

export const getBatchesStatus = (status) => ({
  type: batchConstant.GET_BATCHES_STATUS,
  payload: status,
});
export const getAllBatches = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(getBatchesStatus("pending"));
      return batchService.getAllBatchesService(data).then(
        (result) => {
          let list = result.data.EntityList;
          dispatch(getBatchesList(list));
          dispatch(getBatchesStatus("success"));
          return resolve(list);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  };
};
export const getBatchPendingOrders = (data) => {
  return () => {
    return batchService.getAllBatchPendingOrderService(data);
  };
};
export const bulkCreateShipment = (data) => {
  return () => {
    return batchService.bulkCreateUpdateShipmentService(data);
  };
};
export const updateOrderStatus = (data) => {
  return () => {
    return batchService.updateOrderStatusService(data);
  };
};
export const getCarrierList = (response) => ({
  type: batchConstant.GET_ALL_CARRIERS,
  payload: response,
});
export const getCarrierListStatus = (status) => ({
  type: batchConstant.GET_ALL_CARRIERS_STATUS,
  payload: status,
});
export const getAllcarriers = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(getBatchesStatus("pending"));
      return batchService.getAllcarriersService().then(
        (result) => {
          let list = result.data.EntityList;
          dispatch(getCarrierList(list));
          dispatch(getCarrierListStatus("success"));
          return resolve(list);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  };
};
export const saveRateCard = (data) => {
  return new Promise((res, rej) => {
    return res(batchService.saveRateCardService(data));
  });
};

export const getMergedPdf = (data) => {
  return new Promise((res, rej) => {
    return res(batchService.getMergedPdfService(data));
  });
};

export const saveIndividualRate = (data, UserData) => {
  return new Promise((res, rej) => {
    return res(batchService.saveRateService(data));
  });
};

/**Batch Recent History */

const getBatchHistoryPending = () => ({
  type: batchConstant.GET_RECENT_HISTORY_BATCH_PENDING,
});
const getBatchHistoryFailed = () => ({
  type: batchConstant.GET_RECENT_HISTORY_BATCH_FAILED,
});
const getBatchHistorySuccess = (response) => ({
  type: batchConstant.GET_RECENT_HISTORY_BATCH_SUCCESS,
  payload: response,
});

export const fetchRecentBatchHistory = (data) => {
  return (dispatch) => {
    dispatch(getBatchHistoryPending());
    batchService
      .getRecentBatchHistoryService(data)
      .then((result) => {
        dispatch(getBatchHistorySuccess(result.data));
      })
      .catch((error) => {
        dispatch(getBatchHistoryFailed());
      });
  };
};

export const resetRecentHistoryBatch = () => ({
  type: batchConstant.RESET_RECENT_HISTORY_BATCH_LIST,
});

export const createScanForm = (url, data) => {
  return new Promise((resolve, reject) => {
    return resolve(batchService.createScanFormService(url, data));
  });
};

/** get scan form list */

const getScanFormListPending = () => ({
  type: batchConstant.GET_SCAN_FORM_LIST_PENDING,
});
const getScanFormListFailed = () => ({
  type: batchConstant.GET_SCAN_FORM_LIST_FAILED,
});
const getScanFormListSuccess = (response) => ({
  type: batchConstant.GET_SCAN_FORM_LIST_SUCCESS,
  payload: response,
});

export const getScanFormList = (data) => {
  return (dispatch) => {
    dispatch(getScanFormListPending());
    batchService
      .getScanFormListService(data)
      .then((result) => {
        dispatch(getScanFormListSuccess(result.data));
      })
      .catch((error) => {
        dispatch(getScanFormListFailed());
      });
  };
};

export const getOrderDetailsById = (orderId) => {
  return new Promise((res, rej) => {
    return res(batchService.getOrderDetailsByIdService(orderId));
  });
};

export const cancelLabel = (data) => {
  return () => {
    return batchService.cancelLabelService(data);
  };
};
