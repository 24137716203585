export const InventoryConstant = {
    INVENTORY_PRODUCT_LISTING_PENDING: 'INVENTORY_PRODUCT_LISTING_PENDING',
    INVENTORY_PRODUCT_LISTING_SUCCESS: 'INVENTORY_PRODUCT_LISTING_SUCCESS',
    INVENTORY_PRODUCT_LISTING_FAILED: 'INVENTORY_PRODUCT_LISTING_FAILED',
    GET_BY_PRODUCT_ID_PENDING: 'GET_BY_PRODUCT_ID_PENDING',
    GET_BY_PRODUCT_ID_SUCCESS: 'GET_BY_PRODUCT_ID_SUCCESS',
    GET_BY_PRODUCT_ID_FAILED: 'GET_BY_PRODUCT_ID_FAILED',
    GET_CONSIGNMENT_PENDING: 'GET_CONSIGNMENT_PENDING',
    GET_CONSIGNMENT_SUCCESS: 'GET_CONSIGNMENT_SUCCESS',
    GET_CONSIGNMENT_FAILED: 'GET_CONSIGNMENT_FAILED',
    GET_ACTIVEINTEGRATION_PENDING: 'GET_ACTIVEINTEGRATION_PENDING',
    GET_ACTIVEINTEGRATION_FAILED: 'GET_ACTIVEINTEGRATION_FAILED',
    GET_ACTIVEINTEGRATION_SUCCESS: 'GET_ACTIVEINTEGRATION_SUCCESS',
    GET_ALL_CONSIGNMENT_TYPE_SUCCESS: 'GET_ALL_CONSIGNMENT_TYPE_SUCCESS',
    GET_ALL_CONSIGNMENT_STATUS_SUCCESS: 'GET_ALL_CONSIGNMENT_STATUS_SUCCESS',
    GET_ALL_CONSIGNMENT_OUTBOUND_STATUS_SUCCESS: 'GET_ALL_CONSIGNMENT_OUTBOUND_STATUS_SUCCESS',
    GET_ALL_WAREHOUSE_LIST_SUCCESS: 'GET_ALL_WAREHOUSE_LIST_SUCCESS',
    EMPTYPRODUCT_DETAILS: 'EMPTYPRODUCT_DETAILS',
    INVENTORY_PRODUCT_HISTORY_PENDING: 'INVENTORY_PRODUCT_HISTORY_PENDING',
    INVENTORY_PRODUCT_HISTORY_SUCCESS: 'INVENTORY_PRODUCT_HISTORY_SUCCESS',
    INVENTORY_PRODUCT_HISTORY_FAILED: 'INVENTORY_PRODUCT_HISTORY_FAILED',
    INVENTORY_INBOUND_HISTORY_PENDING: 'INVENTORY_INBOUND_HISTORY_PENDING',
    INVENTORY_INBOUND_HISTORY_SUCCESS: 'INVENTORY_INBOUND_HISTORY_SUCCESS',
    INVENTORY_INBOUND_HISTORY_FAILED: 'INVENTORY_INBOUND_HISTORY_FAILED',
    INVENTORY_OUTBOUND_LISTING_PENDING: 'INVENTORY_OUTBOUND_LISTING_PENDING',
    INVENTORY_OUTBOUND_LISTING_SUCCESS: 'INVENTORY_OUTBOUND_LISTING_SUCCESS',
    INVENTORY_OUTBOUND_LISTING_FAILED: 'INVENTORY_OUTBOUND_LISTING_FAILED',
    INVENTORY_PRODUCT_INVENTORY_PENDING: 'INVENTORY_PRODUCT_INVENTORY_PENDING',
    INVENTORY_PRODUCT_INVENTORY_SUCCESS: 'INVENTORY_PRODUCT_INVENTORY_SUCCESS',
    INVENTORY_PRODUCT_INVENTORY_FAILED: 'INVENTORY_PRODUCT_INVENTORY_FAILED',
    GET_ALL_CONSIGNMENT_STATUS_LIST_SUCCESS: 'GET_ALL_CONSIGNMENT_STATUS_LIST_SUCCESS',
    INVENTORY_OUTBOUND_HISTORY_PENDING: 'INVENTORY_OUTBOUND_HISTORY_PENDING',
    INVENTORY_OUTBOUND_HISTORY_SUCCESS: 'INVENTORY_OUTBOUND_HISTORY_SUCCESS',
    INVENTORY_OUTBOUND_HISTORY_FAILED: 'INVENTORY_OUTBOUND_HISTORY_FAILED',
}