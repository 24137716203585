import Col from "react-bootstrap/Col";
import React from "react";

const IntegrationTable = (props) => {
    const { tableRows, handleChangeSave, saveModel } = props;
    return (
        <Col style={{ display: "flex", flexDirection: "column" }} className='selectWrapper'>
            {saveModel.csvintegrationcolumnsmapping.map((item) => (
                <>
                    <select
                        className="select"
                        id="select-70"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.orderid"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.orderid}
                    >
                        {tableRows.map((j) => (
                            <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                {j.name}
                            </option>
                        ))}
                    </select>
                    <select
                        className="select"
                        id="select-70"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.orderdate"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.orderdate}
                    >
                        {tableRows.map((j) => (
                            <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                {j.name}
                            </option>
                        ))}
                    </select>

                    <select
                        required
                        className="selects"
                        id="select-69"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.ordernumber"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.ordernumber}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-68"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.shippingservicerelated"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.shippingservicerelated}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-67"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.ordergroup"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.ordergroup}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-66"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.packagelength"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.packagelength}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-65"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.packagewidth"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.packagewidth}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-64"
                        name="csvintegrationcolumnsmapping.packageheight"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.packageheight}
                        aria-label="Default select example"
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-63"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.orderweight"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.orderweight}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-62"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.shippingtotal"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.shippingtotal}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-61"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.ponumber"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.ponumber}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-60"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.printorder"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.printorder}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-59"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.shippingreference"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.shippingreference}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-58"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.shippingreference2"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.shippingreference2}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-57"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.departmentnumber"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.departmentnumber}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-56"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderprefix"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={item.senderprefix}
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-55"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.sendername"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.sendername
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-54"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderfirstname"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.senderfirstname
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-53"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderlastname"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.senderlastname
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-52"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.sendercompany"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.sendercompany
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-51"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderaddressline1"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.senderaddressline1
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-50"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderaddressline2"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.senderaddressline2
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-49"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderaddressline3"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.senderaddressline3
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-48"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.sendercity"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.sendercity
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-47"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderstate"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.senderstate
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-46"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderzip"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.senderzip
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-45"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.sendercountrycode"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.sendercountrycode
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-44"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderemail"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.senderemail
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-43"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.senderphone"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.senderphone
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-42"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiverprefix"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiverprefix
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-41"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receivername"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receivername
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-40"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiverfirstname"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiverfirstname
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-39"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiverlastname"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiverlastname
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-38"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receivercompany"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receivercompany
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-37"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiveraddressline1"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiveraddressline1
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-36"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiveraddressline2"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiveraddressline2
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-35"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiveraddressline3"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiveraddressline3
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-34"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receivercity"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receivercity
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-33"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiverstate"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiverstate
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-32"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiverzip"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiverzip
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-31"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receivercountrycode"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receivercountrycode
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-30"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiveremail"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiveremail
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-29"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.receiverphone"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.receiverphone
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-28"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnprefix"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnprefix
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-27"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnname"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnname
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-26"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnfirstname"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnfirstname
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-25"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnlastname"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnlastname
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-24"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returncompany"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returncompany
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-23"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnaddressline1"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnaddressline1
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-22"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnaddressline2"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnaddressline2
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-21"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnaddressline3"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnaddressline3
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-20"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returncity"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returncity
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-19"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnstate"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnstate
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-18"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnzip"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnzip
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-17"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returncountrycode"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returncountrycode
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-16"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnemail"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnemail
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-15"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.returnphone"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.returnphone
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-14"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.productid"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.productid
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-13"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.productsku"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.productsku
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-12"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.producttitle"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.producttitle
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-11"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.productprice"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.productprice
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-10"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.productquantity"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.productquantity
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-9"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.productshippingweight"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.productshippingweight
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-8"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.producttotalonorder"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.producttotalonorder
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-1"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.productattribute"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.productattribute
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-7"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.producteccn"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.producteccn
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-6"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.producthtsnumber"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.producthtsnumber
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-5"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.productvariantname"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.productvariantname
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-4"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.productvariantvalue"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.productvariantvalue
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-3"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.vatioss"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.vatioss
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>

                    <select
                        className="select"
                        id="select-2"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.vatiosstaxidissuercountrycode"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.vatiosstaxidissuercountrycode
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>
                    <select
                        className="select"
                        id="select-2"
                        aria-label="Default select example"
                        name="csvintegrationcolumnsmapping.marketplaceintegrationid"
                        onChange={(e) => handleChangeSave(e, 0)}
                        value={
                            item.marketplaceintegrationid
                        }
                    >
                        {tableRows.map((j) => {
                            return (
                                <option key={j?.id} value={j.name} disabled={j.isDisabled}>
                                    {j.name}
                                </option>
                            );
                        })}
                    </select>
                </>
            ))}
        </Col>
    );
}

export default IntegrationTable;