import React, { useEffect, useState } from 'react';
import AlertDialogModal from '../../common/AlertDialog';
import { findBoxesChecked, transformedData } from '../../../config/utils';
import '../Console/console.css'
import eye_icon from "../../../assets/eye_icon.svg";

/* istanbul ignore next */
const AddPaletteBox = (props) => {
    const { handleAddConsoleType, type, isEditable, handleDeleteConfirm, handleCheck, elements, setElements, handleEditRecord, formData, setDeletePayload, deletePayload } = props;    
    const [showAlert, setShowAlert] = useState(false);
    const [enableAddBtn, setEnableAddBtn] = useState(false);
    const [isPalletChecked, setIsPalletChecked] = useState(false);
    const [isBoxesChecked, setIsBoxesChecked] = useState(false);

    const deleteItem = () => {
        let newData = [...elements];
        let deletePayloadData = [...deletePayload];
        let filteredItems = [];
        newData.forEach(function (brand) {
            brand.consignmentElements.forEach((items) => {
                items.consignmentElements = items.consignmentElements.filter(function (subBrand) {
                    if (subBrand.isChecked && subBrand.id > 0) {
                        filteredItems.push({ consignmentType: subBrand.consignmentTypeId, id: subBrand.id ? subBrand.id : 0 })
                    }
                    return !subBrand.isChecked;
                })
            });
        });
        deletePayloadData.push(...filteredItems);
        setDeletePayload(deletePayloadData);
        setElements(newData);
    };

    const deleteBox = () => {
        let newData = [...elements];
        let deletePayloadData = [...deletePayload];
        let filteredBoxes = [];
        newData.forEach(function (brand) {
            brand.consignmentElements = brand.consignmentElements.filter(function (subBrand) {
                if (subBrand.isChecked && subBrand.id > 0) {
                    filteredBoxes.push({ consignmentType: subBrand.consignmentTypeId, id: subBrand.id ? subBrand.id : 0 })
                }
                return !subBrand.isChecked;
            })
        });
        deletePayloadData.push(...filteredBoxes);
        setDeletePayload(deletePayloadData);
        setElements(newData);
    }

    const handleshippingStatusId = (item) => {
        if(item.shippingStatusId === 1){
            return "Stored at warehouse"
        } else{
            return item.shippingStatusId === 2 ? "Shipped Out" :""
        }
    }

    const handleDeleteElement = () => {
        let deletePayloadData = [...deletePayload];
        let newData = [...elements];
        if (type === 'Pallet') {
            let filteredItems = transformedData(elements);
            filteredItems.length && deletePayloadData.push(...filteredItems);
            newData = newData.filter((palette) => !palette.isChecked);
            setDeletePayload(deletePayloadData);
            setElements(newData);
        } else if (type === 'Box') {
            deleteBox();
        } else if (type === 'Item') {
            deleteItem()
        }
    }

    const handleAlertYes = () => {
        setShowAlert(!showAlert)
        handleDeleteConfirm(type)
    }

    const findIsChecked = () => {
        if (Number(formData.consignmentTypeId) === 1) {
            let newData = elements.filter((palette) => palette.isChecked);
            if (newData.length === 1) {
                setIsPalletChecked(true);
                setEnableAddBtn(false);
            } else {
                setIsPalletChecked(false);
                setEnableAddBtn(true);
            }
        } else if (Number(formData.consignmentTypeId) === 2) {
            setEnableAddBtn(false);
        }
    }

    useEffect(() => {
        handleEnableLogic();
    }, [formData.consignmentTypeId])

    const handleEnableLogic = () => {
        if (Number(formData.consignmentTypeId) === 2 || Number(formData.consignmentTypeId) === 3) {
            setEnableAddBtn(false);
        } else {
            if (!isEditable) {
                setEnableAddBtn(false);
            } else {
                let findCheckedPalette = elements.filter((item) => item.isChecked).length;
                if (findCheckedPalette === 1) {
                    setEnableAddBtn(true);
                } else {
                    setEnableAddBtn(false);
                }
            }
        }
    }

    useEffect(() => {
        if (type === 'Box') {
            findIsChecked();
        } else if (type === 'Item') {
            findIsChecked();
            let checkedBoxesItems = findBoxesChecked(elements);
            if (Number(formData.consignmentTypeId) !== 3) {
                setEnableAddBtn(checkedBoxesItems.length === 1 ? false : true);
            }
            setIsBoxesChecked(checkedBoxesItems.length ? true : false);
        }
    }, [elements]);

    const renderBoxHtml = (item, index, boxIndex) => {
        return (
            <div className='palleteItemRow' key={item?.id}>
                <div className='d_flex'>
                    <input type='checkbox' disabled={!isEditable} name='isChecked' value={item?.isChecked} onChange={(e) => handleCheck(e, index, boxIndex, type, 0)} />
                    <div className='palleteName'>{item?.name}</div>
                </div>
                <div className='d_flex'>
                <div className='pallet_conn'>
                    <span className={item.shippingStatusId === 1 ? 'stored_console_conn connectioninfo' : 'shipped_console_conn connectioninfo'} title={handleshippingStatusId(item)}>
                    {handleshippingStatusId(item)}
                    </span>
                    </div>
                    <div className={`viewPalleteRow ${isEditable ? 'disableTble' : ''}`} onClick={() => handleEditRecord(type, index, boxIndex, 0, 'VIEW')}>
                        <img
                            className="editIcon mr-15"
                            style={{ cursor: "pointer", width: "16px" }}
                            src={eye_icon}
                            alt=""
                        />
                    </div>
                    <div className={`edit cursorPointer ${!isEditable ? 'disableTble' : ''}`} onClick={() => handleEditRecord(type, index, boxIndex, 0, 'EDIT')}>
                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        )
    }

    const renderItemHtml = (items, index, boxIndex, itemIndex) => {
        return (
            <div className='palleteItemRow' key={items?.id}>
                <div className='d_flex'>
                    <input type='checkbox' disabled={!isEditable} name='isChecked' value={items?.isChecked} onChange={(e) => handleCheck(e, index, boxIndex, type, itemIndex)} />
                    <div className='palleteName'>{items?.name}</div>
                </div>
                <div className='d_flex'>
                    <div className='pallet_conn'>
                    <span className={items.shippingStatusId === 1 ? 'stored_console_conn connectioninfo' : 'shipped_console_conn connectioninfo'} title={handleshippingStatusId(items)}>
                    {handleshippingStatusId(items)}
                    </span>
                    </div>
                    <div className={`viewPalleteRow ${isEditable ? 'disableTble' : ''}`} onClick={() => handleEditRecord(type, index, boxIndex, itemIndex, 'VIEW')}>
                        <img
                            className="editIcon mr-15"
                            style={{ cursor: "pointer", width: "16px" }}
                            src={eye_icon}
                            alt=""
                        />
                    </div>
                    <div className={`edit cursorPointer ${!isEditable ? 'disableTble' : ''}`} onClick={() => handleEditRecord(type, index, boxIndex, itemIndex, 'EDIT')}>
                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        )
    }

    const handleDeleteBtn = () => {
        if (!isEditable) {
            return true
        }
        if (type === 'Pallet') {
            let newData = elements.filter((palette) => palette.isChecked);
            return newData.length ? false : true;
        } else if (type === 'Box') {
            let checkedBoxesItems = findBoxesChecked(elements);
            return checkedBoxesItems.length ? false : true;
        } else if (type === 'Item') {
            return fndItemsChecked().length ? false : true;;
        }
        return false;
    }

    const fndItemsChecked = () => {
        let newData = [...elements];
        let filteredItems = [];
        newData.forEach(function (brand) {
            brand.consignmentElements.forEach((items) => {
                items.consignmentElements.map((subBrand) => {
                    subBrand.isChecked && filteredItems.push(subBrand);
                })
            });
        });
        return filteredItems;
    }

    return (
        <>
            <div className='imageBlock'>
                <div className='palleteCardHead'>
                    <div className='palleteHead'>
                        {type}
                    </div>
                    <div className=''>
                        <button
                            className='btn iconBtn'
                            onClick={() => handleAddConsoleType(type)}
                            disabled={enableAddBtn || !isEditable}
                        >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                        <button
                            onClick={handleDeleteElement}
                            className='btn iconBtn'
                            disabled={handleDeleteBtn()}
                        >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div className='cardBody'>
                    {type === 'Pallet' && elements && elements.map((item, index) => (
                        <div className='palleteItemRow' key={item?.id}>
                            <div className='d_flex'>
                                <input type='checkbox' name='isChecked' disabled={!isEditable} value={item?.isChecked} onChange={(e) => handleCheck(e, index, 0, type, 0)} />
                                <div className='palleteName'>{item?.name}</div>
                            </div>
                            <div className='d_flex'>
                            <div className='pallet_conn'>
                                <span className={item.shippingStatusId === 1 ? 'stored_console_conn connectioninfo' : 'shipped_console_conn connectioninfo'} title={handleshippingStatusId(item)}>
                                {handleshippingStatusId(item)}
                                </span>
                                </div>
                                <div className={`viewPalleteRow ${isEditable ? 'disableTble' : ''}`} onClick={() => handleEditRecord(type, index, 0, 0, 'VIEW')}>
                                    <img
                                        className="editIcon mr-15"
                                        style={{ cursor: "pointer", width: "16px" }}
                                        src={eye_icon}
                                        alt=""
                                    />
                                </div>
                                <div className={`edit cursorPointer ${!isEditable ? 'disableTble' : ''}`} onClick={() => handleEditRecord(type, index, 0, 0, 'EDIT')}>
                                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    ))}
                    {type === 'Box' && elements && elements.map((palette, index) => (
                        <>
                            {isPalletChecked ? (
                                <>
                                    {palette.isChecked && (
                                        <>
                                            {palette?.consignmentElements && palette.consignmentElements.map((item, boxIndex) => (
                                                renderBoxHtml(item, index, boxIndex)
                                            ))}

                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {palette?.consignmentElements && palette.consignmentElements.map((item, boxIndex) => (
                                        renderBoxHtml(item, index, boxIndex)
                                    ))}
                                </>
                            )}
                        </>
                    ))}

                    {type === 'Item' && elements && elements.map((palette, index) => (
                        <>
                            {isPalletChecked ? (
                                <>
                                    {palette.isChecked && (
                                        <>
                                            {palette.consignmentElements && palette.consignmentElements.map((boxes, boxIndex) => (
                                                <>
                                                    {isBoxesChecked ? (
                                                        <>
                                                            {boxes.isChecked && (
                                                                <>
                                                                    {boxes?.consignmentElements && boxes.consignmentElements.map((items, itemIndex) => (
                                                                        <>
                                                                            {renderItemHtml(items, index, boxIndex, itemIndex)}
                                                                        </>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {boxes?.consignmentElements && boxes.consignmentElements.map((items, itemIndex) => (
                                                                <>
                                                                    {renderItemHtml(items, index, boxIndex, itemIndex)}
                                                                </>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {palette.consignmentElements && palette.consignmentElements.map((boxes, boxIndex) => (
                                        <>
                                            {isBoxesChecked ? (
                                                <>
                                                    {boxes.isChecked && (
                                                        <>
                                                            {boxes?.consignmentElements && boxes.consignmentElements.map((items, itemIndex) => (
                                                                <>
                                                                    {renderItemHtml(items, index, boxIndex, itemIndex)}
                                                                </>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {boxes?.consignmentElements && boxes.consignmentElements.map((items, itemIndex) => (
                                                        <>
                                                            {renderItemHtml(items, index, boxIndex, itemIndex)}
                                                        </>
                                                    ))}
                                                </>

                                            )}
                                        </>
                                    ))
                                    }
                                </>
                            )}
                        </>
                    ))}
                </div>
            </div >
            <AlertDialogModal
                modalShow={showAlert}
                handleCancelDeleteAlert={handleDeleteElement}
                modalClass={'modalDeleteWrapper'}
                messageText={`Are you sure, you want to delete this record?`}
                handleYesDeleteAlert={handleAlertYes}
                headingText={'DELETE'}
            />
        </>
    );
}

export default AddPaletteBox;