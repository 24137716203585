import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { getSelectList } from '../../config/utils';
import { fetchCompanyFilters, getFilterSellers, fetchSellerFilters } from '../../store/actions/rateCard';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePickerComponent from '../common/DatePickerComponent';
import _ from 'lodash';

const FilterKeyGenerate = (props) => {
    const { showFilter, handleFilter, filterValue, setFilterValue, handleResetFilter, sellerList, setSellerList, allSellers, setAllSellers } = props;
    const [companyList, setCompanyList] = useState([]);
    const { filterCompanies, filterSellers } = useSelector((state) => state.RateCardReducer);
    const dispatch = useDispatch();
    const UserValue = useSelector((state) => state.auth.user);
    useEffect(() => {
        let companyList = filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
        let sellerList = filterSellers?.Entity?.listSellerMasterOrdersEntity || [];
        if (companyList.length) {
            if (UserValue?.Value?.isSuperAdmin) {
                setCompanyList(getSelectList(filterCompanies?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
            } else {
                let filterCompany = companyList.filter((company) => company.name.toLowerCase() === UserValue?.Value?.companyName.toLowerCase());
                setCompanyList(getSelectList(filterCompany, 'Id', 'name'));

            }
        }
        if (sellerList.length) {
            setAllSellers(getSelectList(sellerList, 'id', 'newSellerName'));
            setSellerList(getSelectList(sellerList, 'id', 'newSellerName'));
          }
    }, [filterCompanies, filterSellers]);

    useEffect(() => {
        let data = {
            pageIndex: 1,
            pageSize: 10,
            sortColumn: 'name',
            sortOrder: 'ascending'
        };
        let sellerData = {
            screenType: 2
          };
        dispatch(fetchCompanyFilters(data));
        dispatch(fetchSellerFilters(sellerData));    }, []);

    const handleFilterClick = () => {
        if (moment(filterValue?.DateTo).isBefore(moment(filterValue?.DateFrom))) {
            toast.error('Enter valid dates');
        } else {
            handleFilter();
        }
    }

    const handleDateChange = (date, fieldName) => {
        let dateSelected = moment(date).utc(true).format();
        setFilterValue({ ...filterValue, [fieldName]: dateSelected })
    }
    const handleServiceIdList = (value, fieldName) => {
        setFilterValue({
            ...filterValue,
            [fieldName]: value
        });
        if (fieldName === "CompanyIdList") {
            let companyData = _.map(value, "Id");
            fetchSellers(companyData);
            if (filterValue?.SellerIdList?.length){
                const filteredSellers = filterValue.SellerIdList.filter(seller => 
                  companyData.includes(parseInt(seller.companyIds))
                );    
                setFilterValue({...filterValue, SellerIdList: filteredSellers, CompanyIdList: value})
              }        }
    }
    const fetchSellers = async (companyList) => {
        if (companyList.length === 0) {
            setSellerList(allSellers);
            return;
          }
        let data = {
            "pageIndex": 1,
            "pageSize": 1000,
            "sortColumn": "name",
            "sortOrder": "ASCENDING",
            "companyIdList": companyList
        }
        getFilterSellers(data).then(res => {
            if (res?.data?.Status === 200) {
                let dataList = getSelectList(res?.data?.EntityList, 'sellerId', 'sellerName')
                setSellerList(dataList);
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    return (
        <div className={`${props.customClass} ${showFilter ? 'filterContainer show historyFilter history-margin' : 'filterContainer historyFilter'}`}>
            <div className='row mb10'>
                <div className='col-4'>
                    <div className='row'>
                        <div className='col-12' style={{ paddingTop: '3px' }}>
                            <label className='labelHead'>Date</label>
                        </div>
                        <div className='col-6 fieldAndLabel'>
                            <span>From:</span>
                            <div className='inputHistory'>
                                <DatePickerComponent
                                    dateField={filterValue?.DateFrom || null}
                                    fieldName={'DateFrom'}
                                    handleDateChange={handleDateChange}
                                />
                            </div>
                        </div>
                        <div className='col-6 fieldAndLabel'>
                            <span>To:</span>
                            <div className='inputHistory'>
                                <DatePickerComponent
                                    dateField={filterValue?.DateTo || null}
                                    fieldName={'DateTo'}
                                    handleDateChange={handleDateChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {UserValue?.Value?.roleName !== 'Seller' && (
                    <>
                        <div className='col-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='labelHead'>Company</label>
                                </div>
                                <div className='col-12'>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        options={companyList}
                                        onChange={(value) =>
                                            handleServiceIdList(value, "CompanyIdList")
                                        }
                                        value={filterValue.CompanyIdList}
                                        className="activeSellerListInput"
                                        classNamePrefix="list"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='labelHead'>Seller</label>
                                </div>
                                <div className='col-12'>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        options={sellerList}
                                        onChange={(e) =>
                                            handleServiceIdList(e, "SellerIdList")
                                        }
                                        value={filterValue.SellerIdList}
                                        className="activeSellerListInput"
                                        classNamePrefix="list"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className='col-4'>
                    <div className='col-12'>
                        <label className='labelHead' style={{ marginBottom: '26px' }}></label>
                    </div>
                    <button
                        className="btn cus-primary-transparent-btn top-btn"
                        onClick={handleResetFilter}
                    > Clear Filter
                    </button>
                    <button
                        className="btn cus-seconday-bg-btn top-btn filterButton"
                        onClick={handleFilterClick}
                    > Apply
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FilterKeyGenerate;