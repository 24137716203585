import React, { useEffect } from "react";
import Select from "react-select";
import { Button } from "bootstrap-4-react/lib/components";
import { alphabeticKeys, alphabeticKeysReg } from '../../constant';
import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const BasicDetails = (props) => {
    const {
        formData,
        TopUpForm,
        editValue,
        errors,
        companyList,
        handleSelectChange,
        handleEditable,
        setaddressModalShow,
        saveData,
        addressDataValue,
        handleDefaultAddress,
        handleDeleteAddress,
        countryList,
        handleChangeAddress,
        handleInputValueTypes,
        stateList,
        fetchAddress,
    } = props;
    const navigate = useNavigate();

     const handleSaveAddress = () => {
       if (
         addressDataValue &&
         addressDataValue.length > 0 &&
         addressDataValue[0]?.id > 0
       ) {
         saveData();
         navigate("/SellerManagement");
       } else {
         toast.error("Please add Address");
       }
     };
    return (
        <div className="main_content_table">
            <div className="main__tile_heading cus-btn-include-sec">
                Seller Details
                <div className="top_right_button_container">
                    <>
                        {editValue ? (
                            <>
                                <Button
                                    className="cus-seconday-bg-btn top-btn"
                                    disabled={editValue}
                                >
                                    Add Address
                                </Button>
                                <Button
                                    className="cus-seconday-bg-btn top-btn"
                                    onClick={
                                      /* istanbul ignore next */ () =>
                                            handleEditable()
                                    }
                                >
                                    Edit
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    className="cus-seconday-bg-btn top-btn"
                                    onClick={() => setaddressModalShow(true)}
                                >
                                    Add Address
                                </Button>
                                <Button
                                    className="cus-seconday-bg-btn top-btn"
                                    onClick={
                                      /* istanbul ignore next */ () =>
                                            handleSaveAddress()
                                    }
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </>
                </div>
            </div>
            <>
                <div className="col-4 formLabel Edit">
                    <span className="input-label">Basic Details:</span>
                </div>
                <div className="row modal_input Edit-Form">
                    <div className="col-4 formLabel Edit">
                        <span className="input-label">Seller Name:</span>
                    </div>
                    <div className="col-8">
                        <div className={"select_react"}>
                            <input
                                value={formData?.newSellerName}
                                onChange={(e) =>
                                    TopUpForm(e.target.value, "newSellerName")
                                }
                                disabled={editValue}
                                className="form-control availableBalanceInput"
                                type="text"
                            />
                            <p className="error dialogError">
                                {errors.newSellerName}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row modal_input Edit-Form">
                    <div className="col-4 formLabel Edit">
                        <span className="input-label">Seller Code:</span>
                    </div>
                    <div className="col-8">
                        <div className={"select_react"}>
                            <input
                                value={formData?.sellerCode}
                                onChange={(e) =>
                                    TopUpForm(e.target.value, "sellerCode")
                                }
                                disabled={editValue}
                                className="form-control availableBalanceInput"
                                type="text"
                            />
                            <p className="error dialogError">
                                {errors.sellerCode}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row modal_input Edit-Form">
                    <div className="col-4 formLabel Edit">
                        <span className="input-label">Company:</span>
                    </div>
                    <div className="col-8">
                        <div class={"select_react"}>
                            <Select
                                isMulti
                                options={companyList}
                                onChange={handleSelectChange}
                                className="activeSellerListInput"
                                isDisabled={editValue}
                                value={formData?.companyIds}
                                classNamePrefix="list"
                            />
                            <p className="error dialogError">
                                {errors.companyIds}
                            </p>
                        </div>
                    </div>
                </div>
            </>
            <div className="row modal_input Edit-Form">
                <div className="col-4 formLabel Edit">
                    <span className="input-label">Ste Code:</span>
                </div>
                <div className="col-8">
                    <div class={"select_react"}>
                        <input
                            value={formData?.steCode}
                            /* istanbul ignore next */
                            onChange={(e) =>
                                TopUpForm(e.target.value, "steCode")
                            }
                            disabled={editValue}
                            className="form-control availableBalanceInput"
                            type="text"
                        />
                        <p className="error dialogError">
                            {errors.steCode}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row modal_input Edit-Form">
                <div className="col-4 formLabel Edit">
                    <span className="input-label">Email Address:</span>
                </div>
                <div className="col-8">
                    <div class={"select_react"}>
                        <input
                            value={formData?.email}
                            onChange={(e) =>
                                TopUpForm(e.target.value, "email")
                            }
                            className="form-control availableBalanceInput"
                            type="text"
                            disabled={editValue}
                        />
                        <p className="error dialogError">
                            {errors.email}
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-4 formLabel Edit">
                <span className="input-label">Addresses:</span>
            </div>
            {addressDataValue?.length > 0 &&
                addressDataValue?.map((d, index) => (
                    <>
                        {d?.id !== 0 && (
                            <div className="edit-company-address">
                                {editValue ? (
                                    <div className="row modal_input Edit-Form-company">
                                        <div className="col-4 formLabel Edit"></div>
                                        <div className="col-8 delete-company">
                                            <span className="input-label col-4">
                                                <input
                                                    type="radio"
                                                    class
                                                    id="html"
                                                    checked={d?.isDefault}
                                                    onChange={
                                              /* istanbul ignore next */ () =>
                                                            handleDefaultAddress(d?.id)
                                                    }
                                                    name="fav_language"
                                                    disabled={true}
                                                    value="HTML"
                                                />
                                                {" "}
                                                <label
                                                    className="default-add-label"
                                                    for="html"
                                                >
                                                    Default Address
                                                </label>
                                            </span>

                                            <span className="input-label col-4">
                                                {" "}
                                                <i
                                                    class="fa fa-trash-o"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row modal_input Edit-Form-company">
                                        <div className="col-4 formLabel Edit"></div>
                                        <div className="col-8 delete-company">
                                            <span className="input-label col-4">
                                                <input
                                                    type="radio"
                                                    class
                                                    id="html"
                                                    checked={d?.isDefault}
                                                    onChange={
                                              /* istanbul ignore next */ () =>
                                                            handleDefaultAddress(d?.id)
                                                    }
                                                    name="fav_language"
                                                    disabled={false}
                                                    value="HTML"
                                                />
                                                {" "}
                                                <label
                                                    className="default-add-label"
                                                    for="html"
                                                >
                                                    Default Address
                                                </label>
                                            </span>

                                            <span
                                                className="input-label col-4 delete-btn"
                                                onClick={
                                                    () =>
                                                        handleDeleteAddress(d)
                                                }
                                            >
                                                {" "}
                                                <i
                                                    class="fa fa-trash-o"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <div className="row modal_input Edit-Form-company">
                                    <div className="col-4 formLabel Edit">
                                        <span className="input-label">
                                            Country:
                                        </span>
                                    </div>
                                    <div className="col-8">
                                        <div class={"select_react"}>
                                            <Select
                                                options={countryList}
                                                /* istanbul ignore next */
                                                onChange={(value) =>
                                                    handleChangeAddress(
                                                        index,
                                                        "country_Id",
                                                        value.value
                                                    )
                                                }
                                                key={d?.id}
                                                className="activeSellerListInput"
                                                isDisabled={editValue}
                                                defaultValue={{ label: d?.country, value: d?.country_Id }}
                                                classNamePrefix="list"
                                                value={{ label: d?.country, value: d?.country_Id }}
                                            />
                                            {errors[`addressModels_${index}`] &&
                                                /* istanbul ignore next */
                                                errors[`addressModels_${index}`]
                                                    .country_Id && (
                                                    <p className="error dalogError">
                                                        {
                                                            errors[`addressModels_${index}`]
                                                                .country_Id
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row modal_input Edit-Form-company">
                                    <div className="col-4 formLabel Edit">
                                        <span className="input-label">
                                            Zip Code:
                                        </span>
                                    </div>
                                    <div className="col-8">
                                        <div class={"select_react"}>
                                            <input
                                                value={d?.zipCode}
                                                onChange={(e) =>
                                                    handleChangeAddress(
                                                        index,
                                                        "zipCode",
                                                        e.target.value
                                                    )
                                                }
                                                onKeyDown={(e) =>
                                                    handleInputValueTypes(e, alphabeticKeys)
                                                }
                                                className="form-control availableBalanceInput zipCodeField"
                                                type="text"
                                                disabled={editValue}
                                                pattern="\d{5}(-\d{4})?"
                                                title="Please enter a valid zip code format: xxxxx or xxxxx-xxxx"
                                                onBlur={(e) => fetchAddress(e.target.value, index)}
                                            />
                                            {errors[`addressModels_${index}`] &&
                                                /* istanbul ignore next */
                                                errors[`addressModels_${index}`]
                                                    .zipCode && (
                                                    <p className="error dalogError">
                                                        {
                                                            errors[`addressModels_${index}`]
                                                                .zipCode
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                {d?.country_Id === 218 ? (
                                    <div className="row modal_input Edit-Form-company">
                                        <div className="col-4 formLabel Edit">
                                            <span className="input-label">
                                                State:
                                            </span>
                                        </div>
                                        <div className="col-8">
                                            <Select
                                                //   value={formData?.state}
                                                options={stateList}
                                                onChange={(value) =>
                                                    handleChangeAddress(
                                                        index,
                                                        "state",
                                                        value.label
                                                    )
                                                }
                                                className="activeSellerListInput"
                                                isDisabled={editValue}
                                                value={{ label: d?.state, value: '' }}
                                                classNamePrefix="list"
                                            />
                                            {errors[`addressModels_${index}`] &&
                                                /* istanbul ignore next */
                                                errors[`addressModels_${index}`]
                                                    .state && (
                                                    <p className="error dalogError">
                                                        {
                                                            errors[`addressModels_${index}`]
                                                                .state
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row modal_input Edit-Form-company">
                                        <div className="col-4 formLabel Edit">
                                            <span className="input-label">
                                                State:
                                            </span>
                                        </div>
                                        <div className="col-8">
                                            <input
                                                value={d?.state}
                                                onChange={(e) =>
                                                    handleChangeAddress(
                                                        index,
                                                        "state",
                                                        e.target.value
                                                    )
                                                }
                                                onKeyDown={(e) =>
                                                    handleInputValueTypes(e, alphabeticKeysReg)
                                                }
                                                className="form-control availableBalanceInput addressState"
                                                disabled={editValue}
                                                type="text"
                                            />
                                            {errors[`addressModels_${index}`] &&
                                                /* istanbul ignore next */
                                                errors[`addressModels_${index}`]
                                                    .state && (
                                                    <p className="error dalogError">
                                                        {
                                                            errors[`addressModels_${index}`]
                                                                .state
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                )}

                                <div className="row modal_input Edit-Form-company">
                                    <div className="col-4 formLabel Edit">
                                        <span className="input-label">City:</span>
                                    </div>
                                    <div className="col-8">
                                        <div class={"select_react"}>
                                            <input
                                                value={d?.city}
                                                onChange={(e) =>
                                                    handleChangeAddress(
                                                        index,
                                                        "city",
                                                        e.target.value
                                                    )
                                                }
                                                onKeyDown={(e) =>
                                                    handleInputValueTypes(
                                                        e,
                                                        /^[A-Za-z]$/
                                                    )
                                                }
                                                className="form-control availableBalanceInput cityField"
                                                disabled={editValue}
                                                type="text"
                                            />
                                            {errors[`addressModels_${index}`] &&
                                                /* istanbul ignore next */
                                                errors[`addressModels_${index}`]
                                                    .city && (
                                                    <p className="error dalogError">
                                                        {
                                                            errors[`addressModels_${index}`]
                                                                .city
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row modal_input Edit-Form-company">
                                    <div className="col-4 formLabel Edit">
                                        <span className="input-label">
                                            Phone Number:
                                        </span>
                                    </div>
                                    <div className="col-8">
                                        <div class={"select_react"}>
                                            <PhoneInput
                                                value={d?.phoneNumber}
                                                name="returnphone"
                                                disabled={editValue}
                                                onChange={(e) =>
                                                    handleChangeAddress(index, "phoneNumber", e)
                                                }
                                                className={"form-control availableBalanceInput"}
                                            />
                                            {errors[`addressModels_${index}`] &&
                                                /* istanbul ignore next */
                                                errors[`addressModels_${index}`]
                                                    .phoneNumber && (
                                                    <p className="error dalogError countrytype-error">
                                                        {
                                                            errors[`addressModels_${index}`]
                                                                .phoneNumber
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row modal_input Edit-Form-company">
                                    <div className="col-4 formLabel Edit">
                                        <span className="input-label">
                                            Address 1:
                                        </span>
                                    </div>
                                    <div className="col-8">
                                        <div class={"select_react"}>
                                            <input
                                                value={d?.addressFirst}
                                                onChange={(e) =>
                                                    handleChangeAddress(
                                                        index,
                                                        "addressFirst",
                                                        e.target.value
                                                    )
                                                }
                                                onKeyDown={(e) =>
                                                    handleInputValueTypes(
                                                        e,
                                                        /^[a-zA-Z0-9\s-]$/
                                                    )
                                                }
                                                className="form-control availableBalanceInput addressFirst"
                                                disabled={editValue}
                                                type="text"
                                            />
                                            {errors[`addressModels_${index}`] &&
                                                /* istanbul ignore next */
                                                errors[`addressModels_${index}`]
                                                    .addressFirst && (
                                                    <p className="error dalogError">
                                                        {
                                                            errors[`addressModels_${index}`]
                                                                .addressFirst
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row modal_input Edit-Form-company">
                                    <div className="col-4 formLabel Edit">
                                        <span className="input-label">
                                            Address 2:
                                        </span>
                                    </div>
                                    <div className="col-8">
                                        <div class={"select_react"}>
                                            <input
                                                value={d?.addressSecond}
                                                onChange={(e) =>
                                                    handleChangeAddress(
                                                        index,
                                                        "addressSecond",
                                                        e.target.value
                                                    )
                                                }
                                                onKeyDown={(e) =>
                                                    handleInputValueTypes(
                                                        e,
                                                        /^[a-zA-Z0-9\s-]$/
                                                    )
                                                }
                                                className="form-control availableBalanceInput addressSecond"
                                                disabled={editValue}
                                                type="text"
                                            />
                                            {errors[`addressModels_${index}`] &&
                                                /* istanbul ignore next */
                                                errors[`addressModels_${index}`]
                                                    .addressSecond && (
                                                    <p className="error dalogError">
                                                        {
                                                            errors[`addressModels_${index}`]
                                                                .addressSecond
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row modal_input Edit-Form-company">
                                    <div className="col-4 formLabel Edit">
                                        <span className="input-label">
                                            Address 3:
                                        </span>
                                    </div>
                                    <div className="col-8">
                                        <div class={"select_react"}>
                                            <input
                                                value={d?.addressThird}
                                                onChange={(e) =>
                                                    handleChangeAddress(
                                                        index,
                                                        "addressThird",
                                                        e.target.value
                                                    )
                                                }
                                                onKeyDown={(e) =>
                                                    handleInputValueTypes(
                                                        e,
                                                        /^[a-zA-Z0-9\s-]$/
                                                    )
                                                }
                                                className="form-control availableBalanceInput addressLine3"
                                                disabled={editValue}
                                                type="text"
                                            />
                                            {errors[`addressModels_${index}`] &&
                                                /* istanbul ignore next */
                                                errors[`addressModels_${index}`]
                                                    .addressThird && (
                                                    <p className="error dalogError">
                                                        {
                                                            errors[`addressModels_${index}`]
                                                                .addressThird
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ))}
        </div>
    );
}

export default BasicDetails;