import React, { useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDailyPackageCountList,
  getPackageYearsList,
  setIncomingSelectedYear,
} from "../../store/actions/incoming";
import LoaderSpinner from "../common/Loader";
import PackageCard from "./PackageCard";
import { Button } from "bootstrap-4-react/lib/components";
import AsyncPackageSearch from "../common/AsynTypeAhead";
import { setLocalStorageItem } from "../../services/StorageService";
import { checkPermission, currentYear } from "../../config/utils";
import { featureSubmodulePermission, packages, permssionCreate, returnsManagement } from "../../constant";

const IncomingPackages = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth.user);
  const packageYears = useSelector(
    (state) => state.IncomingReducer.packageYears
  );
  const {
    packageDetails,
    packageDetailsStatus,
    packageYearsStatus,
    selectedIncomingYear,
    packageYearBackIndexDetails
  } = useSelector((state) => state.IncomingReducer);
  const [selectedyear, setSelectedyear] = useState(0);
  const [loader, setLoader] = useState(false);
  const UserValue = useSelector((state) => state.auth.user);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    /* istanbul ignore next */

    dispatch(
      getPackageYearsList(userDetails?.Value.companySellerId, userDetails?.Value?.isSuperAdmin)
    );
  }, []);

  useEffect(() => {
    if (
      packageYearsStatus === "success" &&
      selectedIncomingYear &&
      packageYears &&
      packageYears.length
    ) {
      setSelectedyear(selectedIncomingYear);
    } else {
      if (packageYears && packageYears.length && !selectedIncomingYear) {
        let year = packageYears.includes(currentYear) ? currentYear : packageYears[0]
        setSelectedyear(year);
        dispatch(
          getDailyPackageCountList(year)
        );
      } else if (packageYearsStatus === "success" && !packageYears?.length) {
        setLoader(false);
      }
    }
  }, [packageYearsStatus]);

  useEffect(() => {
    if (packageDetailsStatus !== "success") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [packageDetailsStatus]);

  const handleCreate = (e) => {
    navigate(`/incoming-packages/create-packages/${0}`, {
      state: { view: false, isCreated: true },
    });
  };

  const handleYearChange = (e) => {
    const { value } = e.target;
    setSelectedyear(value);
    setLocalStorageItem("incomingYear", value);
    dispatch(setIncomingSelectedYear(value));
    dispatch(
      getDailyPackageCountList(
        value,
        userDetails?.Value.companySellerId,
        userDetails?.Value?.isSuperAdmin
      )
    );
  };

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          Packages
          <div className="top_right_button_container">
            <div className="selectYear top-btn">
              {packageYears && packageYears.length > 0 && (
                <Form.Select
                  size="sm"
                  className="form-control"
                  value={selectedyear}
                  onChange={handleYearChange}
                >
                  {packageYears &&
                    packageYears.length &&
                    packageYears.map((item, index) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                </Form.Select>
              )}
            </div>
            <button
              disabled={packageYears && !packageYears?.length}
              className="btn cus-seconday-bg-btn top-btn"
              onClick={() => setShowSearch(!showSearch)}
            >
              <i className="fa fa-search" aria-hidden="true"></i> Search
            </button>
            {checkPermission(returnsManagement, packages, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
              <Button
                className="cus-seconday-bg-btn top-btn"
                onClick={() => handleCreate()}
              >
                <i className="fa fa-plus" aria-hidden="true"></i> Create Package
              </Button>
            )}
          </div>
        </div>
        <div
          className={
            showSearch
              ? "filterContainer show searchBox"
              : "filterContainer searchBox"
          }
        >
          <div className="searchInputBox">
            <AsyncPackageSearch
              placeHolder={"Enter tracking number..."}
              userDetails={UserValue}
            />
          </div>
        </div>
        {loader && <LoaderSpinner />}
        <div className="main_content cus-page-content-sec">
          {packageYearsStatus === "success" && !packageYears?.length ? (
            <p>No Record Found</p>
          ) : (
            <Accordion className="incomingAccordion" defaultActiveKey={[packageYearBackIndexDetails || 0]}>
              {packageDetails.map((item, index) => (
                <Accordion.Item eventKey={index} key={item.month}>
                  <Accordion.Header>
                    {item.month}
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="packages row">
                      {item.data.map((data, i) => (
                        <div className="col-lg-3 col-md-6" key={data.date}>
                          <PackageCard index={index} data={data} {...props} dispatch={dispatch} />
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </div>
      </div>
    </main>
  );
};

export default IncomingPackages;
