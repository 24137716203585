import React from 'react';
import Table from "react-bootstrap/Table";
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { sellerListBackDetails, userListBackDetails } from '../../store/actions';
import { companyListBackDetails } from '../../store/actions/rateCard';
import { useDispatch } from 'react-redux';
import { checkPermission } from '../../config/utils';
import { accountsManagement, featureSubmodulePermission, permissionAssociatedUser, permissionCompanyAssociatedSeller, permssionEdit, permssionView, SUBF_COMPANY } from '../../constant';
import ActionItems from '../common/showActions';

const CompanyList = (props) => {
    const {
        sortingColumn,
        handleSortingCoulmn,
        sortOrder,
        getAllCompany,
        handleAssociatedSellerPage,
        handleAssociatedUserPage,
        navigate,
        loader,
        filterData
    } = props;
    const dispatch = useDispatch();

    const handleCompanyEdit = (rowData) => {
        dispatch(companyListBackDetails(filterData))
        navigate(`/editCompany/comp-${rowData?.Id}`, {
            state: {
                data: rowData,
                editable: false,
            },
        });
    };
    const handleCompanyView = (rowData) => {
        dispatch(companyListBackDetails(filterData));
        const companyId = rowData?.Id;
        navigate(`/editCompany/comp-${rowData?.Id}`, {
            state: {
                data: rowData,
                companyId,
                associatedSeller: false,
                editable: true,
            },
        });
    };

    const renderColumnHeader = (colName, type) => {
        return (
            <div
                className={(sortingColumn === type) ? "active" : "sortColumn"}
                onClick={(e) => handleSortingCoulmn(e, type)}
            >
                {colName}
                <i
                    className={(sortingColumn === type && sortOrder === "ASCENDING") ? "fa fa-arrow-up" : "fa fa-arrow-down"}
                    aria-hidden="true"
                ></i>
            </div>
        )
    }

    return (
        <Table className={`table  table-height cus-left-right-col-layout ${loader ? "disableTble" : ""}`}>
            <thead border="Green">
                <tr>
                    <th className="head-company">
                        {renderColumnHeader('Company Name', 'name')}
                    </th>
                    <th>
                        {renderColumnHeader('Country', 'country')}
                    </th>
                    <th>
                        {renderColumnHeader('State', 'state')}
                    </th>
                    <th>
                        {renderColumnHeader('Associated Sellers', 'associatedSeller')}
                    </th>
                    <th>
                        {renderColumnHeader('Associated Users', 'associatedUsers')}
                    </th>
                    <th>
                        {renderColumnHeader('Phone Number', 'phonenumber')}
                    </th>
                    <th>
                        <span className="action-text">Action</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {getAllCompany?.Entity?.listCompanyMasterOrdersEntity?.map(
                    (d) => (
                        <tr key={d?.Id}>
                            <td>{d?.name}</td>
                            <td>{d?.defaultCountry}</td>
                            <td>{d?.defaultState}</td>
                            <td>
                                {d?.sellerCount > 0 && checkPermission(accountsManagement, SUBF_COMPANY, permissionCompanyAssociatedSeller, featureSubmodulePermission, props.employeePermissions) ? (
                                    <div
                                        className="seller-modal"
                                        onClick={() => {
                                            handleAssociatedSellerPage(d?.Id)
                                            dispatch(sellerListBackDetails({}))
                                        }}
                                    >
                                        {d?.sellerCount}
                                    </div>
                                ) : (
                                    <div>{d?.sellerCount}</div>
                                )}
                            </td>
                            <td>
                                
                                {d?.userCount > 0 && checkPermission(accountsManagement, SUBF_COMPANY, permissionAssociatedUser, featureSubmodulePermission, props.employeePermissions) ? (
                                    <div
                                        className="seller-modal"
                                        onClick={() => {
                                            handleAssociatedUserPage(d?.Id)
                                            dispatch(userListBackDetails({}))
                                        }}
                                    >
                                        {d?.userCount}
                                    </div>
                                ) : (
                                    <div>{d?.userCount}</div>
                                )}
                            </td>
                            <td>
                                <span className="phn-value">
                                    {formatPhoneNumberIntl(d?.phoneNumber) || d?.phoneNumber}
                                </span>
                            </td>
                            <td>
                                {checkPermission(accountsManagement, SUBF_COMPANY, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                                    <ActionItems
                                        type={"View"}
                                        item={d}
                                        handleAction={() =>
                                            handleCompanyView(d)
                                        }
                                    />
                                )}
                                {checkPermission(accountsManagement, SUBF_COMPANY, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                                    <ActionItems
                                        type={"Edit"}
                                        item={d}
                                        handleAction={() =>
                                            handleCompanyEdit(d)
                                        }
                                    />
                                )}
                            </td>
                        </tr>
                    )
                )}
            </tbody>
        </Table>
    );
}

export default CompanyList;